import React, {useEffect, useState} from 'react'
import ShowPage from "@templates/page/ShowPage.template";
import useGql from "@modules/graphql/useGql";
import {NavbarBottomButtonWrapper} from "@components/button/NavbarBottomButton.wrapper";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {SubscriptionPlanCardList} from "@pages/merchant/subscriptions/pages/config/pages/plan/components/Plan.list";
import {TierOutput} from "@src/gql-schema";
import {NoResourcesScreen} from "@pages/merchant/subscriptions/components/NoResources.screen";
import {
    PlanPulloverCreateForm
} from "@pages/merchant/subscriptions/pages/config/pages/plan/forms/PlanPullover.create.form";
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import useParamId from "@hooks/useParamId";
import {__t} from "@config/i18n";


export function PlanIndexPage() {

    const gql = useGql();
    const subscriptionId = useParamId('subscriptionId');
    const navbarBottom = useNavbarBottom()

    const [plans, setPlans] = useState<TierOutput[]>([]);
    const [refresh, setRefresh] = useState(true)

    const subscriptionName = useAsyncMemo(async () => {
        return await gql.GetSubscription({id: subscriptionId})
            .then(r => r.getSubscription.title)
    }, [subscriptionId], __t('subscription.config.title'))

    useEffect(() => {
        if (!refresh) {
            return
        }
        gql.GetPlans({subscriptionId: subscriptionId ?? ''})
            .then(r => setPlans(r.getPlans))
            .finally(() => setRefresh(false))
    }, [refresh]);


    useEffect(() => {
        navbarBottom.children[1](
            <NavbarBottomButtonWrapper>
                <PlanPulloverCreateForm
                    subscriptionId={subscriptionId}
                    onSuccess={() => setRefresh(true)}
                />
            </NavbarBottomButtonWrapper>
        )
    }, [subscriptionId]);



    useEffect(() => {
        const sortedArray = [...plans]
            .sort((a, b) => a.order - b.order)
        gql.ReorderPlans({id: sortedArray.map(plan => plan.id)}).then()
    }, [plans]);




    async function handleChange(id: string) {
        const previouslySelectedPlan = plans?.find(plan => plan.isHighlighted)
        const isCurrentlySelected = previouslySelectedPlan?.id === id

        // FE
        const updatedPlans = plans.map(plan => {
            if (plan.id === id) {
                return {...plan, isHighlighted: !isCurrentlySelected} // Highlight
            }
            if (!isCurrentlySelected && plan.id === previouslySelectedPlan?.id) {
                return {...plan, isHighlighted: false} // Unhighlight
            }
            return plan;
        });
        setPlans(updatedPlans);

        // DB
        try {
            if (isCurrentlySelected) {
                await gql.HighlightPlan({id, isHighlighted: false}).then(r => console.log(r))
            } else {
                if (previouslySelectedPlan) {
                    await gql.HighlightPlan({
                        id: previouslySelectedPlan.id,
                        isHighlighted: false
                    }).then(r => console.log(r))
                }
                await gql.HighlightPlan({id, isHighlighted: true}).then(r => console.log(r))
            }
        } catch (error) {
            // Reset if Fails
            setPlans(plans)
        }
    }


    return (
        <ShowPage
            back={{
                title: subscriptionName,
                path: `/subscriptions/${subscriptionId}/config`
            }}
        >
            {plans.length === 0 && <NoResourcesScreen text={__t('plan.no_results')}/>}
            <SubscriptionPlanCardList
                plans={plans}
                handleChange={handleChange}
                setPlans={setPlans}
            />
        </ShowPage>
    )
}