import React, {useMemo} from 'react';
import ShowPageTemplate from "@templates/page/ShowPage.template";
import {mockPaymentData,} from "@app/mock/MockPaymentData";
import useParamId from "@hooks/useParamId";
import {PaymentDetails} from "@pages/merchant/payment/pages/show/components/Payment.details";
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";
import PaymentList from "@pages/merchant/payment/components/Payment.list";

const PaymentShowPage = () => {

    const {available} = useWorkspaceHeight()
    const params = useParamId()


    const currentCustomer = useMemo(() => {
        const foundCustomer = mockPaymentData.find(data => data.id === params)
        if (!foundCustomer) {
            return undefined
        }

        return {
            ...foundCustomer,
            symbol: foundCustomer.title
                ? foundCustomer?.title[0].concat(foundCustomer?.title.split(' ')[1][0])
                : undefined
        }
    }, [params])


    const listHeight = useMemo(() => {
        return currentCustomer?.status === 'reversed' ? `calc(${available - 320.5 - 41 - 30}px - 4.0625rem)` : `calc(${available - 230.5 - 41 - 30}px - 4.0625rem)`

    }, [available, currentCustomer?.status]);


    const customerHistory = useMemo(() => {
        return mockPaymentData.filter(data => data.title === currentCustomer?.title)
    }, [])

    return (
        <ShowPageTemplate back={{
            title: 'Payment List',
            path: '/payments/list'

        }}>
            <PaymentDetails customer={currentCustomer}/>

            <div className={'-m-shell'}>
                <PaymentList items={customerHistory} height={listHeight}/>
            </div>

        </ShowPageTemplate>

    );
};

export default PaymentShowPage;
