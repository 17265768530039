import React, {FC, useMemo} from 'react';
import ListPageTemplate from "@templates/page/ListPage.template";
import PaymentList from "@pages/merchant/payment/components/Payment.list";
import {mockPaymentData} from "@app/mock/MockPaymentData";
import PaymentFilter from "@pages/merchant/payment/pages/list/components/Payment.filter";

const PaymentListPage: FC = () => {


    const items = useMemo(() => {
        return mockPaymentData
    }, []);


    return <ListPageTemplate navChildren={<PaymentFilter/>}>
        <PaymentList items={items}/>
    </ListPageTemplate>
};

export default PaymentListPage;
