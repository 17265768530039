import React, {useEffect, useState} from 'react'
import ShowPage from "@templates/page/ShowPage.template";
import {Tabs} from "@src/@/components/ui/tabs";
import useParamId from "@hooks/useParamId";
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import {NoResourcesScreen} from "@pages/merchant/subscriptions/components/NoResources.screen";
import useAsyncMemo from "@hooks/useAsyncMemo";
import useGql from "@modules/graphql/useGql";
import {BenefitOutput, NestedTierOutput} from "@src/gql-schema";
import {NavbarBottomButtonWrapper} from "@components/button/NavbarBottomButton.wrapper";
import {
    BenefitPulloverCreateForm
} from "@pages/merchant/subscriptions/pages/config/pages/benefit/forms/BenefitPullover.create.form";
import {BenefitTab} from "@pages/merchant/subscriptions/pages/config/pages/benefit/components/Benefit.tab";
import {BenefitList} from "@pages/merchant/subscriptions/pages/config/pages/benefit/components/Benefit.list";
import {
    BenefitAddToPlanTab
} from "@pages/merchant/subscriptions/pages/config/pages/benefit/components/BenefitAddToPlan.tab";
import {__t} from "@config/i18n";
import {BenefitTabItems} from "@pages/merchant/subscriptions/pages/config/pages/benefit/types/Benefit.types";

export function BenefitIndexPage() {

    const gql = useGql()
    const subscriptionId = useParamId('subscriptionId');
    const navbarBottom = useNavbarBottom()

    const [refresh, setRefresh] = useState(true)
    const [benefits, setBenefits] = useState<BenefitOutput[]>([])
    const [plans, setPlans] = useState<NestedTierOutput[]>([])
    const [selectedPlan, setSelectedPlan] = useState<string>('')

    const subscriptionName = useAsyncMemo(async () => {
        return await gql.GetSubscription({id: subscriptionId})
            .then(r => r.getSubscription.title)
    }, [subscriptionId], __t('subscription.config.title'))

    useEffect(() => {
        navbarBottom.children[1](
            <NavbarBottomButtonWrapper>
                <BenefitPulloverCreateForm
                    subscriptionId={subscriptionId}
                    onSuccess={() => setRefresh(true)}
                />
            </NavbarBottomButtonWrapper>
        )
    }, [subscriptionId]);


    useEffect(() => {
        if (!refresh || !subscriptionId) {
            return
        }
        gql.GetBenefits({subscriptionId: subscriptionId ?? ''})
            .then(r => setBenefits(r.getBenefits))

        gql.GetNestedPlan({subscriptionId: subscriptionId ?? ''}).then(r => {
            setPlans(r.getNestedPlans)
            if (r.getNestedPlans.length > 0) {
                setSelectedPlan(r.getNestedPlans[0].id)
            }
        })
            .finally(() => setRefresh(false))
    }, [subscriptionId, refresh])


    useEffect(() => {
        const sortedArray = [...benefits]
            .sort((a, b) => a.order - b.order)

        gql.ReorderBenefits({benefitId: sortedArray.map(benefit => benefit.id)})
            .then(r => r)
    }, [benefits])

    function handleCheckChange(planId: string | undefined, benefitId: string) {
        if (!planId || !benefitId) {
            return
        }

        setPlans(prevPlans => {
            return prevPlans.map(plan => {
                return plan.id !== planId ? plan : {
                    ...plan,
                    benefits: plan.benefits.map(benefit => {
                        return benefit.id !== benefitId ? benefit : {
                            ...benefit,
                            active: !benefit.active
                        }
                    })
                }
            })
        })

        gql.TogglePlanBenefit({planId, benefitId})
            .then(r => r)
            .catch(error => {
                console.error('Failed to update benefit', error)
            })
    }

    return (
        <ShowPage
            back={{
                title: subscriptionName,
                path: `/subscriptions/${subscriptionId}/config`
            }}
        >
            {benefits.length === 0 && <NoResourcesScreen text={__t('benefit.no_results')}/>}
            <Tabs defaultValue={Object.keys(BenefitTabItems)[0]} className={'w-full flex flex-col gap-y-6'}>
                {benefits.length > 0 && <BenefitTab/>}
                <BenefitList
                    benefits={benefits}
                    setBenefits={setBenefits}
                    setPlans={setPlans}
                />
                {benefits.length > 0 && <BenefitAddToPlanTab
                    plans={plans}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                    handleCheckChange={handleCheckChange}
                />}
            </Tabs>
        </ShowPage>
    )
}