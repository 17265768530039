import React, {useEffect, useState} from 'react';
import IndexPage from "@templates/page/IndexPage.template";
import PaymentChart from "@pages/merchant/payment/components/Payment.chart";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";
import PaymentActivityList from "@pages/merchant/payment/components/PaymentActivity.list";
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import {
    PaymentLinkPulloverCreateForm
} from "@pages/merchant/payment/forms/payment-link/PaymentLinkPullover.create.form";
import {NavbarBottomButtonWrapper} from "@components/button/NavbarBottomButton.wrapper";


const PaymentIndexPage = () => {

    const gql = useGql()

    const navbarBottom = useNavbarBottom()

    const [chartHeight, setChartHeight] = useState(0)

    useEffect(() => {
        navbarBottom.children[1](
            <NavbarBottomButtonWrapper>
                <PaymentLinkPulloverCreateForm/>
            </NavbarBottomButtonWrapper>
        )
    }, []);

    const onboardingComplete = useAsyncMemo(async () => {
        return await gql.GetCompanies().then(async r => {
            const companyId = r.getCompanies?.[0].id;
            if (!companyId) {
                return false
            }
            return await gql.GetMerchantProfileProgress()
                .then(r => r.getMerchantProfileProgress?.complete ?? false)
        })
    }, [])

    return (
        <IndexPage back={onboardingComplete === false ? {
            title: 'Onboarding',
            path: '/onboarding'
        } : undefined}>
            <PaymentChart setChartHeight={setChartHeight}/>
            <PaymentActivityList chartHeight={chartHeight}/>
        </IndexPage>
    );
};

export default PaymentIndexPage;
