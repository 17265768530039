import React, {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {Tabs, TabsList, TabsTrigger} from "@src/@/components/ui/tabs";
import DiscountTooltip from "./Discount.tooltip";
import {classNames} from "@modules/casting/String";


export type PricingInterval = {
    id: string,
    title: string,
    intervalType: 'monthly' | 'yearly',
    discount?: number,
    discountType?: 'fixed' | 'percentage' | 'none'
    toolTip?: string
}

export type PricingIntervalState = [PricingInterval | undefined, Dispatch<SetStateAction<PricingInterval>>];

type Props = {
    intervals: PricingInterval[]
    intervalState: PricingIntervalState
}

const IntervalToggle: FC<Props> = ({
    intervals,
    intervalState
}) => {

    const [currentInterval, setCurrentInterval] = useMemo(() => intervalState, [intervalState]);

    const currentIntervalIndex = useMemo(() => {
        return intervals?.findIndex(interval => interval.title == currentInterval?.title)
    }, [currentInterval]);

    const isCurrentInterval = (intervalIndex: number) => currentIntervalIndex == intervalIndex

    const handleIntervalChange = (intervalTitle: string) => {
        setCurrentInterval(intervals?.find(interval => interval.title === intervalTitle) ?? intervals?.[0])
    }

    return (
        <Tabs
            className={'mx-auto bg-neutral-200 rounded-md'}
            defaultValue={currentInterval?.title ?? 'monthly'}
            value={currentInterval?.title}
            onValueChange={handleIntervalChange}
        >
            <TabsList className={`grid grid-cols-2 w-full gap-x-4`}>
                {intervals?.map((interval, index) => <React.Fragment key={index}>
                    <TabsTrigger
                        value={interval.title}
                        key={index}
                        className={classNames(
                            'data-[state=active]:bg-primary-800 data-[state=active]:text-white',
                            isCurrentInterval(index) && 'font-medium'
                        )}
                    >
                        <DiscountTooltip
                            trigger={<span className={`capitalize  font-medium`}>{interval.title}</span>}
                            balloon={interval.toolTip ?
                                <span className={'capitalize font-medium'}>{interval.toolTip}</span> : undefined}
                        />
                    </TabsTrigger>
                </React.Fragment>)}
            </TabsList>
        </Tabs>
    )
}

export default IntervalToggle