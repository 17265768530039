import React, {Dispatch, FC, SetStateAction, useMemo, useState} from 'react'
import PullOver from "@components/pullover/PullOver.component";
import {Button} from "@src/@/components/ui/button";
import {
    FixedFeePulloverCreateForm
} from "@pages/merchant/subscriptions/pages/config/pages/fee/forms/FixedFeePullover.create.form";
import {
    IntervalFeePulloverCreateForm
} from "@pages/merchant/subscriptions/pages/config/pages/fee/forms/IntervalFeePullover.create.form";
import {FeeType, FeeTypeItems} from "@app/types/Fee";
import {__t} from "@config/i18n";


type Props = {
    subscriptionId: string
    refreshState: [boolean, Dispatch<SetStateAction<boolean>>]
}

export const FeeTypePullover: FC<Props> = ({subscriptionId, refreshState}) => {
    const [showPulloverScreen, setShowPulloverScreen] = useState(false)
    const [showFixedFeePullover, setShowFixedFeePullover] = useState(false)
    const [showIntervalFeePullover, setShowIntervalFeePullover] = useState(false)

    const setRefresh = useMemo(() => {
        return refreshState[1]
    }, [refreshState[1]]);


    function handleOnClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        switch (e.currentTarget.value as FeeType) {
            case('fixed') :
                setShowFixedFeePullover(true);
                break;
            case('interval') :
                setShowIntervalFeePullover(true);
                break;
            default:
                return
        }
        setShowPulloverScreen(false)
    }

    return (<>
        {/* FeeTypeItems */}
        <PullOver
            title={__t('fee.choose_type')}
            openState={[showPulloverScreen, setShowPulloverScreen]}
            submit={{title: '', hidden: true}}
            trigger={{title: __t('fee.new')}}
        >
            <div className={'space-y-4'}>
                {Object.keys(FeeTypeItems).map(feeItem => <Button
                    key={feeItem}
                    value={feeItem}
                    onClick={handleOnClick}
                >
                    {FeeTypeItems[feeItem as FeeType]}
                </Button>)}
            </div>
        </PullOver>

        <FixedFeePulloverCreateForm
            subscriptionId={subscriptionId}
            onSuccess={() => setRefresh(true)}
            pulloverState={[showFixedFeePullover, setShowFixedFeePullover]}
        />
        <IntervalFeePulloverCreateForm
            subscriptionId={subscriptionId}
            onSuccess={() => setRefresh(true)}
            pulloverState={[showIntervalFeePullover, setShowIntervalFeePullover]}
        />
    </>)
}