import React, {FC} from 'react'
import {ChevronRight} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {classNames} from "@modules/casting/String";

type Props = {
    title: string,
    optional: boolean,
    done: boolean,
    to: string
}

export const SubscriptionConfigCard: FC<Props> = ({title, optional, done, to}) => {

    return (
        <Link to={to}>
            <div
                className={'bg-white drop-shadow px-4 py-6 flex items-center rounded-md gap-x-2 overflow-hidden relative'}>
                <div className={'flex-grow font-medium capitalize'}>{title}</div>
                <ChevronRight className={classNames(
                    done ? 'text-positive' : (optional ? 'text-neutral-600' : 'text-primary-600')
                )}/>
                <div
                    className={classNames(
                        'h-1 absolute w-full left-0 right-0 bottom-0',
                        done ? 'bg-positive' : (optional ? 'bg-neutral-600' : 'bg-primary-600')
                    )}></div>
            </div>
        </Link>
    )
}