import {SelectOption} from "@components/form/fields/Select.component";
import {__t} from "@config/i18n";

export type InvoiceMomentType = 'first_invoice' | 'last_invoice'
export type InvoiceIntervalType = 'months' | 'years'

export const InvoiceMomentSelectOptions: SelectOption<string>[] = [
    {
        title: __t('invoice.moment.first_invoice'),
        value: 'first_invoice'
    },
    {
        title: __t('invoice.moment.last_invoice'),
        value: 'last_invoice'
    },
]

export const InvoiceIntervalSelectOptions = (plural = false): SelectOption<string>[] => [
    {
        title: __t(`invoice.interval.years.${plural ? 'plural' : 'singular'}`),
        value: 'years'
    },
    {
        title: __t(`invoice.interval.months.${plural ? 'plural' : 'singular'}`),
        value: 'months'
    },
]