/* eslint-disable no-use-before-define, @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars */
// noinspection NestedFunctionCallJS, JSUnusedGlobalSymbols, GraphQLUnresolvedReference

import {GraphQLClient, RequestOptions} from 'graphql-request';
import gql from 'graphql-tag';

export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * ISO 8601 calendar date without timezone.
   * Format: %Y-%m-%d
   *
   * # Examples
   *
   * * `1994-11-13`
   * * `2000-02-24`
   */
  NaiveDate: { input: any; output: any; }
  /**
   * ISO 8601 combined date and time without timezone.
   *
   * # Examples
   *
   * * `2015-07-01T08:59:60.123`,
   */
  NaiveDateTime: { input: any; output: any; }
};

export type AddressOutput = {
  __typename?: 'AddressOutput';
  address: Scalars['String']['output'];
  addressAddition?: Maybe<Scalars['String']['output']>;
  addressType: Scalars['String']['output'];
  city: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type AgeDiscountOutput = {
  __typename?: 'AgeDiscountOutput';
  age: Scalars['Int']['output'];
  amount: Scalars['Float']['output'];
  amountType: Scalars['String']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isLess: Scalars['Boolean']['output'];
  orEqual: Scalars['Boolean']['output'];
  subscriptionId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type BenefitOutput = {
  __typename?: 'BenefitOutput';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  subscriptionId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type BillingConfigCompletionOutput = {
  __typename?: 'BillingConfigCompletionOutput';
  billingRules: Scalars['Boolean']['output'];
  invoiceMoments: Scalars['Boolean']['output'];
  paymentMethods: Scalars['Boolean']['output'];
};

export type BillingRuleCompletionOutput = {
  __typename?: 'BillingRuleCompletionOutput';
  chargeback: Scalars['Boolean']['output'];
  firstReminder: Scalars['Boolean']['output'];
  lastReminder: Scalars['Boolean']['output'];
  paymentRetries: Scalars['Boolean']['output'];
  penalty: Scalars['Boolean']['output'];
  reflectionPeriod: Scalars['Boolean']['output'];
};

export type BuyStatusOutput = {
  __typename?: 'BuyStatusOutput';
  paymentLink?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type ChargeBackSettingOutput = {
  __typename?: 'ChargeBackSettingOutput';
  action: Scalars['String']['output'];
  collectionCost: Scalars['Boolean']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  subscriptionId: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type CocRecordOutput = {
  __typename?: 'CocRecordOutput';
  addressId: Scalars['String']['output'];
  cocNumber: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  imageId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['NaiveDateTime']['output'];
  vatNumber?: Maybe<Scalars['String']['output']>;
};

export type CompanyOutput = {
  __typename?: 'CompanyOutput';
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceEmail: Scalars['String']['output'];
  invoiceRecipient: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rootUserId: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type ConfiguredOutput = {
  __typename?: 'ConfiguredOutput';
  configured: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['String']['output']>;
};

export type ConstraintOutput = {
  __typename?: 'ConstraintOutput';
  boolean: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ContactOutput = {
  __typename?: 'ContactOutput';
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  position?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type CreateAddressInput = {
  address: Scalars['String']['input'];
  addressAddition?: InputMaybe<Scalars['String']['input']>;
  addressType: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
};

export type CreateAgeDiscountInput = {
  age: Scalars['Int']['input'];
  amount: Scalars['Float']['input'];
  amountType: Scalars['String']['input'];
  description: Scalars['String']['input'];
  isLess: Scalars['Boolean']['input'];
  orEqual: Scalars['Boolean']['input'];
  subscriptionId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateBenefitInput = {
  description: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateChargeBackSettingInput = {
  action: Scalars['String']['input'];
  collectionCost: Scalars['Boolean']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type CreateCocRecordInput = {
  addressId: Scalars['String']['input'];
  cocNumber: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  imageId?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyInput = {
  invoiceEmail: Scalars['String']['input'];
  invoiceRecipient: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rootUserId: Scalars['String']['input'];
};

export type CreateContactInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  position?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDiscountCodeInput = {
  availableAmount?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  discount: Scalars['Float']['input'];
  discountType: Scalars['String']['input'];
  title: Scalars['String']['input'];
  useBefore?: InputMaybe<Scalars['NaiveDateTime']['input']>;
};

export type CreateDynamicFeeInput = {
  active: Scalars['Boolean']['input'];
  amount: Scalars['Float']['input'];
  amountMax?: InputMaybe<Scalars['Float']['input']>;
  amountType: Scalars['String']['input'];
  clauseType: Scalars['String']['input'];
  condition: Scalars['String']['input'];
  description: Scalars['String']['input'];
  disabledTill?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  enabledTill?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  interval: Scalars['String']['input'];
  intervalAmount?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateExtraInput = {
  active: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  excludeFromCoupons?: InputMaybe<Scalars['Boolean']['input']>;
  excludeFromPromotions?: InputMaybe<Scalars['Boolean']['input']>;
  excludeFromYearlyDiscounts?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
  value: Scalars['Float']['input'];
  vatPercentage: Scalars['Float']['input'];
  when: Scalars['String']['input'];
};

export type CreateFirstReminderSettingInput = {
  daysAfterInvoice: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
  tone: Scalars['String']['input'];
};

export type CreateFixedFeeInput = {
  active: Scalars['Boolean']['input'];
  amount: Scalars['Float']['input'];
  amountType: Scalars['String']['input'];
  description: Scalars['String']['input'];
  isDiscount: Scalars['Boolean']['input'];
  moment: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateLastReminderSettingInput = {
  daysAfterFirstReminder: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
  tone: Scalars['String']['input'];
};

export type CreateLoyaltyDiscountInput = {
  amount: Scalars['Float']['input'];
  amountType: Scalars['String']['input'];
  description: Scalars['String']['input'];
  intervalYears: Scalars['Int']['input'];
  maximumAmount: Scalars['Float']['input'];
  maximumAmountType: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  yearsSubscribed: Scalars['Int']['input'];
};

export type CreateMerchantThemeInput = {
  companyId: Scalars['String']['input'];
  companyLogo: Scalars['String']['input'];
  isFormal: Scalars['Boolean']['input'];
  primaryColor: Scalars['String']['input'];
  secondaryColor: Scalars['String']['input'];
};

export type CreatePaymentMethodSettingInput = {
  chargeFee: Scalars['Boolean']['input'];
  internalId: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type CreatePaymentRetrySettingInput = {
  retryBeforeNextReminder: Scalars['Boolean']['input'];
  retryImmediately: Scalars['Boolean']['input'];
  retryNextDay: Scalars['Boolean']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type CreatePenaltySettingInput = {
  applyDebtCollectionFee: Scalars['Boolean']['input'];
  minimumStatutoryInterest: Scalars['Boolean']['input'];
  reminderInterval: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type CreateProfileInput = {
  companyCategory: Scalars['String']['input'];
  companyDescription: Scalars['String']['input'];
  companyEmail: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  companyPhone: Scalars['String']['input'];
  companyWebsite: Scalars['String']['input'];
  countriesOfActivity: Array<Scalars['String']['input']>;
};

export type CreateReflectionSettingInput = {
  cancelCharge: Scalars['Boolean']['input'];
  daysOfReflection: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type CreateShoppingCartInput = {
  discountId?: InputMaybe<Scalars['String']['input']>;
  intervalId: Scalars['String']['input'];
  tierId: Scalars['String']['input'];
};

export type CreateSubscriptionInput = {
  allowMultiplePlansPerUser?: InputMaybe<Scalars['Boolean']['input']>;
  companyId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  vatPercentage: Scalars['Float']['input'];
};

export type CreateSubscriptionIntervalInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  alwaysBillFullMonth?: InputMaybe<Scalars['Boolean']['input']>;
  billUpfront?: InputMaybe<Scalars['Boolean']['input']>;
  day?: InputMaybe<Scalars['String']['input']>;
  dayOfMonth?: InputMaybe<Scalars['Int']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  firstPart?: InputMaybe<Scalars['Boolean']['input']>;
  interval: Scalars['String']['input'];
  isPartOfMonth?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyCancellableAfterCommitment?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyPayments?: InputMaybe<Scalars['String']['input']>;
  respectStartDate?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionId: Scalars['String']['input'];
};

export type CreateTierInput = {
  description: Scalars['String']['input'];
  extends?: InputMaybe<Scalars['String']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  price: Scalars['Float']['input'];
  subscriptionId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateUserInput = {
  dob?: InputMaybe<Scalars['NaiveDate']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mollieTokenId?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWelcomeDiscountInput = {
  amount: Scalars['Float']['input'];
  amountType: Scalars['String']['input'];
  description: Scalars['String']['input'];
  months: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type DetailedInvoiceOutput = {
  __typename?: 'DetailedInvoiceOutput';
  dueDate: Scalars['String']['output'];
  invoiceDate: Scalars['String']['output'];
  invoiceNumber: Scalars['String']['output'];
  paymentDate?: Maybe<Scalars['String']['output']>;
  paymentMethod: Scalars['String']['output'];
  pricingDetails: TierPriceOutput;
  status: Scalars['String']['output'];
  subscriptionName: Scalars['String']['output'];
};

export type DiscountCodeOutput = {
  __typename?: 'DiscountCodeOutput';
  availableAmount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Scalars['String']['output'];
  discount: Scalars['Float']['output'];
  discountType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
  useBefore?: Maybe<Scalars['NaiveDateTime']['output']>;
};

export type DynamicFeeOutput = {
  __typename?: 'DynamicFeeOutput';
  active: Scalars['Boolean']['output'];
  amount: Scalars['Float']['output'];
  amountMax?: Maybe<Scalars['Float']['output']>;
  amountType: Scalars['String']['output'];
  clauseType: Scalars['String']['output'];
  condition: Scalars['String']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Scalars['String']['output'];
  disabledTill?: Maybe<Scalars['NaiveDateTime']['output']>;
  enabledTill?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  interval: Scalars['String']['output'];
  intervalAmount: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  subscriptionId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type ExtraOutput = {
  __typename?: 'ExtraOutput';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Scalars['String']['output'];
  excludeFromCoupons: Scalars['Boolean']['output'];
  excludeFromPromotions: Scalars['Boolean']['output'];
  excludeFromYearlyDiscounts: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  subscriptionId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
  value: Scalars['Float']['output'];
  vatPercentage: Scalars['Float']['output'];
  when: Scalars['String']['output'];
};

export type FirstReminderSettingOutput = {
  __typename?: 'FirstReminderSettingOutput';
  createdAt: Scalars['NaiveDateTime']['output'];
  daysAfterInvoice: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  subscriptionId: Scalars['String']['output'];
  tone: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type FixedFeeOutput = {
  __typename?: 'FixedFeeOutput';
  active: Scalars['Boolean']['output'];
  amount: Scalars['Float']['output'];
  amountType: Scalars['String']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Scalars['String']['output'];
  disabledTill?: Maybe<Scalars['NaiveDateTime']['output']>;
  enabledTill?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  isDiscount: Scalars['Boolean']['output'];
  moment: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  subscriptionId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type Identity = {
  __typename?: 'Identity';
  domain: Scalars['String']['output'];
  user: UserIdentity;
};

export type InvoiceMomentOutput = {
  __typename?: 'InvoiceMomentOutput';
  monthly: ConfiguredOutput;
  yearly: ConfiguredOutput;
};

export type InvoiceOutput = {
  __typename?: 'InvoiceOutput';
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  dueDate: Scalars['NaiveDateTime']['output'];
  id: Scalars['String']['output'];
  invoiceDate: Scalars['NaiveDateTime']['output'];
  name: Scalars['String']['output'];
  paymentDate?: Maybe<Scalars['NaiveDateTime']['output']>;
  price: Scalars['Float']['output'];
  reminderSent: Scalars['Boolean']['output'];
  tierId: Scalars['String']['output'];
  tierPrice: Scalars['Float']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
  userId: Scalars['String']['output'];
};

export type LastReminderSettingOutput = {
  __typename?: 'LastReminderSettingOutput';
  createdAt: Scalars['NaiveDateTime']['output'];
  daysAfterFirstReminder: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  subscriptionId: Scalars['String']['output'];
  tone: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type LoyaltyDiscountOutput = {
  __typename?: 'LoyaltyDiscountOutput';
  amount: Scalars['Float']['output'];
  amountType: Scalars['String']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  intervalYears: Scalars['Int']['output'];
  maximumAmount: Scalars['Float']['output'];
  maximumAmountType: Scalars['String']['output'];
  subscriptionId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
  yearsSubscribed: Scalars['Int']['output'];
};

export type MerchantContactForm = {
  address: Scalars['String']['input'];
  addressAddition?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  position?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
};

export type MerchantContactOutput = {
  __typename?: 'MerchantContactOutput';
  address: Scalars['String']['output'];
  addressAddition?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  position?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
};

export type MerchantProgressList = {
  __typename?: 'MerchantProgressList';
  addressIsComplete: Scalars['Boolean']['output'];
  companyIsComplete: Scalars['Boolean']['output'];
  contactIsComplete: Scalars['Boolean']['output'];
  memberIsComplete: Scalars['Boolean']['output'];
  paymentIsComplete: Scalars['Boolean']['output'];
  paymentMethodIsComplete: Scalars['Boolean']['output'];
  planIsComplete: Scalars['Boolean']['output'];
  profileIsComplete: Scalars['Boolean']['output'];
  subscriptionIsComplete: Scalars['Boolean']['output'];
  tosIsComplete: Scalars['Boolean']['output'];
};

export type MerchantRegistrationForm = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  coc?: InputMaybe<Scalars['String']['input']>;
  extra?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  vat?: InputMaybe<Scalars['String']['input']>;
};

export type MerchantRegistrationOutput = {
  __typename?: 'MerchantRegistrationOutput';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  coc?: Maybe<Scalars['String']['output']>;
  extra?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  vat?: Maybe<Scalars['String']['output']>;
};

export type MerchantRegistrationProgress = {
  __typename?: 'MerchantRegistrationProgress';
  companyId?: Maybe<Scalars['String']['output']>;
  complete: Scalars['Boolean']['output'];
  onboardingProgress: MerchantProgressList;
};

export type MerchantThemeOutput = {
  __typename?: 'MerchantThemeOutput';
  companyId: Scalars['String']['output'];
  companyLogo: Scalars['String']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  isFormal: Scalars['Boolean']['output'];
  primaryColor: Scalars['String']['output'];
  secondaryColor: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type MollieAuthorizationStatus = {
  __typename?: 'MollieAuthorizationStatus';
  authorizeMollieId: Scalars['String']['output'];
  isAuthorized: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTermsForUser: Scalars['Boolean']['output'];
  addAddressToCompany: Scalars['Boolean']['output'];
  addAddressToUser: Scalars['Boolean']['output'];
  addBenefitToTier: Scalars['Boolean']['output'];
  addCompanyToUser: Scalars['Boolean']['output'];
  addContactToCompany: Scalars['Boolean']['output'];
  addExtraToTier: Scalars['Boolean']['output'];
  addToShoppingCart: ShoppingCartOutput;
  buyTier: BuyStatusOutput;
  cancelTier: Scalars['Boolean']['output'];
  createAddress: AddressOutput;
  createAgeDiscount: AgeDiscountOutput;
  createBenefit: BenefitOutput;
  createCocRecord: CocRecordOutput;
  createCompany: CompanyOutput;
  createContact: ContactOutput;
  createDiscountCode: DiscountCodeOutput;
  createDynamicFee: DynamicFeeOutput;
  createExtra: ExtraOutput;
  createFixedFee: FixedFeeOutput;
  createLoyaltyDiscount: LoyaltyDiscountOutput;
  createMerchantTheme: MerchantThemeOutput;
  createMollieProfile: Scalars['Boolean']['output'];
  createOrUpdateFirstReminderSettings: FirstReminderSettingOutput;
  createOrUpdateLastReminderSettings: LastReminderSettingOutput;
  createOrUpdatePaymentRetrySettings: PaymentRetrySettingOutput;
  createOrUpdatePenaltySettings: PenaltySettingOutput;
  createOrUpdateReflectionSettings: ReflectionSettingOutput;
  createPaymentMethod: PaymentMethodSettingOutput;
  createPlan: TierOutput;
  createSubscription: SubscriptionOutput;
  createSubscriptionInterval: SubscriptionIntervalOutput;
  createUser: UserOutput;
  createWelcomeDiscount: WelcomeDiscountOutput;
  deleteAddress: Scalars['Boolean']['output'];
  deleteAgeDiscount: Scalars['Boolean']['output'];
  deleteBenefit: Scalars['Boolean']['output'];
  deleteCocRecord: Scalars['Boolean']['output'];
  deleteCompany: Scalars['Boolean']['output'];
  deleteContact: Scalars['Boolean']['output'];
  deleteDiscountCode: Scalars['Boolean']['output'];
  deleteExtra: Scalars['Boolean']['output'];
  deleteLoyaltyDiscount: Scalars['Boolean']['output'];
  deleteMerchantTheme: Scalars['Boolean']['output'];
  deleteSubscription: Scalars['Boolean']['output'];
  deleteTier: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  deleteWelcomeDiscount: Scalars['Boolean']['output'];
  highlightPlan: TierOutput;
  mfaActivate: Array<Scalars['String']['output']>;
  mfaDeactivate: Scalars['Boolean']['output'];
  mfaVerify: Scalars['Boolean']['output'];
  regenerateMfaCodes: Array<Scalars['String']['output']>;
  registerMerchant: MerchantRegistrationOutput;
  registerMerchantContact: MerchantContactOutput;
  removeAddressFromCompany: Scalars['Boolean']['output'];
  removeAddressFromUser: Scalars['Boolean']['output'];
  removeBenefitFromTier: Scalars['Boolean']['output'];
  removeCompanyFromUser: Scalars['Boolean']['output'];
  removeContactFromCompany: Scalars['Boolean']['output'];
  removeExtraFromTier: Scalars['Boolean']['output'];
  removeFromShoppingCart: Scalars['Boolean']['output'];
  reorderBenefits: Array<BenefitOutput>;
  reorderExtras: Array<ExtraOutput>;
  reorderPaymentMethods: Array<PaymentMethodSettingOutput>;
  reorderPlans: Array<TierOutput>;
  saveChargebackSettings: ChargeBackSettingOutput;
  setActiveMerchant: Scalars['Boolean']['output'];
  toggleInvoiceMoment: SubscriptionIntervalOutput;
  togglePlanBenefit: NestedTierOutput;
  unacceptTermsForUser: Scalars['Boolean']['output'];
  updateAddress: AddressOutput;
  updateAgeDiscount: AgeDiscountOutput;
  updateBenefit: BenefitOutput;
  updateChargebackSettings: ChargeBackSettingOutput;
  updateCocRecord: CocRecordOutput;
  updateCompany: CompanyOutput;
  updateContact: ContactOutput;
  updateDiscountCode: DiscountCodeOutput;
  updateDynamicFee: DynamicFeeOutput;
  updateExtra: ExtraOutput;
  updateFirstReminderSettings: FirstReminderSettingOutput;
  updateFixedFee: FixedFeeOutput;
  updateLastReminderSettings: LastReminderSettingOutput;
  updateLoyaltyDiscount: LoyaltyDiscountOutput;
  updateMerchantTheme: MerchantThemeOutput;
  updatePaymentMethod: PaymentMethodSettingOutput;
  updatePaymentRetrySettings: PaymentRetrySettingOutput;
  updatePenaltySettings: PenaltySettingOutput;
  updateReflectionSettings: ReflectionSettingOutput;
  updateSubscription: SubscriptionOutput;
  updateSubscriptionInterval: SubscriptionIntervalOutput;
  updateTier: TierOutput;
  updateUser: UserOutput;
  updateWelcomeDiscount: WelcomeDiscountOutput;
};


export type MutationAcceptTermsForUserArgs = {
  relationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAddAddressToCompanyArgs = {
  companyId: Scalars['String']['input'];
  relationId: Scalars['String']['input'];
};


export type MutationAddAddressToUserArgs = {
  relationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAddBenefitToTierArgs = {
  relationId: Scalars['String']['input'];
  tierId: Scalars['String']['input'];
};


export type MutationAddCompanyToUserArgs = {
  relationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAddContactToCompanyArgs = {
  companyId: Scalars['String']['input'];
  relationId: Scalars['String']['input'];
};


export type MutationAddExtraToTierArgs = {
  relationId: Scalars['String']['input'];
  tierId: Scalars['String']['input'];
};


export type MutationAddToShoppingCartArgs = {
  input: CreateShoppingCartInput;
};


export type MutationBuyTierArgs = {
  discountCodeId?: InputMaybe<Scalars['String']['input']>;
  intervalId: Scalars['String']['input'];
  tierId: Scalars['String']['input'];
};


export type MutationCancelTierArgs = {
  tierId: Scalars['String']['input'];
};


export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};


export type MutationCreateAgeDiscountArgs = {
  input: CreateAgeDiscountInput;
};


export type MutationCreateBenefitArgs = {
  input: CreateBenefitInput;
};


export type MutationCreateCocRecordArgs = {
  input: CreateCocRecordInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateDiscountCodeArgs = {
  input: CreateDiscountCodeInput;
};


export type MutationCreateDynamicFeeArgs = {
  input: CreateDynamicFeeInput;
};


export type MutationCreateExtraArgs = {
  input: CreateExtraInput;
};


export type MutationCreateFixedFeeArgs = {
  input: CreateFixedFeeInput;
};


export type MutationCreateLoyaltyDiscountArgs = {
  input: CreateLoyaltyDiscountInput;
};


export type MutationCreateMerchantThemeArgs = {
  input: CreateMerchantThemeInput;
};


export type MutationCreateMollieProfileArgs = {
  input: CreateProfileInput;
};


export type MutationCreateOrUpdateFirstReminderSettingsArgs = {
  input: CreateFirstReminderSettingInput;
};


export type MutationCreateOrUpdateLastReminderSettingsArgs = {
  input: CreateLastReminderSettingInput;
};


export type MutationCreateOrUpdatePaymentRetrySettingsArgs = {
  input: CreatePaymentRetrySettingInput;
};


export type MutationCreateOrUpdatePenaltySettingsArgs = {
  input: CreatePenaltySettingInput;
};


export type MutationCreateOrUpdateReflectionSettingsArgs = {
  input: CreateReflectionSettingInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodSettingInput;
};


export type MutationCreatePlanArgs = {
  input: CreateTierInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationCreateSubscriptionIntervalArgs = {
  input: CreateSubscriptionIntervalInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateWelcomeDiscountArgs = {
  input: CreateWelcomeDiscountInput;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteAgeDiscountArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteBenefitArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCocRecordArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteContactArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteDiscountCodeArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteExtraArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteLoyaltyDiscountArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMerchantThemeArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSubscriptionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTierArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteWelcomeDiscountArgs = {
  id: Scalars['String']['input'];
};


export type MutationHighlightPlanArgs = {
  isHighlighted: Scalars['Boolean']['input'];
  planId: Scalars['String']['input'];
};


export type MutationMfaActivateArgs = {
  code: Scalars['String']['input'];
};


export type MutationMfaDeactivateArgs = {
  code: Scalars['String']['input'];
};


export type MutationMfaVerifyArgs = {
  code: Scalars['String']['input'];
};


export type MutationRegenerateMfaCodesArgs = {
  code: Scalars['String']['input'];
};


export type MutationRegisterMerchantArgs = {
  input: MerchantRegistrationForm;
};


export type MutationRegisterMerchantContactArgs = {
  input: MerchantContactForm;
};


export type MutationRemoveAddressFromCompanyArgs = {
  companyId: Scalars['String']['input'];
  relationId: Scalars['String']['input'];
};


export type MutationRemoveAddressFromUserArgs = {
  relationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveBenefitFromTierArgs = {
  relationId: Scalars['String']['input'];
  tierId: Scalars['String']['input'];
};


export type MutationRemoveCompanyFromUserArgs = {
  relationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveContactFromCompanyArgs = {
  companyId: Scalars['String']['input'];
  relationId: Scalars['String']['input'];
};


export type MutationRemoveExtraFromTierArgs = {
  relationId: Scalars['String']['input'];
  tierId: Scalars['String']['input'];
};


export type MutationRemoveFromShoppingCartArgs = {
  tierId: Scalars['String']['input'];
};


export type MutationReorderBenefitsArgs = {
  benefitIds: Array<Scalars['String']['input']>;
};


export type MutationReorderExtrasArgs = {
  extraIds: Array<Scalars['String']['input']>;
};


export type MutationReorderPaymentMethodsArgs = {
  methodIds: Array<Scalars['String']['input']>;
};


export type MutationReorderPlansArgs = {
  planIds: Array<Scalars['String']['input']>;
};


export type MutationSaveChargebackSettingsArgs = {
  input: CreateChargeBackSettingInput;
};


export type MutationSetActiveMerchantArgs = {
  id: Scalars['String']['input'];
};


export type MutationToggleInvoiceMomentArgs = {
  subscriptionIntervalId: Scalars['String']['input'];
};


export type MutationTogglePlanBenefitArgs = {
  benefitId: Scalars['String']['input'];
  planId: Scalars['String']['input'];
};


export type MutationUnacceptTermsForUserArgs = {
  relationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUpdateAddressArgs = {
  id: Scalars['String']['input'];
  input: UpdateAddressInput;
};


export type MutationUpdateAgeDiscountArgs = {
  id: Scalars['String']['input'];
  input: UpdateAgeDiscountInput;
};


export type MutationUpdateBenefitArgs = {
  id: Scalars['String']['input'];
  input: UpdateBenefitInput;
};


export type MutationUpdateChargebackSettingsArgs = {
  id: Scalars['String']['input'];
  input: UpdateChargeBackSettingInput;
};


export type MutationUpdateCocRecordArgs = {
  id: Scalars['String']['input'];
  input: UpdateCocRecordInput;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['String']['input'];
  input: UpdateCompanyInput;
};


export type MutationUpdateContactArgs = {
  id: Scalars['String']['input'];
  input: UpdateContactInput;
};


export type MutationUpdateDiscountCodeArgs = {
  id: Scalars['String']['input'];
  input: UpdateDiscountCodeInput;
};


export type MutationUpdateDynamicFeeArgs = {
  id: Scalars['String']['input'];
  input: UpdateDynamicFeeInput;
};


export type MutationUpdateExtraArgs = {
  id: Scalars['String']['input'];
  input: UpdateExtraInput;
};


export type MutationUpdateFirstReminderSettingsArgs = {
  id: Scalars['String']['input'];
  input: UpdateFirstReminderSettingInput;
};


export type MutationUpdateFixedFeeArgs = {
  id: Scalars['String']['input'];
  input: UpdateFixedFeeInput;
};


export type MutationUpdateLastReminderSettingsArgs = {
  id: Scalars['String']['input'];
  input: UpdateLastReminderSettingInput;
};


export type MutationUpdateLoyaltyDiscountArgs = {
  id: Scalars['String']['input'];
  input: UpdateLoyaltyDiscountInput;
};


export type MutationUpdateMerchantThemeArgs = {
  id: Scalars['String']['input'];
  input: UpdateMerchantThemeInput;
};


export type MutationUpdatePaymentMethodArgs = {
  id: Scalars['String']['input'];
  input: UpdatePaymentMethodSettingInput;
};


export type MutationUpdatePaymentRetrySettingsArgs = {
  id: Scalars['String']['input'];
  input: UpdatePaymentRetrySettingInput;
};


export type MutationUpdatePenaltySettingsArgs = {
  id: Scalars['String']['input'];
  input: UpdatePenaltySettingInput;
};


export type MutationUpdateReflectionSettingsArgs = {
  id: Scalars['String']['input'];
  input: UpdateReflectionSettingInput;
};


export type MutationUpdateSubscriptionArgs = {
  id: Scalars['String']['input'];
  input: UpdateSubscriptionInput;
};


export type MutationUpdateSubscriptionIntervalArgs = {
  id: Scalars['String']['input'];
  input: UpdateSubscriptionIntervalInput;
};


export type MutationUpdateTierArgs = {
  id: Scalars['String']['input'];
  input: UpdateTierInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String']['input'];
  input: UpdateUserInput;
};


export type MutationUpdateWelcomeDiscountArgs = {
  id: Scalars['String']['input'];
  input: UpdateWelcomeDiscountInput;
};

export type NestedSubscriptionOutput = {
  __typename?: 'NestedSubscriptionOutput';
  allowMultiplePlansPerUser: Scalars['Boolean']['output'];
  companyId: Scalars['String']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  intervals: Array<SubscriptionIntervalOutput>;
  status: Scalars['String']['output'];
  tiers: Array<NestedTierOutput>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
  vatPercentage: Scalars['Float']['output'];
};

export type NestedTierOutput = {
  __typename?: 'NestedTierOutput';
  benefits: Array<BenefitOutput>;
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Scalars['String']['output'];
  extends?: Maybe<Scalars['String']['output']>;
  fixedFees: Array<FixedFeeOutput>;
  id: Scalars['String']['output'];
  isHighlighted: Scalars['Boolean']['output'];
  order: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  subscriptionId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export enum PaymentMethod {
  CreditCard = 'CREDIT_CARD',
  Sepa = 'SEPA'
}

export type PaymentMethodOutput = {
  __typename?: 'PaymentMethodOutput';
  externalId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PaymentMethodSettingOutput = {
  __typename?: 'PaymentMethodSettingOutput';
  amount?: Maybe<Scalars['Float']['output']>;
  amountType?: Maybe<Scalars['String']['output']>;
  chargeFee: Scalars['Boolean']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  subscriptionId: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type PaymentRetrySettingOutput = {
  __typename?: 'PaymentRetrySettingOutput';
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  retryBeforeNextReminder: Scalars['Boolean']['output'];
  retryImmediately: Scalars['Boolean']['output'];
  retryNextDay: Scalars['Boolean']['output'];
  subscriptionId: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type PenaltySettingOutput = {
  __typename?: 'PenaltySettingOutput';
  applyDebtCollectionFee: Scalars['Boolean']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  minimumStatutoryInterest: Scalars['Boolean']['output'];
  reminderInterval: Scalars['Int']['output'];
  subscriptionId: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type Query = {
  __typename?: 'Query';
  connectMfaQrCode: Scalars['String']['output'];
  getAddress: AddressOutput;
  getAddresses: Array<AddressOutput>;
  getAgeDiscounts: Array<AgeDiscountOutput>;
  getBenefit: BenefitOutput;
  getBenefits: Array<BenefitOutput>;
  getBenefitsByTierId: Array<BenefitOutput>;
  getBillingConfigStatus: BillingConfigCompletionOutput;
  getBillingRuleStatus: BillingRuleCompletionOutput;
  getChargebackSettings: ChargeBackSettingOutput;
  getChosenTjeckoTier?: Maybe<TierOutput>;
  getCocRecord: CocRecordOutput;
  getCocRecords: Array<CocRecordOutput>;
  getCompanies: Array<CompanyOutput>;
  getCompany: CompanyOutput;
  getContact: ContactOutput;
  getContacts: Array<ContactOutput>;
  getCurrentMerchant: CompanyOutput;
  getDiscountCode: DiscountCodeOutput;
  getExtra: ExtraOutput;
  getExtras: Array<ExtraOutput>;
  getExtrasBySubscriptionId: Array<ExtraOutput>;
  getFirstReminderSettings: FirstReminderSettingOutput;
  getIdentity: Identity;
  getInterval: SubscriptionIntervalOutput;
  getIntervals: Array<SubscriptionIntervalOutput>;
  getInvoiceData: DetailedInvoiceOutput;
  getInvoiceMoments: InvoiceMomentOutput;
  getLastReminderSettings: LastReminderSettingOutput;
  getLoyaltyDiscounts: Array<LoyaltyDiscountOutput>;
  getMerchantProfileProgress: MerchantRegistrationProgress;
  getMerchantTheme: MerchantThemeOutput;
  getMerchantThemes: Array<MerchantThemeOutput>;
  getMerchantTjeckoSubStatus: SubStatusOutput;
  getNestedPlans: Array<NestedTierOutput>;
  getOwnedCompanies: Array<CompanyOutput>;
  getPaymentMethodsForSubscription: Array<PaymentMethodSettingOutput>;
  getPaymentMethodsNotInSubscription: Array<PaymentMethodOutput>;
  getPaymentRetrySettings: PaymentRetrySettingOutput;
  getPenaltySettings: PenaltySettingOutput;
  getPlan: TierOutput;
  getPlans: Array<TierOutput>;
  getPrimaryAddressOfCompany: AddressOutput;
  getReflectionSettings: ReflectionSettingOutput;
  getRegisterMerchantForm: MerchantRegistrationOutput;
  getShoppingCart: Array<ShoppingCartOutput>;
  getSubscription: SubscriptionOutput;
  getSubscriptionConfigStatus: Array<SubscriptionConfigOutput>;
  getSubscriptionDiscounts: Array<SubscriptionDiscountOutput>;
  getSubscriptionFees: Array<SubscriptionFeeOutput>;
  getSubscriptions: Array<SubscriptionOutput>;
  getTerm: TermOutput;
  getTierPrice: TierPriceOutput;
  getTjeckoSubscription: NestedSubscriptionOutput;
  getUser: UserOutput;
  getUserInvoices: Array<InvoiceOutput>;
  getUsers: Array<UserOutput>;
  getWelcomeDiscounts: Array<WelcomeDiscountOutput>;
  isAuthorizedByMollie: MollieAuthorizationStatus;
  latestTerms: TermOutput;
  subscriptionHasWelcomeDiscounts: Scalars['Boolean']['output'];
};


export type QueryGetAddressArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAgeDiscountsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetBenefitArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetBenefitsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetBenefitsByTierIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetBillingConfigStatusArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetBillingRuleStatusArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetChargebackSettingsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetContactArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDiscountCodeArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetExtraArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetExtrasArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetExtrasBySubscriptionIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetFirstReminderSettingsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetIntervalArgs = {
  intervalId: Scalars['String']['input'];
};


export type QueryGetIntervalsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetInvoiceDataArgs = {
  invoiceId: Scalars['String']['input'];
};


export type QueryGetInvoiceMomentsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetLastReminderSettingsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetLoyaltyDiscountsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetNestedPlansArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetPaymentMethodsForSubscriptionArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetPaymentMethodsNotInSubscriptionArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetPaymentRetrySettingsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetPenaltySettingsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetPlanArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPlansArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetPrimaryAddressOfCompanyArgs = {
  companyId: Scalars['String']['input'];
};


export type QueryGetReflectionSettingsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetSubscriptionArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSubscriptionConfigStatusArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetSubscriptionDiscountsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetSubscriptionFeesArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryGetTermArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetTierPriceArgs = {
  discountCodeId?: InputMaybe<Scalars['String']['input']>;
  intervalId: Scalars['String']['input'];
  tierId: Scalars['String']['input'];
};


export type QueryGetUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetWelcomeDiscountsArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type QueryLatestTermsArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySubscriptionHasWelcomeDiscountsArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type ReflectionSettingOutput = {
  __typename?: 'ReflectionSettingOutput';
  cancelCharge: Scalars['Boolean']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  daysOfReflection: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  subscriptionId: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type ShoppingCartOutput = {
  __typename?: 'ShoppingCartOutput';
  discountId?: Maybe<Scalars['String']['output']>;
  intervalId: Scalars['String']['output'];
  tierId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type SubStatusOutput = {
  __typename?: 'SubStatusOutput';
  initialPaymentComplete: Scalars['Boolean']['output'];
  openPayments: Scalars['Boolean']['output'];
  paidLatest: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
};

export type SubscriptionConfigOutput = {
  __typename?: 'SubscriptionConfigOutput';
  finished: Scalars['Boolean']['output'];
  step: Scalars['String']['output'];
};

export type SubscriptionDiscountOutput = {
  __typename?: 'SubscriptionDiscountOutput';
  constraint: ConstraintOutput;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  summary: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SubscriptionFeeOutput = {
  __typename?: 'SubscriptionFeeOutput';
  amount: Scalars['Float']['output'];
  amountType: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  interval?: Maybe<Scalars['Int']['output']>;
  intervalType: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SubscriptionIntervalOutput = {
  __typename?: 'SubscriptionIntervalOutput';
  active: Scalars['Boolean']['output'];
  alwaysBillFullMonth: Scalars['Boolean']['output'];
  billUpfront: Scalars['Boolean']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  day?: Maybe<Scalars['String']['output']>;
  dayOfMonth?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType: Scalars['String']['output'];
  firstPart?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  interval: Scalars['String']['output'];
  isPartOfMonth: Scalars['Boolean']['output'];
  monthlyCancellableAfterCommitment?: Maybe<Scalars['Boolean']['output']>;
  monthlyPayments?: Maybe<Scalars['String']['output']>;
  respectStartDate: Scalars['Boolean']['output'];
  subscriptionId: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type SubscriptionOutput = {
  __typename?: 'SubscriptionOutput';
  allowMultiplePlansPerUser: Scalars['Boolean']['output'];
  companyId: Scalars['String']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
  vatPercentage: Scalars['Float']['output'];
};

export type TermOutput = {
  __typename?: 'TermOutput';
  body: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type TierOutput = {
  __typename?: 'TierOutput';
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Scalars['String']['output'];
  extends?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isHighlighted: Scalars['Boolean']['output'];
  order: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  subscriptionId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type TierPriceOutput = {
  __typename?: 'TierPriceOutput';
  basePrice: Scalars['Float']['output'];
  discountType: Scalars['String']['output'];
  interval: Scalars['String']['output'];
  intervalDiscount: Scalars['Float']['output'];
  promotionDiscount: Scalars['Float']['output'];
  subTotal: Scalars['Float']['output'];
  tax: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type UpdateAddressInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  addressAddition?: InputMaybe<Scalars['String']['input']>;
  addressType?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAgeDiscountInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isLess?: InputMaybe<Scalars['Boolean']['input']>;
  orEqual?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBenefitInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChargeBackSettingInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  collectionCost?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateCocRecordInput = {
  addressId?: InputMaybe<Scalars['String']['input']>;
  cocNumber?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyInput = {
  invoiceEmail?: InputMaybe<Scalars['String']['input']>;
  invoiceRecipient?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDiscountCodeInput = {
  availableAmount?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  useBefore?: InputMaybe<Scalars['NaiveDateTime']['input']>;
};

export type UpdateDynamicFeeInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountMax?: InputMaybe<Scalars['Float']['input']>;
  amountType?: InputMaybe<Scalars['String']['input']>;
  clauseType?: InputMaybe<Scalars['String']['input']>;
  condition?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabledTill?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  enabledTill?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  intervalAmount?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateExtraInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  excludeFromCoupons?: InputMaybe<Scalars['Boolean']['input']>;
  excludeFromPromotions?: InputMaybe<Scalars['Boolean']['input']>;
  excludeFromYearlyDiscounts?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  vatPercentage?: InputMaybe<Scalars['Float']['input']>;
  when?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFirstReminderSettingInput = {
  daysAfterInvoice?: InputMaybe<Scalars['Int']['input']>;
  tone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFixedFeeInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabledTill?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  enabledTill?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  isDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  moment?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLastReminderSettingInput = {
  daysAfterFirstReminder?: InputMaybe<Scalars['Int']['input']>;
  tone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLoyaltyDiscountInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  intervalYears?: InputMaybe<Scalars['Int']['input']>;
  maximumAmount?: InputMaybe<Scalars['Float']['input']>;
  maximumAmountType?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  yearsSubscribed?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateMerchantThemeInput = {
  companyLogo?: InputMaybe<Scalars['String']['input']>;
  isFormal?: InputMaybe<Scalars['Boolean']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  secondaryColor?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePaymentMethodSettingInput = {
  chargeFee?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePaymentRetrySettingInput = {
  retryBeforeNextReminder?: InputMaybe<Scalars['Boolean']['input']>;
  retryImmediately?: InputMaybe<Scalars['Boolean']['input']>;
  retryNextDay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePenaltySettingInput = {
  applyDebtCollectionFee?: InputMaybe<Scalars['Boolean']['input']>;
  minimumStatutoryInterest?: InputMaybe<Scalars['Boolean']['input']>;
  reminderInterval?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateReflectionSettingInput = {
  cancelCharge?: InputMaybe<Scalars['Boolean']['input']>;
  daysOfReflection?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSubscriptionInput = {
  allowMultiplePlansPerUser?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  vatPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateSubscriptionIntervalInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  alwaysBillFullMonth?: InputMaybe<Scalars['Boolean']['input']>;
  billUpfront?: InputMaybe<Scalars['Boolean']['input']>;
  day?: InputMaybe<Scalars['String']['input']>;
  dayOfMonth?: InputMaybe<Scalars['Int']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  firstPart?: InputMaybe<Scalars['Boolean']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  isPartOfMonth?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyCancellableAfterCommitment?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyPayments?: InputMaybe<Scalars['String']['input']>;
  respectStartDate?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTierInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  extends?: InputMaybe<Scalars['String']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  dob?: InputMaybe<Scalars['NaiveDate']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWelcomeDiscountInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  months?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UserIdentity = {
  __typename?: 'UserIdentity';
  comments?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  latestTerms?: Maybe<Scalars['String']['output']>;
  mfaEnabled: Scalars['Boolean']['output'];
  mfaVerified: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type UserOutput = {
  __typename?: 'UserOutput';
  createdAt: Scalars['NaiveDateTime']['output'];
  dob?: Maybe<Scalars['NaiveDate']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type WelcomeDiscountOutput = {
  __typename?: 'WelcomeDiscountOutput';
  amount: Scalars['Float']['output'];
  amountType: Scalars['String']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  months: Scalars['Int']['output'];
  subscriptionId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type RegisterCompanyFormFragment = { __typename?: 'CompanyOutput', id: string, name: string, invoiceEmail: string, createdAt: any, deletedAt?: any, invoiceRecipient: string, rootUserId: string, updatedAt: any };

export type CompanyFormFragment = { __typename?: 'CompanyOutput', name: string, invoiceEmail: string, invoiceRecipient: string };

export type BaseCompanyFragment = { __typename?: 'CompanyOutput', id: string, name: string, invoiceEmail: string, invoiceRecipient: string, createdAt: any, updatedAt: any };

export type BaseMerchantThemeFragment = { __typename?: 'MerchantThemeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, companyLogo: string, primaryColor: string, secondaryColor: string, isFormal: boolean };

export type BaseAddressFragment = { __typename?: 'AddressOutput', address: string, addressAddition?: string, addressType: string, city: string, companyName?: string, createdAt: any, deletedAt?: any, id: string, postalCode: string, updatedAt: any };

export type BaseContactFragment = { __typename?: 'ContactOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, firstName: string, middleName?: string, lastName: string, position?: string, email: string, phone: string };

export type MinimumTosFragment = { __typename?: 'TermOutput', id: string, companyId: string, title: string, body: string, createdAt: any, updatedAt: any, deletedAt?: any };

export type IsAuthorizedByMollieFragFragment = { __typename?: 'MollieAuthorizationStatus', isAuthorized: boolean, authorizeMollieId: string };

export type BenefitFormFragment = { __typename?: 'BenefitOutput', id: string, title: string, description: string };

export type BaseBenefitFragment = { __typename?: 'BenefitOutput', id: string, title: string, description: string, subscriptionId: string, active?: boolean, createdAt: any, updatedAt: any, deletedAt?: any, order: number };

export type BaseAgeDiscountFragment = { __typename?: 'AgeDiscountOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, amount: number, amountType: string, isLess: boolean, orEqual: boolean, age: number, subscriptionId: string };

export type BaseLoyaltyDiscountFragment = { __typename?: 'LoyaltyDiscountOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, amount: number, amountType: string, yearsSubscribed: number, intervalYears: number, maximumAmount: number, maximumAmountType: string, subscriptionId: string };

export type BaseWelcomeDiscountFragment = { __typename?: 'WelcomeDiscountOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, amount: number, amountType: string, months: number, subscriptionId: string };

export type BaseDiscountCodeFragment = { __typename?: 'DiscountCodeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, useBefore?: any, title: string, description: string, discount: number };

export type BaseExtraFragment = { __typename?: 'ExtraOutput', id: string, title: string, description: string, value: number, type: string, when: string, active: boolean, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, vatPercentage: number, excludeFromYearlyDiscounts: boolean, excludeFromPromotions: boolean, excludeFromCoupons: boolean };

export type BaseSubscriptionFeesFragment = { __typename?: 'SubscriptionFeeOutput', id: string, title: string, description: string, type: string, amount: number, amountType: string, intervalType: string, interval?: number };

export type BaseFixedFeeFragment = {
  __typename?: 'FixedFeeOutput',
  id: string,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any,
  title: string,
  description: string,
  amount: number,
  amountType: string,
  moment: string,
  subscriptionId: string
};

export type BaseDynamicFeeFragment = { __typename?: 'DynamicFeeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, clauseType: string, condition: string, amount: number, amountType: string, amountMax?: number, interval: string, active: boolean, disabledTill?: any, enabledTill?: any, order: number, subscriptionId: string, intervalAmount: number };

export type SubscriptionFormFragment = { __typename?: 'SubscriptionOutput', id: string, allowMultiplePlansPerUser: boolean, title: string, description: string, vatPercentage: number };

export type BaseSubscriptionFragment = { __typename?: 'SubscriptionOutput', id: string, title: string, description: string, vatPercentage: number, createdAt: any, updatedAt: any, deletedAt?: any, companyId: string, allowMultiplePlansPerUser: boolean, status: string };

export type TierFormFragment = { __typename?: 'TierOutput', createdAt: any, deletedAt?: any, description: string, extends?: string, id: string, price: number, subscriptionId: string, title: string, updatedAt: any };

export type BaseTierFragment = { __typename?: 'TierOutput', id: string, title: string, description: string, price: number, extends?: string, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, isHighlighted: boolean };

export type BuyFormFragment = { __typename?: 'BuyStatusOutput', paymentLink?: string, status: string };

export type BaseIdentityFragment = { __typename?: 'Identity', domain: string, user: { __typename?: 'UserIdentity', id: string, name: string, email: string, latestTerms?: string, mfaEnabled: boolean, mfaVerified: boolean } };

export type UserRegistrationFormFragment = { __typename?: 'MerchantContactOutput', firstName: string, middleName?: string, lastName: string, position?: string, email: string, phone: string, address: string, postalCode: string, city: string, addressAddition?: string };

export type RegisterMerchantFormFragment = { __typename?: 'MerchantRegistrationOutput', name: string, coc?: string, vat?: string, address?: string, extra?: string, city?: string, postalCode?: string };

export type BaseUserFragment = { __typename?: 'UserOutput', id: string, firstName: string, lastName: string, email: string, createdAt: any, updatedAt: any };

export type GetRegisterCompanyFormQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRegisterCompanyFormQuery = { __typename?: 'Query', getCompany: { __typename?: 'CompanyOutput', id: string, name: string, invoiceEmail: string, createdAt: any, deletedAt?: any, invoiceRecipient: string, rootUserId: string, updatedAt: any } };

export type RegisterCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type RegisterCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'CompanyOutput', name: string, invoiceEmail: string, invoiceRecipient: string } };

export type GetCompanyFormQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyFormQuery = { __typename?: 'Query', getCompany: { __typename?: 'CompanyOutput', name: string, invoiceEmail: string, invoiceRecipient: string } };

export type GetCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyQuery = { __typename?: 'Query', getCompany: { __typename?: 'CompanyOutput', id: string, name: string, invoiceEmail: string, invoiceRecipient: string, createdAt: any, updatedAt: any } };

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompaniesQuery = { __typename?: 'Query', getCompanies: Array<{ __typename?: 'CompanyOutput', id: string, name: string, invoiceEmail: string, invoiceRecipient: string, createdAt: any, updatedAt: any }> };

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'CompanyOutput', name: string, invoiceEmail: string, invoiceRecipient: string } };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename?: 'CompanyOutput', name: string, invoiceEmail: string, invoiceRecipient: string } };

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteCompanyMutation = { __typename?: 'Mutation', deleteCompany: boolean };

export type AddAddressToCompanyMutationVariables = Exact<{
  relationId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
}>;


export type AddAddressToCompanyMutation = { __typename?: 'Mutation', addAddressToCompany: boolean };

export type AddContactToCompanyMutationVariables = Exact<{
  relationId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
}>;


export type AddContactToCompanyMutation = { __typename?: 'Mutation', addContactToCompany: boolean };

export type RemoveAddressFromCompanyMutationVariables = Exact<{
  relationId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
}>;


export type RemoveAddressFromCompanyMutation = { __typename?: 'Mutation', removeAddressFromCompany: boolean };

export type RemoveContactFromCompanyMutationVariables = Exact<{
  relationId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
}>;


export type RemoveContactFromCompanyMutation = { __typename?: 'Mutation', removeContactFromCompany: boolean };

export type GetMerchantThemeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMerchantThemeQuery = { __typename?: 'Query', getMerchantTheme: { __typename?: 'MerchantThemeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, companyLogo: string, primaryColor: string, secondaryColor: string, isFormal: boolean } };

export type CreateMerchantThemeMutationVariables = Exact<{
  input: CreateMerchantThemeInput;
}>;


export type CreateMerchantThemeMutation = { __typename?: 'Mutation', createMerchantTheme: { __typename?: 'MerchantThemeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, companyLogo: string, primaryColor: string, secondaryColor: string, isFormal: boolean } };

export type UpdateMerchantThemeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateMerchantThemeInput;
}>;


export type UpdateMerchantThemeMutation = { __typename?: 'Mutation', updateMerchantTheme: { __typename?: 'MerchantThemeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, companyLogo: string, primaryColor: string, secondaryColor: string, isFormal: boolean } };

export type DeleteMerchantThemeMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteMerchantThemeMutation = { __typename?: 'Mutation', deleteMerchantTheme: boolean };

export type GetAddressQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetAddressQuery = { __typename?: 'Query', getAddress: { __typename?: 'AddressOutput', address: string, addressAddition?: string, addressType: string, city: string, companyName?: string, createdAt: any, deletedAt?: any, id: string, postalCode: string, updatedAt: any } };

export type GetAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAddressesQuery = { __typename?: 'Query', getAddresses: Array<{ __typename?: 'AddressOutput', address: string, addressAddition?: string, addressType: string, city: string, companyName?: string, createdAt: any, deletedAt?: any, id: string, postalCode: string, updatedAt: any }> };

export type CreateAddressMutationVariables = Exact<{
  input: CreateAddressInput;
}>;


export type CreateAddressMutation = { __typename?: 'Mutation', createAddress: { __typename?: 'AddressOutput', address: string, addressAddition?: string, addressType: string, city: string, companyName?: string, createdAt: any, deletedAt?: any, id: string, postalCode: string, updatedAt: any } };

export type UpdateAddressMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateAddressInput;
}>;


export type UpdateAddressMutation = { __typename?: 'Mutation', updateAddress: { __typename?: 'AddressOutput', address: string, addressAddition?: string, addressType: string, city: string, companyName?: string, createdAt: any, deletedAt?: any, id: string, postalCode: string, updatedAt: any } };

export type DeleteAddressMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteAddressMutation = { __typename?: 'Mutation', deleteAddress: boolean };

export type GetContactQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetContactQuery = { __typename?: 'Query', getContact: { __typename?: 'ContactOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, firstName: string, middleName?: string, lastName: string, position?: string, email: string, phone: string } };

export type GetContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactsQuery = { __typename?: 'Query', getContacts: Array<{ __typename?: 'ContactOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, firstName: string, middleName?: string, lastName: string, position?: string, email: string, phone: string }> };

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', createContact: { __typename?: 'ContactOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, firstName: string, middleName?: string, lastName: string, position?: string, email: string, phone: string } };

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateContactInput;
}>;


export type UpdateContactMutation = { __typename?: 'Mutation', updateContact: { __typename?: 'ContactOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, firstName: string, middleName?: string, lastName: string, position?: string, email: string, phone: string } };

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteContactMutation = { __typename?: 'Mutation', deleteContact: boolean };

export type LatestTermsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']['input']>;
}>;


export type LatestTermsQuery = { __typename?: 'Query', latestTerms: { __typename?: 'TermOutput', id: string, companyId: string, title: string, body: string, createdAt: any, updatedAt: any, deletedAt?: any } };

export type ConnectMfaQrCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type ConnectMfaQrCodeQuery = { __typename?: 'Query', connectMfaQrCode: string };

export type RegenerateMfaCodesMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type RegenerateMfaCodesMutation = { __typename?: 'Mutation', regenerateMfaCodes: Array<string> };

export type MfaVerifyMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type MfaVerifyMutation = { __typename?: 'Mutation', mfaVerify: boolean };

export type MfaActivateMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type MfaActivateMutation = { __typename?: 'Mutation', mfaActivate: Array<string> };

export type MfaDeactivateMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type MfaDeactivateMutation = { __typename?: 'Mutation', mfaDeactivate: boolean };

export type IsAuthorizedByMollieQueryVariables = Exact<{ [key: string]: never; }>;


export type IsAuthorizedByMollieQuery = { __typename?: 'Query', isAuthorizedByMollie: { __typename?: 'MollieAuthorizationStatus', isAuthorized: boolean, authorizeMollieId: string } };

export type GetBenefitFormQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetBenefitFormQuery = { __typename?: 'Query', getBenefit: { __typename?: 'BenefitOutput', id: string, title: string, description: string } };

export type GetBenefitQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetBenefitQuery = { __typename?: 'Query', getBenefit: { __typename?: 'BenefitOutput', id: string, title: string, description: string, subscriptionId: string, active?: boolean, createdAt: any, updatedAt: any, deletedAt?: any, order: number } };

export type GetBenefitsQueryVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
}>;


export type GetBenefitsQuery = { __typename?: 'Query', getBenefits: Array<{ __typename?: 'BenefitOutput', id: string, title: string, description: string, subscriptionId: string, active?: boolean, createdAt: any, updatedAt: any, deletedAt?: any, order: number }> };

export type GetBenefitsByTierIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetBenefitsByTierIdQuery = { __typename?: 'Query', getBenefitsByTierId: Array<{ __typename?: 'BenefitOutput', id: string, title: string, description: string, subscriptionId: string, active?: boolean, createdAt: any, updatedAt: any, deletedAt?: any, order: number }> };

export type GetNestedPlanQueryVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
}>;


export type GetNestedPlanQuery = { __typename?: 'Query', getNestedPlans: Array<{ __typename?: 'NestedTierOutput', id: string, title: string, description: string, price: number, extends?: string, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, isHighlighted: boolean, fixedFees: Array<{ __typename?: 'FixedFeeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, isDiscount: boolean, amount: number, amountType: string, moment: string, active: boolean, disabledTill?: any, enabledTill?: any, order: number, subscriptionId: string }>, benefits: Array<{ __typename?: 'BenefitOutput', id: string, title: string, description: string, subscriptionId: string, active?: boolean, createdAt: any, updatedAt: any, deletedAt?: any, order: number }> }> };

export type CreateBenefitMutationVariables = Exact<{
  input: CreateBenefitInput;
}>;


export type CreateBenefitMutation = { __typename?: 'Mutation', createBenefit: { __typename?: 'BenefitOutput', id: string, title: string, description: string } };

export type AddBenefitToTierMutationVariables = Exact<{
  benefitId: Scalars['String']['input'];
  tierId: Scalars['String']['input'];
}>;


export type AddBenefitToTierMutation = { __typename?: 'Mutation', addBenefitToTier: boolean };

export type RemoveBenefitForTierMutationVariables = Exact<{
  benefitId: Scalars['String']['input'];
  tierId: Scalars['String']['input'];
}>;


export type RemoveBenefitForTierMutation = { __typename?: 'Mutation', removeBenefitFromTier: boolean };

export type CreateBenefitForTierMutationVariables = Exact<{
  input: CreateBenefitInput;
}>;


export type CreateBenefitForTierMutation = { __typename?: 'Mutation', createBenefit: { __typename?: 'BenefitOutput', id: string, title: string, description: string } };

export type UpdateBenefitMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateBenefitInput;
}>;


export type UpdateBenefitMutation = { __typename?: 'Mutation', updateBenefit: { __typename?: 'BenefitOutput', id: string, title: string, description: string } };

export type DeleteBenefitMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteBenefitMutation = { __typename?: 'Mutation', deleteBenefit: boolean };

export type ReorderBenefitsMutationVariables = Exact<{
  benefitId: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ReorderBenefitsMutation = { __typename?: 'Mutation', reorderBenefits: Array<{ __typename?: 'BenefitOutput', id: string, title: string, description: string, subscriptionId: string, active?: boolean, createdAt: any, updatedAt: any, deletedAt?: any, order: number }> };

export type TogglePlanBenefitMutationVariables = Exact<{
  planId: Scalars['String']['input'];
  benefitId: Scalars['String']['input'];
}>;


export type TogglePlanBenefitMutation = { __typename?: 'Mutation', togglePlanBenefit: { __typename?: 'NestedTierOutput', id: string, title: string, description: string, price: number, extends?: string, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, isHighlighted: boolean, fixedFees: Array<{ __typename?: 'FixedFeeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, isDiscount: boolean, amount: number, amountType: string, moment: string, active: boolean, disabledTill?: any, enabledTill?: any, order: number, subscriptionId: string }>, benefits: Array<{ __typename?: 'BenefitOutput', id: string, title: string, description: string, subscriptionId: string, active?: boolean, createdAt: any, updatedAt: any, deletedAt?: any, order: number }> } };

export type GetSubscriptionDiscountsQueryVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
}>;


export type GetSubscriptionDiscountsQuery = { __typename?: 'Query', getSubscriptionDiscounts: Array<{ __typename?: 'SubscriptionDiscountOutput', id: string, name: string, type: string, summary: string, constraint: { __typename?: 'ConstraintOutput', value: string, boolean: string } }> };

export type GetAgeDiscountsQueryVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
}>;


export type GetAgeDiscountsQuery = { __typename?: 'Query', getAgeDiscounts: Array<{ __typename?: 'AgeDiscountOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, amount: number, amountType: string, isLess: boolean, orEqual: boolean, age: number, subscriptionId: string }> };

export type GetLoyaltyDiscountsQueryVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
}>;


export type GetLoyaltyDiscountsQuery = { __typename?: 'Query', getLoyaltyDiscounts: Array<{ __typename?: 'LoyaltyDiscountOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, amount: number, amountType: string, yearsSubscribed: number, intervalYears: number, maximumAmount: number, maximumAmountType: string, subscriptionId: string }> };

export type GetWelcomeDiscountsQueryVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
}>;


export type GetWelcomeDiscountsQuery = { __typename?: 'Query', getWelcomeDiscounts: Array<{ __typename?: 'WelcomeDiscountOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, amount: number, amountType: string, months: number, subscriptionId: string }> };

export type CreateAgeDiscountMutationVariables = Exact<{
  input: CreateAgeDiscountInput;
}>;


export type CreateAgeDiscountMutation = { __typename?: 'Mutation', createAgeDiscount: { __typename?: 'AgeDiscountOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, amount: number, amountType: string, isLess: boolean, orEqual: boolean, age: number, subscriptionId: string } };

export type UpdateAgeDiscountMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateAgeDiscountInput;
}>;


export type UpdateAgeDiscountMutation = { __typename?: 'Mutation', updateAgeDiscount: { __typename?: 'AgeDiscountOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, amount: number, amountType: string, isLess: boolean, orEqual: boolean, age: number, subscriptionId: string } };

export type CreateLoyaltyDiscountMutationVariables = Exact<{
  input: CreateLoyaltyDiscountInput;
}>;


export type CreateLoyaltyDiscountMutation = { __typename?: 'Mutation', createLoyaltyDiscount: { __typename?: 'LoyaltyDiscountOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, amount: number, amountType: string, yearsSubscribed: number, intervalYears: number, maximumAmount: number, maximumAmountType: string, subscriptionId: string } };

export type UpdateLoyaltyDiscountMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateLoyaltyDiscountInput;
}>;


export type UpdateLoyaltyDiscountMutation = { __typename?: 'Mutation', updateLoyaltyDiscount: { __typename?: 'LoyaltyDiscountOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, amount: number, amountType: string, yearsSubscribed: number, intervalYears: number, maximumAmount: number, maximumAmountType: string, subscriptionId: string } };

export type CreateWelcomeDiscountMutationVariables = Exact<{
  input: CreateWelcomeDiscountInput;
}>;


export type CreateWelcomeDiscountMutation = { __typename?: 'Mutation', createWelcomeDiscount: { __typename?: 'WelcomeDiscountOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, amount: number, amountType: string, months: number, subscriptionId: string } };

export type UpdateWelcomeDiscountMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateWelcomeDiscountInput;
}>;


export type UpdateWelcomeDiscountMutation = { __typename?: 'Mutation', updateWelcomeDiscount: { __typename?: 'WelcomeDiscountOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, amount: number, amountType: string, months: number, subscriptionId: string } };

export type GetDiscountCodeQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetDiscountCodeQuery = { __typename?: 'Query', getDiscountCode: { __typename?: 'DiscountCodeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, useBefore?: any, title: string, description: string, discount: number } };

export type CreateDiscountCodeMutationVariables = Exact<{
  input: CreateDiscountCodeInput;
}>;


export type CreateDiscountCodeMutation = { __typename?: 'Mutation', createDiscountCode: { __typename?: 'DiscountCodeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, useBefore?: any, title: string, description: string, discount: number } };

export type UpdateDiscountCodeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateDiscountCodeInput;
}>;


export type UpdateDiscountCodeMutation = { __typename?: 'Mutation', updateDiscountCode: { __typename?: 'DiscountCodeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, useBefore?: any, title: string, description: string, discount: number } };

export type DeleteDiscountCodeMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteDiscountCodeMutation = { __typename?: 'Mutation', deleteDiscountCode: boolean };

export type GetExtraQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetExtraQuery = { __typename?: 'Query', getExtra: { __typename?: 'ExtraOutput', id: string, title: string, description: string, value: number, type: string, when: string, active: boolean, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, vatPercentage: number, excludeFromYearlyDiscounts: boolean, excludeFromPromotions: boolean, excludeFromCoupons: boolean } };

export type GetExtrasBySubscriptionIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetExtrasBySubscriptionIdQuery = { __typename?: 'Query', getExtrasBySubscriptionId: Array<{ __typename?: 'ExtraOutput', id: string, title: string, description: string, value: number, type: string, when: string, active: boolean, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, vatPercentage: number, excludeFromYearlyDiscounts: boolean, excludeFromPromotions: boolean, excludeFromCoupons: boolean }> };

export type GetExtrasQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetExtrasQuery = { __typename?: 'Query', getExtras: Array<{ __typename?: 'ExtraOutput', id: string, title: string, description: string, value: number, type: string, when: string, active: boolean, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, vatPercentage: number, excludeFromYearlyDiscounts: boolean, excludeFromPromotions: boolean, excludeFromCoupons: boolean }> };

export type CreateExtraMutationVariables = Exact<{
  input: CreateExtraInput;
}>;


export type CreateExtraMutation = { __typename?: 'Mutation', createExtra: { __typename?: 'ExtraOutput', id: string, title: string, description: string, value: number, type: string, when: string, active: boolean, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, vatPercentage: number, excludeFromYearlyDiscounts: boolean, excludeFromPromotions: boolean, excludeFromCoupons: boolean } };

export type AddExtraToTierMutationVariables = Exact<{
  relation: Scalars['String']['input'];
  tierId: Scalars['String']['input'];
}>;


export type AddExtraToTierMutation = { __typename?: 'Mutation', addExtraToTier: boolean };

export type UpdateExtraMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateExtraInput;
}>;


export type UpdateExtraMutation = { __typename?: 'Mutation', updateExtra: { __typename?: 'ExtraOutput', id: string, title: string, description: string, value: number, type: string, when: string, active: boolean, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, vatPercentage: number, excludeFromYearlyDiscounts: boolean, excludeFromPromotions: boolean, excludeFromCoupons: boolean } };

export type DeleteExtraMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteExtraMutation = { __typename?: 'Mutation', deleteExtra: boolean };

export type ReorderExtrasMutationVariables = Exact<{
  extraIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ReorderExtrasMutation = { __typename?: 'Mutation', reorderExtras: Array<{ __typename?: 'ExtraOutput', id: string, title: string, description: string, value: number, type: string, when: string, active: boolean, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, vatPercentage: number, excludeFromYearlyDiscounts: boolean, excludeFromPromotions: boolean, excludeFromCoupons: boolean }> };

export type GetSubscriptionFeesQueryVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
}>;


export type GetSubscriptionFeesQuery = { __typename?: 'Query', getSubscriptionFees: Array<{ __typename?: 'SubscriptionFeeOutput', id: string, title: string, description: string, type: string, amount: number, amountType: string, intervalType: string, interval?: number }> };

export type CreateFixedFeeMutationVariables = Exact<{
  input: CreateFixedFeeInput;
}>;


export type CreateFixedFeeMutation = {
  __typename?: 'Mutation',
  createFixedFee: {
    __typename?: 'FixedFeeOutput',
    id: string,
    createdAt: any,
    updatedAt: any,
    deletedAt?: any,
    title: string,
    description: string,
    amount: number,
    amountType: string,
    moment: string,
    subscriptionId: string
  }
};

export type UpdateFixedFeeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateFixedFeeInput;
}>;


export type UpdateFixedFeeMutation = {
  __typename?: 'Mutation',
  updateFixedFee: {
    __typename?: 'FixedFeeOutput',
    id: string,
    createdAt: any,
    updatedAt: any,
    deletedAt?: any,
    title: string,
    description: string,
    amount: number,
    amountType: string,
    moment: string,
    subscriptionId: string
  }
};

export type CreateDynamicFeeMutationVariables = Exact<{
  input: CreateDynamicFeeInput;
}>;


export type CreateDynamicFeeMutation = { __typename?: 'Mutation', createDynamicFee: { __typename?: 'DynamicFeeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, clauseType: string, condition: string, amount: number, amountType: string, amountMax?: number, interval: string, active: boolean, disabledTill?: any, enabledTill?: any, order: number, subscriptionId: string, intervalAmount: number } };

export type UpdateDynamicFeeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateDynamicFeeInput;
}>;


export type UpdateDynamicFeeMutation = { __typename?: 'Mutation', updateDynamicFee: { __typename?: 'DynamicFeeOutput', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description: string, clauseType: string, condition: string, amount: number, amountType: string, amountMax?: number, interval: string, active: boolean, disabledTill?: any, enabledTill?: any, order: number, subscriptionId: string, intervalAmount: number } };

export type GetSubscriptionConfigStatusQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetSubscriptionConfigStatusQuery = { __typename?: 'Query', getSubscriptionConfigStatus: Array<{ __typename?: 'SubscriptionConfigOutput', step: string, finished: boolean }> };

export type GetSubscriptionFormQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetSubscriptionFormQuery = { __typename?: 'Query', getSubscription: { __typename?: 'SubscriptionOutput', id: string, allowMultiplePlansPerUser: boolean, title: string, description: string, vatPercentage: number } };

export type GetSubscriptionQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetSubscriptionQuery = { __typename?: 'Query', getSubscription: { __typename?: 'SubscriptionOutput', id: string, title: string, description: string, vatPercentage: number, createdAt: any, updatedAt: any, deletedAt?: any, companyId: string, allowMultiplePlansPerUser: boolean, status: string } };

export type GetSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionsQuery = { __typename?: 'Query', getSubscriptions: Array<{ __typename?: 'SubscriptionOutput', id: string, title: string, description: string, vatPercentage: number, createdAt: any, updatedAt: any, deletedAt?: any, companyId: string, allowMultiplePlansPerUser: boolean, status: string }> };

export type GetTjeckoSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTjeckoSubscriptionQuery = { __typename?: 'Query', getTjeckoSubscription: { __typename?: 'NestedSubscriptionOutput', id: string, title: string, description: string, vatPercentage: number, createdAt: any, updatedAt: any, deletedAt?: any, companyId: string, intervals: Array<{ __typename?: 'SubscriptionIntervalOutput', id: string, interval: string, discountAmount?: number, discountType: string, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any }>, tiers: Array<{ __typename?: 'NestedTierOutput', id: string, title: string, description: string, price: number, extends?: string, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, benefits: Array<{ __typename?: 'BenefitOutput', id: string, title: string, description: string, subscriptionId: string, active?: boolean, createdAt: any, updatedAt: any, deletedAt?: any }> }> } };

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'SubscriptionOutput', id: string, allowMultiplePlansPerUser: boolean, title: string, description: string, vatPercentage: number } };

export type UpdateSubscriptionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateSubscriptionInput;
}>;


export type UpdateSubscriptionMutation = { __typename?: 'Mutation', updateSubscription: { __typename?: 'SubscriptionOutput', id: string, allowMultiplePlansPerUser: boolean, title: string, description: string, vatPercentage: number } };

export type DeleteSubscriptionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteSubscriptionMutation = { __typename?: 'Mutation', deleteSubscription: boolean };

export type GetPlanFormQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetPlanFormQuery = { __typename?: 'Query', getPlan: { __typename?: 'TierOutput', createdAt: any, deletedAt?: any, description: string, extends?: string, id: string, price: number, subscriptionId: string, title: string, updatedAt: any } };

export type GetPlanQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetPlanQuery = { __typename?: 'Query', getPlan: { __typename?: 'TierOutput', id: string, title: string, description: string, price: number, extends?: string, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, isHighlighted: boolean } };

export type GetPlansQueryVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
}>;


export type GetPlansQuery = { __typename?: 'Query', getPlans: Array<{ __typename?: 'TierOutput', id: string, title: string, description: string, price: number, extends?: string, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, isHighlighted: boolean }> };

export type CreateTierMutationVariables = Exact<{
  input: CreateTierInput;
}>;


export type CreateTierMutation = { __typename?: 'Mutation', createPlan: { __typename?: 'TierOutput', createdAt: any, deletedAt?: any, description: string, extends?: string, id: string, price: number, subscriptionId: string, title: string, updatedAt: any } };

export type UpdateTierMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateTierInput;
}>;


export type UpdateTierMutation = { __typename?: 'Mutation', updateTier: { __typename?: 'TierOutput', createdAt: any, deletedAt?: any, description: string, extends?: string, id: string, price: number, subscriptionId: string, title: string, updatedAt: any } };

export type DeleteTierMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteTierMutation = { __typename?: 'Mutation', deleteTier: boolean };

export type BuyTierMutationVariables = Exact<{
  tierId: Scalars['String']['input'];
  intervalId: Scalars['String']['input'];
  discountId?: InputMaybe<Scalars['String']['input']>;
}>;


export type BuyTierMutation = { __typename?: 'Mutation', buyTier: { __typename?: 'BuyStatusOutput', paymentLink?: string, status: string } };

export type GetShoppingCartQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShoppingCartQuery = { __typename?: 'Query', getShoppingCart: Array<{ __typename?: 'ShoppingCartOutput', userId: string, tierId: string, intervalId: string, discountId?: string }> };

export type HighlightPlanMutationVariables = Exact<{
  id: Scalars['String']['input'];
  isHighlighted: Scalars['Boolean']['input'];
}>;


export type HighlightPlanMutation = { __typename?: 'Mutation', highlightPlan: { __typename?: 'TierOutput', title: string, description: string, price: number, extends?: string, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, isHighlighted: boolean } };

export type ReorderPlansMutationVariables = Exact<{
  id: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ReorderPlansMutation = { __typename?: 'Mutation', reorderPlans: Array<{ __typename?: 'TierOutput', title: string, description: string, price: number, extends?: string, subscriptionId: string, createdAt: any, updatedAt: any, deletedAt?: any, order: number, isHighlighted: boolean }> };

export type AddToShoppingCartMutationVariables = Exact<{
  input: CreateShoppingCartInput;
}>;


export type AddToShoppingCartMutation = { __typename?: 'Mutation', addToShoppingCart: { __typename?: 'ShoppingCartOutput', userId: string, tierId: string, intervalId: string } };

export type RemoveTierFromShoppingCartMutationVariables = Exact<{
  tierId: Scalars['String']['input'];
}>;


export type RemoveTierFromShoppingCartMutation = { __typename?: 'Mutation', removeFromShoppingCart: boolean };

export type GetIdentityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIdentityQuery = { __typename?: 'Query', getIdentity: { __typename?: 'Identity', domain: string, user: { __typename?: 'UserIdentity', id: string, name: string, email: string, latestTerms?: string, mfaEnabled: boolean, mfaVerified: boolean } } };

export type SetActiveMerchantMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type SetActiveMerchantMutation = { __typename?: 'Mutation', setActiveMerchant: boolean };

export type GetCurrentMerchantQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentMerchantQuery = { __typename?: 'Query', getCurrentMerchant: { __typename?: 'CompanyOutput', id: string, name: string, invoiceEmail: string, createdAt: any, deletedAt?: any, invoiceRecipient: string, rootUserId: string, updatedAt: any } };

export type RegisterMerchantContactMutationVariables = Exact<{
  input: MerchantContactForm;
}>;


export type RegisterMerchantContactMutation = { __typename?: 'Mutation', registerMerchantContact: { __typename?: 'MerchantContactOutput', firstName: string, middleName?: string, lastName: string, position?: string, email: string, phone: string, address: string, postalCode: string, city: string, addressAddition?: string } };

export type RegisterMerchantMutationVariables = Exact<{
  input: MerchantRegistrationForm;
}>;


export type RegisterMerchantMutation = { __typename?: 'Mutation', registerMerchant: { __typename?: 'MerchantRegistrationOutput', name: string, coc?: string, vat?: string, address?: string, extra?: string, city?: string, postalCode?: string } };

export type GetRegisterMerchantFormQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRegisterMerchantFormQuery = { __typename?: 'Query', getRegisterMerchantForm: { __typename?: 'MerchantRegistrationOutput', name: string, coc?: string, vat?: string, address?: string, extra?: string, city?: string, postalCode?: string } };

export type GetMerchantProfileProgressQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMerchantProfileProgressQuery = { __typename?: 'Query', getMerchantProfileProgress: { __typename?: 'MerchantRegistrationProgress', complete: boolean, companyId?: string, onboardingProgress: { __typename?: 'MerchantProgressList', contactIsComplete: boolean, addressIsComplete: boolean, subscriptionIsComplete: boolean, memberIsComplete: boolean } } };

export type GetMerchantTjeckoSubStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMerchantTjeckoSubStatusQuery = { __typename?: 'Query', getMerchantTjeckoSubStatus: { __typename?: 'SubStatusOutput', status: string, paidLatest: boolean, initialPaymentComplete: boolean } };

export type GetUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'UserOutput', id: string, firstName: string, lastName: string, email: string, createdAt: any, updatedAt: any } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: Array<{ __typename?: 'UserOutput', id: string, firstName: string, lastName: string, email: string, createdAt: any, updatedAt: any }> };

export type GetUserFormDataQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetUserFormDataQuery = { __typename?: 'Query', getUser: { __typename?: 'UserOutput', firstName: string, lastName: string } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserOutput', id: string, firstName: string, lastName: string, email: string, createdAt: any, updatedAt: any } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserOutput', id: string, firstName: string, lastName: string, email: string, createdAt: any, updatedAt: any } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type AddAddressToUserMutationVariables = Exact<{
  relation: Scalars['String']['input'];
  record: Scalars['String']['input'];
}>;


export type AddAddressToUserMutation = { __typename?: 'Mutation', addAddressToUser: boolean };

export type AddCompanyToUserMutationVariables = Exact<{
  relation: Scalars['String']['input'];
  record: Scalars['String']['input'];
}>;


export type AddCompanyToUserMutation = { __typename?: 'Mutation', addCompanyToUser: boolean };

export type AcceptTermsForUserMutationVariables = Exact<{
  relation: Scalars['String']['input'];
  record: Scalars['String']['input'];
}>;


export type AcceptTermsForUserMutation = { __typename?: 'Mutation', acceptTermsForUser: boolean };

export type RemoveAddressFromUserMutationVariables = Exact<{
  relation: Scalars['String']['input'];
  record: Scalars['String']['input'];
}>;


export type RemoveAddressFromUserMutation = { __typename?: 'Mutation', removeAddressFromUser: boolean };

export type RemoveCompanyFromUserMutationVariables = Exact<{
  relation: Scalars['String']['input'];
  record: Scalars['String']['input'];
}>;


export type RemoveCompanyFromUserMutation = { __typename?: 'Mutation', removeCompanyFromUser: boolean };

export type UnacceptTermsForUserMutationVariables = Exact<{
  relation: Scalars['String']['input'];
  record: Scalars['String']['input'];
}>;


export type UnacceptTermsForUserMutation = { __typename?: 'Mutation', unacceptTermsForUser: boolean };

export const BaseCompanyFragmentDoc = gql`
    fragment BaseCompany on CompanyOutput {
  id
  name
  invoiceEmail
  invoiceRecipient
  createdAt
  updatedAt
}
    `;
export const CompanyFormFragmentDoc = gql`
    fragment CompanyForm on CompanyOutput {
  name
  invoiceEmail
  invoiceRecipient
}
    `;
export const RegisterCompanyFormFragmentDoc = gql`
    fragment RegisterCompanyForm on CompanyOutput {
  id
  name
  invoiceEmail
  createdAt
  deletedAt
  invoiceEmail
  invoiceRecipient
  rootUserId
  updatedAt
}
    `;
export const BaseMerchantThemeFragmentDoc = gql`
    fragment BaseMerchantTheme on MerchantThemeOutput {
  id
  createdAt
  updatedAt
  deletedAt
  companyLogo
  primaryColor
  secondaryColor
  isFormal
}
    `;
export const BaseAddressFragmentDoc = gql`
    fragment BaseAddress on AddressOutput {
  address
  addressAddition
  addressType
  city
  companyName
  createdAt
  deletedAt
  id
  postalCode
  updatedAt
}
    `;
export const BaseContactFragmentDoc = gql`
    fragment BaseContact on ContactOutput {
  id
  createdAt
  updatedAt
  deletedAt
  firstName
  middleName
  lastName
  position
  email
  phone
}
    `;
export const MinimumTosFragmentDoc = gql`
    fragment MinimumTos on TermOutput {
  id
  companyId
  title
  body
  createdAt
  updatedAt
  deletedAt
}
    `;
export const IsAuthorizedByMollieFragFragmentDoc = gql`
    fragment IsAuthorizedByMollieFrag on MollieAuthorizationStatus {
  isAuthorized
  authorizeMollieId
}
    `;
export const BaseBenefitFragmentDoc = gql`
    fragment BaseBenefit on BenefitOutput {
  id
  title
  description
  subscriptionId
  active
  createdAt
  updatedAt
  deletedAt
  order
}
    `;
export const BenefitFormFragmentDoc = gql`
    fragment BenefitForm on BenefitOutput {
  id
  title
  description
}
    `;
export const BaseAgeDiscountFragmentDoc = gql`
    fragment BaseAgeDiscount on AgeDiscountOutput {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
  amount
  amountType
  isLess
  orEqual
  age
  subscriptionId
}
    `;
export const BaseLoyaltyDiscountFragmentDoc = gql`
    fragment BaseLoyaltyDiscount on LoyaltyDiscountOutput {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
  amount
  amountType
  yearsSubscribed
  intervalYears
  maximumAmount
  maximumAmountType
  subscriptionId
}
    `;
export const BaseWelcomeDiscountFragmentDoc = gql`
    fragment BaseWelcomeDiscount on WelcomeDiscountOutput {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
  amount
  amountType
  months
  subscriptionId
}
    `;
export const BaseDiscountCodeFragmentDoc = gql`
    fragment BaseDiscountCode on DiscountCodeOutput {
  id
  createdAt
  updatedAt
  deletedAt
  useBefore
  title
  description
  discount
}
    `;
export const BaseExtraFragmentDoc = gql`
    fragment BaseExtra on ExtraOutput {
  id
  title
  description
  value
  type
  when
  active
  subscriptionId
  createdAt
  updatedAt
  deletedAt
  order
  vatPercentage
  excludeFromYearlyDiscounts
  excludeFromPromotions
  excludeFromCoupons
}
    `;
export const BaseFixedFeeFragmentDoc = gql`
    fragment BaseFixedFee on FixedFeeOutput {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
  amount
  amountType
  moment
  subscriptionId
}
    `;
export const BaseDynamicFeeFragmentDoc = gql`
    fragment BaseDynamicFee on DynamicFeeOutput {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
  clauseType
  condition
  amount
  amountType
  amountMax
  interval
  active
  disabledTill
  enabledTill
  order
  subscriptionId
  intervalAmount
}
    `;
export const BaseSubscriptionFeesFragmentDoc = gql`
    fragment BaseSubscriptionFees on SubscriptionFeeOutput {
  id
  title
  description
  type
  amount
  amountType
  intervalType
  interval
}
    `;
export const BaseSubscriptionFragmentDoc = gql`
    fragment BaseSubscription on SubscriptionOutput {
  id
  title
  description
  vatPercentage
  createdAt
  updatedAt
  deletedAt
  companyId
  allowMultiplePlansPerUser
  status
}
    `;
export const SubscriptionFormFragmentDoc = gql`
    fragment SubscriptionForm on SubscriptionOutput {
  id
  allowMultiplePlansPerUser
  title
  description
  vatPercentage
}
    `;
export const BaseTierFragmentDoc = gql`
    fragment BaseTier on TierOutput {
  id
  title
  description
  price
  extends
  subscriptionId
  createdAt
  updatedAt
  deletedAt
  order
  isHighlighted
}
    `;
export const BuyFormFragmentDoc = gql`
    fragment BuyForm on BuyStatusOutput {
  paymentLink
  status
}
    `;
export const TierFormFragmentDoc = gql`
    fragment TierForm on TierOutput {
  createdAt
  deletedAt
  description
  extends
  id
  price
  subscriptionId
  title
  updatedAt
}
    `;
export const BaseIdentityFragmentDoc = gql`
    fragment BaseIdentity on Identity {
  domain
  user {
    id
    name
    email
    latestTerms
    mfaEnabled
    mfaVerified
  }
}
    `;
export const RegisterMerchantFormFragmentDoc = gql`
    fragment RegisterMerchantForm on MerchantRegistrationOutput {
  name
  coc
  vat
  address
  extra
  city
  postalCode
}
    `;
export const UserRegistrationFormFragmentDoc = gql`
    fragment UserRegistrationForm on MerchantContactOutput {
  firstName
  middleName
  lastName
  position
  email
  phone
  address
  postalCode
  city
  addressAddition
}
    `;
export const BaseUserFragmentDoc = gql`
    fragment BaseUser on UserOutput {
  id
  firstName
  lastName
  email
  createdAt
  updatedAt
}
    `;
export const GetRegisterCompanyFormDocument = gql`
    query GetRegisterCompanyForm {
  getCompany {
    ...RegisterCompanyForm
  }
}
    ${RegisterCompanyFormFragmentDoc}`;
export const RegisterCompanyDocument = gql`
    mutation RegisterCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    ...CompanyForm
  }
}
    ${CompanyFormFragmentDoc}`;
export const GetCompanyFormDocument = gql`
    query GetCompanyForm {
  getCompany {
    ...CompanyForm
  }
}
    ${CompanyFormFragmentDoc}`;
export const GetCompanyDocument = gql`
    query GetCompany {
  getCompany {
    ...BaseCompany
  }
}
    ${BaseCompanyFragmentDoc}`;
export const GetCompaniesDocument = gql`
    query GetCompanies {
  getCompanies {
    ...BaseCompany
  }
}
    ${BaseCompanyFragmentDoc}`;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    ...CompanyForm
  }
}
    ${CompanyFormFragmentDoc}`;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($id: String!, $input: UpdateCompanyInput!) {
  updateCompany(id: $id, input: $input) {
    ...CompanyForm
  }
}
    ${CompanyFormFragmentDoc}`;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: String!) {
  deleteCompany(id: $id)
}
    `;
export const AddAddressToCompanyDocument = gql`
    mutation AddAddressToCompany($relationId: String!, $companyId: String!) {
  addAddressToCompany(relationId: $relationId, companyId: $companyId)
}
    `;
export const AddContactToCompanyDocument = gql`
    mutation AddContactToCompany($relationId: String!, $companyId: String!) {
  addContactToCompany(relationId: $relationId, companyId: $companyId)
}
    `;
export const RemoveAddressFromCompanyDocument = gql`
    mutation RemoveAddressFromCompany($relationId: String!, $companyId: String!) {
  removeAddressFromCompany(relationId: $relationId, companyId: $companyId)
}
    `;
export const RemoveContactFromCompanyDocument = gql`
    mutation RemoveContactFromCompany($relationId: String!, $companyId: String!) {
  removeContactFromCompany(relationId: $relationId, companyId: $companyId)
}
    `;
export const GetMerchantThemeDocument = gql`
    query GetMerchantTheme {
  getMerchantTheme {
    ...BaseMerchantTheme
  }
}
    ${BaseMerchantThemeFragmentDoc}`;
export const CreateMerchantThemeDocument = gql`
    mutation CreateMerchantTheme($input: CreateMerchantThemeInput!) {
  createMerchantTheme(input: $input) {
    ...BaseMerchantTheme
  }
}
    ${BaseMerchantThemeFragmentDoc}`;
export const UpdateMerchantThemeDocument = gql`
    mutation UpdateMerchantTheme($id: String!, $input: UpdateMerchantThemeInput!) {
  updateMerchantTheme(id: $id, input: $input) {
    ...BaseMerchantTheme
  }
}
    ${BaseMerchantThemeFragmentDoc}`;
export const DeleteMerchantThemeDocument = gql`
    mutation DeleteMerchantTheme($id: String!) {
  deleteMerchantTheme(id: $id)
}
    `;
export const GetAddressDocument = gql`
    query GetAddress($id: String!) {
  getAddress(id: $id) {
    ...BaseAddress
  }
}
    ${BaseAddressFragmentDoc}`;
export const GetAddressesDocument = gql`
    query GetAddresses {
  getAddresses {
    ...BaseAddress
  }
}
    ${BaseAddressFragmentDoc}`;
export const CreateAddressDocument = gql`
    mutation CreateAddress($input: CreateAddressInput!) {
  createAddress(input: $input) {
    ...BaseAddress
  }
}
    ${BaseAddressFragmentDoc}`;
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($id: String!, $input: UpdateAddressInput!) {
  updateAddress(id: $id, input: $input) {
    ...BaseAddress
  }
}
    ${BaseAddressFragmentDoc}`;
export const DeleteAddressDocument = gql`
    mutation DeleteAddress($id: String!) {
  deleteAddress(id: $id)
}
    `;
export const GetContactDocument = gql`
    query GetContact($id: String!) {
  getContact(id: $id) {
    ...BaseContact
  }
}
    ${BaseContactFragmentDoc}`;
export const GetContactsDocument = gql`
    query GetContacts {
  getContacts {
    ...BaseContact
  }
}
    ${BaseContactFragmentDoc}`;
export const CreateContactDocument = gql`
    mutation CreateContact($input: CreateContactInput!) {
  createContact(input: $input) {
    ...BaseContact
  }
}
    ${BaseContactFragmentDoc}`;
export const UpdateContactDocument = gql`
    mutation UpdateContact($id: String!, $input: UpdateContactInput!) {
  updateContact(id: $id, input: $input) {
    ...BaseContact
  }
}
    ${BaseContactFragmentDoc}`;
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: String!) {
  deleteContact(id: $id)
}
    `;
export const LatestTermsDocument = gql`
    query LatestTerms($companyId: String) {
  latestTerms(companyId: $companyId) {
    ...MinimumTos
  }
}
    ${MinimumTosFragmentDoc}`;
export const ConnectMfaQrCodeDocument = gql`
    query ConnectMfaQrCode {
  connectMfaQrCode
}
    `;
export const RegenerateMfaCodesDocument = gql`
    mutation RegenerateMfaCodes($code: String!) {
  regenerateMfaCodes(code: $code)
}
    `;
export const MfaVerifyDocument = gql`
    mutation MfaVerify($code: String!) {
  mfaVerify(code: $code)
}
    `;
export const MfaActivateDocument = gql`
    mutation MfaActivate($code: String!) {
  mfaActivate(code: $code)
}
    `;
export const MfaDeactivateDocument = gql`
    mutation MfaDeactivate($code: String!) {
  mfaDeactivate(code: $code)
}
    `;
export const IsAuthorizedByMollieDocument = gql`
    query IsAuthorizedByMollie {
  isAuthorizedByMollie {
    ...IsAuthorizedByMollieFrag
  }
}
    ${IsAuthorizedByMollieFragFragmentDoc}`;
export const GetBenefitFormDocument = gql`
    query GetBenefitForm($id: String!) {
  getBenefit(id: $id) {
    ...BenefitForm
  }
}
    ${BenefitFormFragmentDoc}`;
export const GetBenefitDocument = gql`
    query GetBenefit($id: String!) {
  getBenefit(id: $id) {
    ...BaseBenefit
  }
}
    ${BaseBenefitFragmentDoc}`;
export const GetBenefitsDocument = gql`
    query GetBenefits($subscriptionId: String!) {
  getBenefits(subscriptionId: $subscriptionId) {
    ...BaseBenefit
  }
}
    ${BaseBenefitFragmentDoc}`;
export const GetBenefitsByTierIdDocument = gql`
    query GetBenefitsByTierId($id: String!) {
  getBenefitsByTierId(id: $id) {
    id
    title
    description
    subscriptionId
    active
    createdAt
    updatedAt
    deletedAt
    order
  }
}
    `;
export const GetNestedPlanDocument = gql`
    query GetNestedPlan($subscriptionId: String!) {
  getNestedPlans(subscriptionId: $subscriptionId) {
    id
    title
    description
    price
    extends
    subscriptionId
    createdAt
    updatedAt
    deletedAt
    order
    isHighlighted
    fixedFees {
      id
      createdAt
      updatedAt
      deletedAt
      title
      description
      isDiscount
      amount
      amountType
      moment
      active
      disabledTill
      enabledTill
      order
      subscriptionId
    }
    benefits {
      id
      title
      description
      subscriptionId
      active
      createdAt
      updatedAt
      deletedAt
      order
    }
  }
}
    `;
export const CreateBenefitDocument = gql`
    mutation CreateBenefit($input: CreateBenefitInput!) {
  createBenefit(input: $input) {
    ...BenefitForm
  }
}
    ${BenefitFormFragmentDoc}`;
export const AddBenefitToTierDocument = gql`
    mutation AddBenefitToTier($benefitId: String!, $tierId: String!) {
  addBenefitToTier(relationId: $benefitId, tierId: $tierId)
}
    `;
export const RemoveBenefitForTierDocument = gql`
    mutation RemoveBenefitForTier($benefitId: String!, $tierId: String!) {
  removeBenefitFromTier(relationId: $benefitId, tierId: $tierId)
}
    `;
export const CreateBenefitForTierDocument = gql`
    mutation CreateBenefitForTier($input: CreateBenefitInput!) {
  createBenefit(input: $input) {
    ...BenefitForm
  }
}
    ${BenefitFormFragmentDoc}`;
export const UpdateBenefitDocument = gql`
    mutation UpdateBenefit($id: String!, $input: UpdateBenefitInput!) {
  updateBenefit(id: $id, input: $input) {
    ...BenefitForm
  }
}
    ${BenefitFormFragmentDoc}`;
export const DeleteBenefitDocument = gql`
    mutation DeleteBenefit($id: String!) {
  deleteBenefit(id: $id)
}
    `;
export const ReorderBenefitsDocument = gql`
    mutation ReorderBenefits($benefitId: [String!]!) {
  reorderBenefits(benefitIds: $benefitId) {
    id
    title
    description
    subscriptionId
    active
    createdAt
    updatedAt
    deletedAt
    order
  }
}
    `;
export const TogglePlanBenefitDocument = gql`
    mutation TogglePlanBenefit($planId: String!, $benefitId: String!) {
  togglePlanBenefit(planId: $planId, benefitId: $benefitId) {
    id
    title
    description
    price
    extends
    subscriptionId
    createdAt
    updatedAt
    deletedAt
    order
    isHighlighted
    fixedFees {
      id
      createdAt
      updatedAt
      deletedAt
      title
      description
      isDiscount
      amount
      amountType
      moment
      active
      disabledTill
      enabledTill
      order
      subscriptionId
    }
    benefits {
      id
      title
      description
      subscriptionId
      active
      createdAt
      updatedAt
      deletedAt
      order
    }
  }
}
    `;
export const GetSubscriptionDiscountsDocument = gql`
    query GetSubscriptionDiscounts($subscriptionId: String!) {
  getSubscriptionDiscounts(subscriptionId: $subscriptionId) {
    id
    name
    type
    summary
    constraint {
      value
      boolean
    }
  }
}
    `;
export const GetAgeDiscountsDocument = gql`
    query GetAgeDiscounts($subscriptionId: String!) {
  getAgeDiscounts(subscriptionId: $subscriptionId) {
    ...BaseAgeDiscount
  }
}
    ${BaseAgeDiscountFragmentDoc}`;
export const GetLoyaltyDiscountsDocument = gql`
    query GetLoyaltyDiscounts($subscriptionId: String!) {
  getLoyaltyDiscounts(subscriptionId: $subscriptionId) {
    ...BaseLoyaltyDiscount
  }
}
    ${BaseLoyaltyDiscountFragmentDoc}`;
export const GetWelcomeDiscountsDocument = gql`
    query GetWelcomeDiscounts($subscriptionId: String!) {
  getWelcomeDiscounts(subscriptionId: $subscriptionId) {
    ...BaseWelcomeDiscount
  }
}
    ${BaseWelcomeDiscountFragmentDoc}`;
export const CreateAgeDiscountDocument = gql`
    mutation CreateAgeDiscount($input: CreateAgeDiscountInput!) {
  createAgeDiscount(input: $input) {
    ...BaseAgeDiscount
  }
}
    ${BaseAgeDiscountFragmentDoc}`;
export const UpdateAgeDiscountDocument = gql`
    mutation UpdateAgeDiscount($id: String!, $input: UpdateAgeDiscountInput!) {
  updateAgeDiscount(id: $id, input: $input) {
    ...BaseAgeDiscount
  }
}
    ${BaseAgeDiscountFragmentDoc}`;
export const CreateLoyaltyDiscountDocument = gql`
    mutation CreateLoyaltyDiscount($input: CreateLoyaltyDiscountInput!) {
  createLoyaltyDiscount(input: $input) {
    ...BaseLoyaltyDiscount
  }
}
    ${BaseLoyaltyDiscountFragmentDoc}`;
export const UpdateLoyaltyDiscountDocument = gql`
    mutation UpdateLoyaltyDiscount($id: String!, $input: UpdateLoyaltyDiscountInput!) {
  updateLoyaltyDiscount(id: $id, input: $input) {
    ...BaseLoyaltyDiscount
  }
}
    ${BaseLoyaltyDiscountFragmentDoc}`;
export const CreateWelcomeDiscountDocument = gql`
    mutation CreateWelcomeDiscount($input: CreateWelcomeDiscountInput!) {
  createWelcomeDiscount(input: $input) {
    ...BaseWelcomeDiscount
  }
}
    ${BaseWelcomeDiscountFragmentDoc}`;
export const UpdateWelcomeDiscountDocument = gql`
    mutation UpdateWelcomeDiscount($id: String!, $input: UpdateWelcomeDiscountInput!) {
  updateWelcomeDiscount(id: $id, input: $input) {
    ...BaseWelcomeDiscount
  }
}
    ${BaseWelcomeDiscountFragmentDoc}`;
export const GetDiscountCodeDocument = gql`
    query GetDiscountCode($id: String!) {
  getDiscountCode(id: $id) {
    ...BaseDiscountCode
  }
}
    ${BaseDiscountCodeFragmentDoc}`;
export const CreateDiscountCodeDocument = gql`
    mutation CreateDiscountCode($input: CreateDiscountCodeInput!) {
  createDiscountCode(input: $input) {
    ...BaseDiscountCode
  }
}
    ${BaseDiscountCodeFragmentDoc}`;
export const UpdateDiscountCodeDocument = gql`
    mutation UpdateDiscountCode($id: String!, $input: UpdateDiscountCodeInput!) {
  updateDiscountCode(id: $id, input: $input) {
    ...BaseDiscountCode
  }
}
    ${BaseDiscountCodeFragmentDoc}`;
export const DeleteDiscountCodeDocument = gql`
    mutation DeleteDiscountCode($id: String!) {
  deleteDiscountCode(id: $id)
}
    `;
export const GetExtraDocument = gql`
    query GetExtra($id: String!) {
  getExtra(id: $id) {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const GetExtrasBySubscriptionIdDocument = gql`
    query GetExtrasBySubscriptionId($id: String!) {
  getExtrasBySubscriptionId(id: $id) {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const GetExtrasDocument = gql`
    query GetExtras($id: String!) {
  getExtras(subscriptionId: $id) {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const CreateExtraDocument = gql`
    mutation CreateExtra($input: CreateExtraInput!) {
  createExtra(input: $input) {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const AddExtraToTierDocument = gql`
    mutation AddExtraToTier($relation: String!, $tierId: String!) {
  addExtraToTier(relationId: $relation, tierId: $tierId)
}
    `;
export const UpdateExtraDocument = gql`
    mutation UpdateExtra($id: String!, $input: UpdateExtraInput!) {
  updateExtra(id: $id, input: $input) {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const DeleteExtraDocument = gql`
    mutation DeleteExtra($id: String!) {
  deleteExtra(id: $id)
}
    `;
export const ReorderExtrasDocument = gql`
    mutation ReorderExtras($extraIds: [String!]!) {
  reorderExtras(extraIds: $extraIds) {
    id
    title
    description
    value
    type
    when
    active
    subscriptionId
    createdAt
    updatedAt
    deletedAt
    order
    vatPercentage
    excludeFromYearlyDiscounts
    excludeFromPromotions
    excludeFromCoupons
  }
}
    `;
export const GetSubscriptionFeesDocument = gql`
    query GetSubscriptionFees($subscriptionId: String!) {
  getSubscriptionFees(subscriptionId: $subscriptionId) {
    ...BaseSubscriptionFees
  }
}
    ${BaseSubscriptionFeesFragmentDoc}`;
export const CreateFixedFeeDocument = gql`
    mutation CreateFixedFee($input: CreateFixedFeeInput!) {
  createFixedFee(input: $input) {
    ...BaseFixedFee
  }
}
    ${BaseFixedFeeFragmentDoc}`;
export const UpdateFixedFeeDocument = gql`
    mutation UpdateFixedFee($id: String!, $input: UpdateFixedFeeInput!) {
  updateFixedFee(id: $id, input: $input) {
    ...BaseFixedFee
  }
}
    ${BaseFixedFeeFragmentDoc}`;
export const CreateDynamicFeeDocument = gql`
    mutation CreateDynamicFee($input: CreateDynamicFeeInput!) {
  createDynamicFee(input: $input) {
    ...BaseDynamicFee
  }
}
    ${BaseDynamicFeeFragmentDoc}`;
export const UpdateDynamicFeeDocument = gql`
    mutation UpdateDynamicFee($id: String!, $input: UpdateDynamicFeeInput!) {
  updateDynamicFee(id: $id, input: $input) {
    ...BaseDynamicFee
  }
}
    ${BaseDynamicFeeFragmentDoc}`;
export const GetSubscriptionConfigStatusDocument = gql`
    query GetSubscriptionConfigStatus($id: String!) {
  getSubscriptionConfigStatus(subscriptionId: $id) {
    step
    finished
  }
}
    `;
export const GetSubscriptionFormDocument = gql`
    query GetSubscriptionForm($id: String!) {
  getSubscription(id: $id) {
    ...SubscriptionForm
  }
}
    ${SubscriptionFormFragmentDoc}`;
export const GetSubscriptionDocument = gql`
    query GetSubscription($id: String!) {
  getSubscription(id: $id) {
    ...BaseSubscription
  }
}
    ${BaseSubscriptionFragmentDoc}`;
export const GetSubscriptionsDocument = gql`
    query GetSubscriptions {
  getSubscriptions {
    ...BaseSubscription
  }
}
    ${BaseSubscriptionFragmentDoc}`;
export const GetTjeckoSubscriptionDocument = gql`
    query GetTjeckoSubscription {
  getTjeckoSubscription {
    id
    title
    description
    vatPercentage
    createdAt
    updatedAt
    deletedAt
    companyId
    intervals {
      id
      interval
      discountAmount
      discountType
      subscriptionId
      createdAt
      updatedAt
      deletedAt
    }
    tiers {
      id
      title
      description
      price
      extends
      subscriptionId
      createdAt
      updatedAt
      deletedAt
      benefits {
        id
        title
        description
        subscriptionId
        active
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
}
    `;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    ...SubscriptionForm
  }
}
    ${SubscriptionFormFragmentDoc}`;
export const UpdateSubscriptionDocument = gql`
    mutation UpdateSubscription($id: String!, $input: UpdateSubscriptionInput!) {
  updateSubscription(id: $id, input: $input) {
    ...SubscriptionForm
  }
}
    ${SubscriptionFormFragmentDoc}`;
export const DeleteSubscriptionDocument = gql`
    mutation DeleteSubscription($id: String!) {
  deleteSubscription(id: $id)
}
    `;
export const GetPlanFormDocument = gql`
    query GetPlanForm($id: String!) {
  getPlan(id: $id) {
    ...TierForm
  }
}
    ${TierFormFragmentDoc}`;
export const GetPlanDocument = gql`
    query GetPlan($id: String!) {
  getPlan(id: $id) {
    ...BaseTier
  }
}
    ${BaseTierFragmentDoc}`;
export const GetPlansDocument = gql`
    query GetPlans($subscriptionId: String!) {
  getPlans(subscriptionId: $subscriptionId) {
    id
    title
    description
    price
    extends
    subscriptionId
    createdAt
    updatedAt
    deletedAt
    order
    isHighlighted
  }
}
    `;
export const CreateTierDocument = gql`
    mutation CreateTier($input: CreateTierInput!) {
  createPlan(input: $input) {
    ...TierForm
  }
}
    ${TierFormFragmentDoc}`;
export const UpdateTierDocument = gql`
    mutation UpdateTier($id: String!, $input: UpdateTierInput!) {
  updateTier(id: $id, input: $input) {
    ...TierForm
  }
}
    ${TierFormFragmentDoc}`;
export const DeleteTierDocument = gql`
    mutation DeleteTier($id: String!) {
  deleteTier(id: $id)
}
    `;
export const BuyTierDocument = gql`
    mutation BuyTier($tierId: String!, $intervalId: String!, $discountId: String) {
  buyTier(tierId: $tierId, intervalId: $intervalId, discountCodeId: $discountId) {
    ...BuyForm
  }
}
    ${BuyFormFragmentDoc}`;
export const GetShoppingCartDocument = gql`
    query GetShoppingCart {
  getShoppingCart {
    userId
    tierId
    intervalId
    discountId
  }
}
    `;
export const HighlightPlanDocument = gql`
    mutation HighlightPlan($id: String!, $isHighlighted: Boolean!) {
  highlightPlan(planId: $id, isHighlighted: $isHighlighted) {
    title
    description
    price
    extends
    subscriptionId
    createdAt
    updatedAt
    deletedAt
    order
    isHighlighted
  }
}
    `;
export const ReorderPlansDocument = gql`
    mutation ReorderPlans($id: [String!]!) {
  reorderPlans(planIds: $id) {
    title
    description
    price
    extends
    subscriptionId
    createdAt
    updatedAt
    deletedAt
    order
    isHighlighted
  }
}
    `;
export const AddToShoppingCartDocument = gql`
    mutation AddToShoppingCart($input: CreateShoppingCartInput!) {
  addToShoppingCart(input: $input) {
    userId
    tierId
    intervalId
  }
}
    `;
export const RemoveTierFromShoppingCartDocument = gql`
    mutation RemoveTierFromShoppingCart($tierId: String!) {
  removeFromShoppingCart(tierId: $tierId)
}
    `;
export const GetIdentityDocument = gql`
    query GetIdentity {
  getIdentity {
    ...BaseIdentity
  }
}
    ${BaseIdentityFragmentDoc}`;
export const SetActiveMerchantDocument = gql`
    mutation SetActiveMerchant($id: String!) {
  setActiveMerchant(id: $id)
}
    `;
export const GetCurrentMerchantDocument = gql`
    query GetCurrentMerchant {
  getCurrentMerchant {
    id
    name
    rootUserId
    invoiceEmail
    invoiceRecipient
    createdAt
    updatedAt
    deletedAt
  }
}
    `;
export const RegisterMerchantContactDocument = gql`
    mutation RegisterMerchantContact($input: MerchantContactForm!) {
  registerMerchantContact(input: $input) {
    ...UserRegistrationForm
  }
}
    ${UserRegistrationFormFragmentDoc}`;
export const RegisterMerchantDocument = gql`
    mutation RegisterMerchant($input: MerchantRegistrationForm!) {
  registerMerchant(input: $input) {
    ...RegisterMerchantForm
  }
}
    ${RegisterMerchantFormFragmentDoc}`;
export const GetRegisterMerchantFormDocument = gql`
    query GetRegisterMerchantForm {
  getRegisterMerchantForm {
    ...RegisterMerchantForm
  }
}
    ${RegisterMerchantFormFragmentDoc}`;
export const GetMerchantProfileProgressDocument = gql`
    query GetMerchantProfileProgress {
  getMerchantProfileProgress {
    onboardingProgress {
      contactIsComplete
      addressIsComplete
      subscriptionIsComplete
      memberIsComplete
    }
    complete
    companyId
  }
}
    `;
export const GetMerchantTjeckoSubStatusDocument = gql`
    query GetMerchantTjeckoSubStatus {
  getMerchantTjeckoSubStatus {
    status
    paidLatest
    initialPaymentComplete
  }
}
    `;
export const GetUserDocument = gql`
    query GetUser($id: String!) {
  getUser(id: $id) {
    ...BaseUser
  }
}
    ${BaseUserFragmentDoc}`;
export const GetUsersDocument = gql`
    query GetUsers {
  getUsers {
    ...BaseUser
  }
}
    ${BaseUserFragmentDoc}`;
export const GetUserFormDataDocument = gql`
    query GetUserFormData($id: String!) {
  getUser(id: $id) {
    firstName
    lastName
  }
}
    `;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    ...BaseUser
  }
}
    ${BaseUserFragmentDoc}`;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $input: UpdateUserInput!) {
  updateUser(id: $id, input: $input) {
    ...BaseUser
  }
}
    ${BaseUserFragmentDoc}`;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id)
}
    `;
export const AddAddressToUserDocument = gql`
    mutation AddAddressToUser($relation: String!, $record: String!) {
  addAddressToUser(relationId: $relation, userId: $record)
}
    `;
export const AddCompanyToUserDocument = gql`
    mutation AddCompanyToUser($relation: String!, $record: String!) {
  addCompanyToUser(relationId: $relation, userId: $record)
}
    `;
export const AcceptTermsForUserDocument = gql`
    mutation AcceptTermsForUser($relation: String!, $record: String!) {
  acceptTermsForUser(relationId: $relation, userId: $record)
}
    `;
export const RemoveAddressFromUserDocument = gql`
    mutation RemoveAddressFromUser($relation: String!, $record: String!) {
  removeAddressFromUser(relationId: $relation, userId: $record)
}
    `;
export const RemoveCompanyFromUserDocument = gql`
    mutation RemoveCompanyFromUser($relation: String!, $record: String!) {
  removeCompanyFromUser(relationId: $relation, userId: $record)
}
    `;
export const UnacceptTermsForUserDocument = gql`
    mutation UnacceptTermsForUser($relation: String!, $record: String!) {
  unacceptTermsForUser(relationId: $relation, userId: $record)
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
    return {
        GetRegisterCompanyForm(variables?: GetRegisterCompanyFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRegisterCompanyFormQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetRegisterCompanyFormQuery>(GetRegisterCompanyFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRegisterCompanyForm', 'query', variables);
        },
        RegisterCompany(variables: RegisterCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RegisterCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RegisterCompanyMutation>(RegisterCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RegisterCompany', 'mutation', variables);
        },
        GetCompanyForm(variables?: GetCompanyFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCompanyFormQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetCompanyFormQuery>(GetCompanyFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCompanyForm', 'query', variables);
        },
        GetCompany(variables?: GetCompanyQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCompanyQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetCompanyQuery>(GetCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCompany', 'query', variables);
        },
        GetCompanies(variables?: GetCompaniesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCompaniesQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetCompaniesQuery>(GetCompaniesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCompanies', 'query', variables);
        },
        CreateCompany(variables: CreateCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateCompanyMutation>(CreateCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateCompany', 'mutation', variables);
        },
        UpdateCompany(variables: UpdateCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateCompanyMutation>(UpdateCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateCompany', 'mutation', variables);
        },
        DeleteCompany(variables: DeleteCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteCompanyMutation>(DeleteCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteCompany', 'mutation', variables);
        },
        AddAddressToCompany(variables: AddAddressToCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddAddressToCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddAddressToCompanyMutation>(AddAddressToCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddAddressToCompany', 'mutation', variables);
        },
        AddContactToCompany(variables: AddContactToCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddContactToCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddContactToCompanyMutation>(AddContactToCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddContactToCompany', 'mutation', variables);
        },
        RemoveAddressFromCompany(variables: RemoveAddressFromCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveAddressFromCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveAddressFromCompanyMutation>(RemoveAddressFromCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveAddressFromCompany', 'mutation', variables);
        },
        RemoveContactFromCompany(variables: RemoveContactFromCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveContactFromCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveContactFromCompanyMutation>(RemoveContactFromCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveContactFromCompany', 'mutation', variables);
        },
        GetMerchantTheme(variables?: GetMerchantThemeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMerchantThemeQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetMerchantThemeQuery>(GetMerchantThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetMerchantTheme', 'query', variables);
        },
        CreateMerchantTheme(variables: CreateMerchantThemeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateMerchantThemeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateMerchantThemeMutation>(CreateMerchantThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateMerchantTheme', 'mutation', variables);
        },
        UpdateMerchantTheme(variables: UpdateMerchantThemeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateMerchantThemeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateMerchantThemeMutation>(UpdateMerchantThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateMerchantTheme', 'mutation', variables);
        },
        DeleteMerchantTheme(variables: DeleteMerchantThemeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteMerchantThemeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteMerchantThemeMutation>(DeleteMerchantThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteMerchantTheme', 'mutation', variables);
        },
        GetAddress(variables: GetAddressQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAddressQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetAddressQuery>(GetAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAddress', 'query', variables);
        },
        GetAddresses(variables?: GetAddressesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAddressesQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetAddressesQuery>(GetAddressesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAddresses', 'query', variables);
        },
        CreateAddress(variables: CreateAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateAddressMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateAddressMutation>(CreateAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateAddress', 'mutation', variables);
        },
        UpdateAddress(variables: UpdateAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateAddressMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateAddressMutation>(UpdateAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateAddress', 'mutation', variables);
        },
        DeleteAddress(variables: DeleteAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteAddressMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteAddressMutation>(DeleteAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteAddress', 'mutation', variables);
        },
        GetContact(variables: GetContactQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetContactQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetContactQuery>(GetContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetContact', 'query', variables);
        },
        GetContacts(variables?: GetContactsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetContactsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetContactsQuery>(GetContactsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetContacts', 'query', variables);
        },
        CreateContact(variables: CreateContactMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateContactMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateContactMutation>(CreateContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateContact', 'mutation', variables);
        },
        UpdateContact(variables: UpdateContactMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateContactMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateContactMutation>(UpdateContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateContact', 'mutation', variables);
        },
        DeleteContact(variables: DeleteContactMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteContactMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteContactMutation>(DeleteContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteContact', 'mutation', variables);
        },
        LatestTerms(variables?: LatestTermsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LatestTermsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<LatestTermsQuery>(LatestTermsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LatestTerms', 'query', variables);
        },
        ConnectMfaQrCode(variables?: ConnectMfaQrCodeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ConnectMfaQrCodeQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<ConnectMfaQrCodeQuery>(ConnectMfaQrCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ConnectMfaQrCode', 'query', variables);
        },
        RegenerateMfaCodes(variables: RegenerateMfaCodesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RegenerateMfaCodesMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RegenerateMfaCodesMutation>(RegenerateMfaCodesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RegenerateMfaCodes', 'mutation', variables);
        },
        MfaVerify(variables: MfaVerifyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MfaVerifyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<MfaVerifyMutation>(MfaVerifyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MfaVerify', 'mutation', variables);
        },
        MfaActivate(variables: MfaActivateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MfaActivateMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<MfaActivateMutation>(MfaActivateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MfaActivate', 'mutation', variables);
        },
        MfaDeactivate(variables: MfaDeactivateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MfaDeactivateMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<MfaDeactivateMutation>(MfaDeactivateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MfaDeactivate', 'mutation', variables);
        },
        IsAuthorizedByMollie(variables?: IsAuthorizedByMollieQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IsAuthorizedByMollieQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<IsAuthorizedByMollieQuery>(IsAuthorizedByMollieDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'IsAuthorizedByMollie', 'query', variables);
        },
        GetBenefitForm(variables: GetBenefitFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitFormQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitFormQuery>(GetBenefitFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBenefitForm', 'query', variables);
        },
        GetBenefit(variables: GetBenefitQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitQuery>(GetBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBenefit', 'query', variables);
        },
        GetBenefits(variables: GetBenefitsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitsQuery>(GetBenefitsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBenefits', 'query', variables);
        },
        GetBenefitsByTierId(variables: GetBenefitsByTierIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitsByTierIdQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitsByTierIdQuery>(GetBenefitsByTierIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBenefitsByTierId', 'query', variables);
        },
        GetNestedPlan(variables: GetNestedPlanQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetNestedPlanQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetNestedPlanQuery>(GetNestedPlanDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetNestedPlan', 'query', variables);
        },
        CreateBenefit(variables: CreateBenefitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateBenefitMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateBenefitMutation>(CreateBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateBenefit', 'mutation', variables);
        },
        AddBenefitToTier(variables: AddBenefitToTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddBenefitToTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddBenefitToTierMutation>(AddBenefitToTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddBenefitToTier', 'mutation', variables);
        },
        RemoveBenefitForTier(variables: RemoveBenefitForTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveBenefitForTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveBenefitForTierMutation>(RemoveBenefitForTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveBenefitForTier', 'mutation', variables);
        },
        CreateBenefitForTier(variables: CreateBenefitForTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateBenefitForTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateBenefitForTierMutation>(CreateBenefitForTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateBenefitForTier', 'mutation', variables);
        },
        UpdateBenefit(variables: UpdateBenefitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateBenefitMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateBenefitMutation>(UpdateBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateBenefit', 'mutation', variables);
        },
        DeleteBenefit(variables: DeleteBenefitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteBenefitMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteBenefitMutation>(DeleteBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteBenefit', 'mutation', variables);
        },
        ReorderBenefits(variables: ReorderBenefitsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ReorderBenefitsMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<ReorderBenefitsMutation>(ReorderBenefitsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ReorderBenefits', 'mutation', variables);
        },
        TogglePlanBenefit(variables: TogglePlanBenefitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<TogglePlanBenefitMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<TogglePlanBenefitMutation>(TogglePlanBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TogglePlanBenefit', 'mutation', variables);
        },
        GetSubscriptionDiscounts(variables: GetSubscriptionDiscountsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSubscriptionDiscountsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetSubscriptionDiscountsQuery>(GetSubscriptionDiscountsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSubscriptionDiscounts', 'query', variables);
        },
        GetAgeDiscounts(variables: GetAgeDiscountsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAgeDiscountsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetAgeDiscountsQuery>(GetAgeDiscountsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAgeDiscounts', 'query', variables);
        },
        GetLoyaltyDiscounts(variables: GetLoyaltyDiscountsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetLoyaltyDiscountsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetLoyaltyDiscountsQuery>(GetLoyaltyDiscountsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetLoyaltyDiscounts', 'query', variables);
        },
        GetWelcomeDiscounts(variables: GetWelcomeDiscountsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetWelcomeDiscountsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetWelcomeDiscountsQuery>(GetWelcomeDiscountsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetWelcomeDiscounts', 'query', variables);
        },
        CreateAgeDiscount(variables: CreateAgeDiscountMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateAgeDiscountMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateAgeDiscountMutation>(CreateAgeDiscountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateAgeDiscount', 'mutation', variables);
        },
        UpdateAgeDiscount(variables: UpdateAgeDiscountMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateAgeDiscountMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateAgeDiscountMutation>(UpdateAgeDiscountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateAgeDiscount', 'mutation', variables);
        },
        CreateLoyaltyDiscount(variables: CreateLoyaltyDiscountMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateLoyaltyDiscountMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateLoyaltyDiscountMutation>(CreateLoyaltyDiscountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateLoyaltyDiscount', 'mutation', variables);
        },
        UpdateLoyaltyDiscount(variables: UpdateLoyaltyDiscountMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateLoyaltyDiscountMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateLoyaltyDiscountMutation>(UpdateLoyaltyDiscountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateLoyaltyDiscount', 'mutation', variables);
        },
        CreateWelcomeDiscount(variables: CreateWelcomeDiscountMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateWelcomeDiscountMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateWelcomeDiscountMutation>(CreateWelcomeDiscountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateWelcomeDiscount', 'mutation', variables);
        },
        UpdateWelcomeDiscount(variables: UpdateWelcomeDiscountMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateWelcomeDiscountMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateWelcomeDiscountMutation>(UpdateWelcomeDiscountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateWelcomeDiscount', 'mutation', variables);
        },
        GetDiscountCode(variables: GetDiscountCodeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetDiscountCodeQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetDiscountCodeQuery>(GetDiscountCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetDiscountCode', 'query', variables);
        },
        CreateDiscountCode(variables: CreateDiscountCodeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateDiscountCodeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateDiscountCodeMutation>(CreateDiscountCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateDiscountCode', 'mutation', variables);
        },
        UpdateDiscountCode(variables: UpdateDiscountCodeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateDiscountCodeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateDiscountCodeMutation>(UpdateDiscountCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateDiscountCode', 'mutation', variables);
        },
        DeleteDiscountCode(variables: DeleteDiscountCodeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteDiscountCodeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteDiscountCodeMutation>(DeleteDiscountCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteDiscountCode', 'mutation', variables);
        },
        GetExtra(variables: GetExtraQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetExtraQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetExtraQuery>(GetExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetExtra', 'query', variables);
        },
        GetExtrasBySubscriptionId(variables: GetExtrasBySubscriptionIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetExtrasBySubscriptionIdQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetExtrasBySubscriptionIdQuery>(GetExtrasBySubscriptionIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetExtrasBySubscriptionId', 'query', variables);
        },
        GetExtras(variables: GetExtrasQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetExtrasQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetExtrasQuery>(GetExtrasDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetExtras', 'query', variables);
        },
        CreateExtra(variables: CreateExtraMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateExtraMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateExtraMutation>(CreateExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateExtra', 'mutation', variables);
        },
        AddExtraToTier(variables: AddExtraToTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddExtraToTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddExtraToTierMutation>(AddExtraToTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddExtraToTier', 'mutation', variables);
        },
        UpdateExtra(variables: UpdateExtraMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateExtraMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateExtraMutation>(UpdateExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateExtra', 'mutation', variables);
        },
        DeleteExtra(variables: DeleteExtraMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteExtraMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteExtraMutation>(DeleteExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteExtra', 'mutation', variables);
        },
        ReorderExtras(variables: ReorderExtrasMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ReorderExtrasMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<ReorderExtrasMutation>(ReorderExtrasDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ReorderExtras', 'mutation', variables);
        },
        GetSubscriptionFees(variables: GetSubscriptionFeesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSubscriptionFeesQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetSubscriptionFeesQuery>(GetSubscriptionFeesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSubscriptionFees', 'query', variables);
        },
        CreateFixedFee(variables: CreateFixedFeeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateFixedFeeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateFixedFeeMutation>(CreateFixedFeeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateFixedFee', 'mutation', variables);
        },
        UpdateFixedFee(variables: UpdateFixedFeeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateFixedFeeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateFixedFeeMutation>(UpdateFixedFeeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateFixedFee', 'mutation', variables);
        },
        CreateDynamicFee(variables: CreateDynamicFeeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateDynamicFeeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateDynamicFeeMutation>(CreateDynamicFeeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateDynamicFee', 'mutation', variables);
        },
        UpdateDynamicFee(variables: UpdateDynamicFeeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateDynamicFeeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateDynamicFeeMutation>(UpdateDynamicFeeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateDynamicFee', 'mutation', variables);
        },
        GetSubscriptionConfigStatus(variables: GetSubscriptionConfigStatusQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSubscriptionConfigStatusQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetSubscriptionConfigStatusQuery>(GetSubscriptionConfigStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSubscriptionConfigStatus', 'query', variables);
        },
        GetSubscriptionForm(variables: GetSubscriptionFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSubscriptionFormQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetSubscriptionFormQuery>(GetSubscriptionFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSubscriptionForm', 'query', variables);
        },
        GetSubscription(variables: GetSubscriptionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSubscriptionQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetSubscriptionQuery>(GetSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSubscription', 'query', variables);
        },
        GetSubscriptions(variables?: GetSubscriptionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSubscriptionsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetSubscriptionsQuery>(GetSubscriptionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSubscriptions', 'query', variables);
        },
        GetTjeckoSubscription(variables?: GetTjeckoSubscriptionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTjeckoSubscriptionQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetTjeckoSubscriptionQuery>(GetTjeckoSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTjeckoSubscription', 'query', variables);
        },
        CreateSubscription(variables: CreateSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateSubscriptionMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateSubscriptionMutation>(CreateSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateSubscription', 'mutation', variables);
        },
        UpdateSubscription(variables: UpdateSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateSubscriptionMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateSubscriptionMutation>(UpdateSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateSubscription', 'mutation', variables);
        },
        DeleteSubscription(variables: DeleteSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteSubscriptionMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteSubscriptionMutation>(DeleteSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteSubscription', 'mutation', variables);
        },
        GetPlanForm(variables: GetPlanFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetPlanFormQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetPlanFormQuery>(GetPlanFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPlanForm', 'query', variables);
        },
        GetPlan(variables: GetPlanQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetPlanQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetPlanQuery>(GetPlanDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPlan', 'query', variables);
        },
        GetPlans(variables: GetPlansQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetPlansQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetPlansQuery>(GetPlansDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPlans', 'query', variables);
        },
        CreateTier(variables: CreateTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateTierMutation>(CreateTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateTier', 'mutation', variables);
        },
        UpdateTier(variables: UpdateTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateTierMutation>(UpdateTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTier', 'mutation', variables);
        },
        DeleteTier(variables: DeleteTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteTierMutation>(DeleteTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteTier', 'mutation', variables);
        },
        BuyTier(variables: BuyTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<BuyTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<BuyTierMutation>(BuyTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BuyTier', 'mutation', variables);
        },
        GetShoppingCart(variables?: GetShoppingCartQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetShoppingCartQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetShoppingCartQuery>(GetShoppingCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetShoppingCart', 'query', variables);
        },
        HighlightPlan(variables: HighlightPlanMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<HighlightPlanMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<HighlightPlanMutation>(HighlightPlanDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'HighlightPlan', 'mutation', variables);
        },
        ReorderPlans(variables: ReorderPlansMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ReorderPlansMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<ReorderPlansMutation>(ReorderPlansDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ReorderPlans', 'mutation', variables);
        },
        AddToShoppingCart(variables: AddToShoppingCartMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddToShoppingCartMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddToShoppingCartMutation>(AddToShoppingCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddToShoppingCart', 'mutation', variables);
        },
        RemoveTierFromShoppingCart(variables: RemoveTierFromShoppingCartMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveTierFromShoppingCartMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveTierFromShoppingCartMutation>(RemoveTierFromShoppingCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveTierFromShoppingCart', 'mutation', variables);
        },
        GetIdentity(variables?: GetIdentityQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetIdentityQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetIdentityQuery>(GetIdentityDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetIdentity', 'query', variables);
        },
        SetActiveMerchant(variables: SetActiveMerchantMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SetActiveMerchantMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<SetActiveMerchantMutation>(SetActiveMerchantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetActiveMerchant', 'mutation', variables);
        },
        GetCurrentMerchant(variables?: GetCurrentMerchantQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCurrentMerchantQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetCurrentMerchantQuery>(GetCurrentMerchantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCurrentMerchant', 'query', variables);
        },
        RegisterMerchantContact(variables: RegisterMerchantContactMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RegisterMerchantContactMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RegisterMerchantContactMutation>(RegisterMerchantContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RegisterMerchantContact', 'mutation', variables);
        },
        RegisterMerchant(variables: RegisterMerchantMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RegisterMerchantMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RegisterMerchantMutation>(RegisterMerchantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RegisterMerchant', 'mutation', variables);
        },
        GetRegisterMerchantForm(variables?: GetRegisterMerchantFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRegisterMerchantFormQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetRegisterMerchantFormQuery>(GetRegisterMerchantFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRegisterMerchantForm', 'query', variables);
        },
        GetMerchantProfileProgress(variables?: GetMerchantProfileProgressQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMerchantProfileProgressQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetMerchantProfileProgressQuery>(GetMerchantProfileProgressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetMerchantProfileProgress', 'query', variables);
        },
        GetMerchantTjeckoSubStatus(variables?: GetMerchantTjeckoSubStatusQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMerchantTjeckoSubStatusQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetMerchantTjeckoSubStatusQuery>(GetMerchantTjeckoSubStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetMerchantTjeckoSubStatus', 'query', variables);
        },
        GetUser(variables: GetUserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUserQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetUserQuery>(GetUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUser', 'query', variables);
        },
        GetUsers(variables?: GetUsersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUsersQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetUsersQuery>(GetUsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUsers', 'query', variables);
        },
        GetUserFormData(variables: GetUserFormDataQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUserFormDataQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetUserFormDataQuery>(GetUserFormDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUserFormData', 'query', variables);
        },
        CreateUser(variables: CreateUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateUserMutation>(CreateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateUser', 'mutation', variables);
        },
        UpdateUser(variables: UpdateUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserMutation>(UpdateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateUser', 'mutation', variables);
        },
        DeleteUser(variables: DeleteUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteUserMutation>(DeleteUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteUser', 'mutation', variables);
        },
        AddAddressToUser(variables: AddAddressToUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddAddressToUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddAddressToUserMutation>(AddAddressToUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddAddressToUser', 'mutation', variables);
        },
        AddCompanyToUser(variables: AddCompanyToUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddCompanyToUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddCompanyToUserMutation>(AddCompanyToUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddCompanyToUser', 'mutation', variables);
        },
        AcceptTermsForUser(variables: AcceptTermsForUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AcceptTermsForUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AcceptTermsForUserMutation>(AcceptTermsForUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AcceptTermsForUser', 'mutation', variables);
        },
        RemoveAddressFromUser(variables: RemoveAddressFromUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveAddressFromUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveAddressFromUserMutation>(RemoveAddressFromUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveAddressFromUser', 'mutation', variables);
        },
        RemoveCompanyFromUser(variables: RemoveCompanyFromUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveCompanyFromUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveCompanyFromUserMutation>(RemoveCompanyFromUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveCompanyFromUser', 'mutation', variables);
        },
        UnacceptTermsForUser(variables: UnacceptTermsForUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UnacceptTermsForUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UnacceptTermsForUserMutation>(UnacceptTermsForUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UnacceptTermsForUser', 'mutation', variables);
        }
    };
}
export type Sdk = ReturnType<typeof getSdk>;