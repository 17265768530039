import React, {FC} from 'react'
import {SubscriptionDiscountOutput} from "@src/gql-schema";
import {DiscountCard} from "@pages/merchant/subscriptions/pages/config/pages/discount/components/Discount.card";

type Props = {
    discounts: SubscriptionDiscountOutput[]
}

export const DiscountList: FC<Props> = ({discounts}) => (
    <div className={'space-y-4'}>
        {discounts.map(discount => <DiscountCard
            key={discount.id}
            id={discount.id}
            name={discount.name}
            summary={discount.summary}
        />)}
    </div>
)
