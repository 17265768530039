import React, {FC} from 'react';
import {Badge} from "@src/@/components/ui/badge";
import {Link} from "react-router-dom";
import DescriptionCard from "@components/card/DescriptionCard.component";
import {SubscriptionStatus} from "@app/types/Subscription";
import {__t} from "@config/i18n";


function subscriptionVariant(status: SubscriptionStatus) {
    switch (status) {
        case "published":
            return 'positive'
        case "draft":
            return 'neutral'
        case "scheduled":
            return 'default'
        default:
            return 'positive'
    }
}

type Props = {
    id?: string,
    title: string,
    status: SubscriptionStatus;
}

const SubscriptionCard: FC<Props> = ({
    id,
    title,
    status
}) => {

    return (
        <Link to={`${id}/config`}>
            <DescriptionCard
                title={title}
                context={<Badge
                    label={__t(`subscription.status.${status}`)}
                    variant={subscriptionVariant(status)}
                    className={'capitalize'}
                />}
            />
        </Link>
    )
}

export default SubscriptionCard
