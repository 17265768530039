import React, {FC} from "react";
import useGql from "@modules/graphql/useGql";
import LoadingSpinner from "@components/feedback/LoadingSpinner.component";
import Redirect from "@modules/routing/Redirect";
import useAsyncMemo from "@hooks/useAsyncMemo";
import ShowPageTemplate from "@templates/page/ShowPage.template";

type Props = object

const MfaIndexPage: FC<Props> = () => {

    const gql = useGql();


    const location = useAsyncMemo(async () => {
        return await gql.GetIdentity().then(res => {
            return res.getIdentity.user.mfaEnabled
                ? '/settings/manage-mfa'
                : 'scan-qr'

        })
    }, [])
    return (
        <ShowPageTemplate>
            <LoadingSpinner/>
            {location && <Redirect pathname={location}/>}
        </ShowPageTemplate>
    );
}
export default MfaIndexPage;