import React, {useEffect, useMemo, useState} from 'react';
import IndexPage from "@templates/page/IndexPage.template";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {NavbarBottomButtonWrapper} from "@components/button/NavbarBottomButton.wrapper";
import {SubscriptionPulloverCreateForm} from "@pages/merchant/subscriptions/forms/SubscriptionPullover.create.form";
import SubscriptionCard from "@pages/merchant/subscriptions/components/Subscription.card";
import {NoResourcesScreen} from "@pages/merchant/subscriptions/components/NoResources.screen";
import {SubscriptionOutput} from "@src/gql-schema";
import {useNavigate} from "react-router-dom";
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import {SubscriptionStatus, SubscriptionStatuses} from "@app/types/Subscription";
import {__t} from "@config/i18n";


const SubscriptionIndexPage = () => {

    const {GetSubscriptions} = useGql()
    const navigate = useNavigate()
    const navbarBottomCtx = useNavbarBottom()

    useEffect(() => {
        navbarBottomCtx.children[1](
            <NavbarBottomButtonWrapper>
                <SubscriptionPulloverCreateForm
                    onSuccess={(id: string) => navigate(`${id}/config`)}
                />
            </NavbarBottomButtonWrapper>
        )
    }, []);

    const [shouldRefresh, setShouldRefresh] = useState(true)
    const subscriptions: SubscriptionOutput[] = useAsyncMemo(async () => {
        if (!shouldRefresh) {
            return subscriptions
        }
        return await GetSubscriptions().then(r => r.getSubscriptions)
            .finally(() => setShouldRefresh(false))
    }, [shouldRefresh], [])


    const groupedSubscriptions = useMemo(() => {
        return SubscriptionStatuses.map(status => ({
            status: status,
            subscriptions: subscriptions.filter(sub => status === sub.status)
        })).filter(item => item.subscriptions.length > 0) as {
            status: SubscriptionStatus,
            subscriptions: SubscriptionOutput[]
        }[]
    }, [subscriptions]);


    return (
        <IndexPage>
            {!shouldRefresh && subscriptions.length == 0 && <NoResourcesScreen text={__t('subscription.no_results')}/>}
            {subscriptions.length > 0 && groupedSubscriptions.map(group => <div key={group.status}>

                <div className={'flex flex-col gap-y-4'}>
                    {group.subscriptions.map(subscription =>
                        <SubscriptionCard
                            key={subscription.id}
                            id={subscription.id}
                            title={subscription.title}
                            status={subscription.status as SubscriptionStatus}
                        />
                    )}
                </div>
            </div>)}
        </IndexPage>
    );
};

export default SubscriptionIndexPage;
