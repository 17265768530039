import React, {FC, useEffect, useState} from "react";
import {Check, ContentCopy} from "@mui/icons-material";
import SuccessMessage from "@components/feedback/Message/SuccessMessage";
import {__t} from "@config/i18n";

type Props = {
    value: string
}

const MfaCode: FC<Props> = ({value}) => {
    const [successMessage, setSuccessMessage] = useState<string>()


    const messageText = 'Copied to clipboard!';

    const copyCode = () => {
        navigator.clipboard.writeText(value).then(() => setSuccessMessage(messageText))
    }

    useEffect(() => {
        if (successMessage) {
            setTimeout(() => {
                setSuccessMessage(undefined)
            }, 3000)
        }
    }, [successMessage]);


    return <>
        <p>{__t('auth.otp.qr.scant')}</p>

        <button className={'text-xl font-bold text-primary flex items-center mx-auto my-5'} onClick={copyCode}>
            <span className={'text-ellipsis overflow-hidden inline-block max-w-[75vw] mr-2'}>{value}</span>
            {successMessage
                ? <Check fontWeight={900} fontSize={'medium'}/>
                : <ContentCopy fontWeight={900} fontSize={'medium'}/>
            }
        </button>

        <SuccessMessage message={successMessage}/>
    </>

}
export default MfaCode