import React, {useMemo} from 'react'
import useParamId from "@hooks/useParamId";
import useAsyncMemo from "@hooks/useAsyncMemo";
import useGql from "@modules/graphql/useGql";
import {__t} from "@config/i18n";
import {SubscriptionConfigCard} from "@pages/merchant/subscriptions/pages/config/components/SubscriptionConfigCard";
import ShowPage from "@templates/page/ShowPage.template";
import {NavRouteItem} from "@components/navigation/NavbarTop/NavbarTop.context";
import {
    OptionalSubscriptionConfigSteps,
    RequiredSubscriptionConfigSteps,
    SubscriptionConfigStep
} from "@app/types/Subscription";


export function SubscriptionConfigIndexPage() {

    const subscriptionId = useParamId('subscriptionId');
    const gql = useGql();

    /**
     *
     */
    const subscriptionName = useAsyncMemo(async () => {
        return await gql.GetSubscription({id: subscriptionId})
            .then(r => r.getSubscription.title)
    }, [subscriptionId], 'config')

    /**
     *
     */
    const subscriptionConfigStatus = useAsyncMemo(async () => {
        return await gql.GetSubscriptionConfigStatus({id: subscriptionId})
            .then(r => r.getSubscriptionConfigStatus)
    }, [subscriptionId], [])

    /**
     *
     */
    const requiredConfigSteps = useMemo(() => {
        return subscriptionConfigStatus
            .filter(item => RequiredSubscriptionConfigSteps.includes(item.step as SubscriptionConfigStep))
    }, [subscriptionConfigStatus]);

    /**
     *
     */
    const optionalConfigSteps = useMemo(() => {
        return subscriptionConfigStatus
            .filter(item => OptionalSubscriptionConfigSteps.includes(item.step as SubscriptionConfigStep))
    }, [subscriptionConfigStatus]);

    /**
     *
     */
    const canProceed = useMemo(() => {
        for (const requiredConfigStep of requiredConfigSteps) {
            if (!requiredConfigStep.finished) {
                return false
            }
        }
        return true
    }, [requiredConfigSteps]);

    /**
     *
     */
    const nextButton: NavRouteItem | undefined = useMemo(() => {
        if (!canProceed) {
            return undefined;
        }
        return {
            path: '/billing',
            title: __t('ui.next')
        };
    }, [canProceed]);


    return (
        <ShowPage
            pageTitle={subscriptionName}
            back={{
                path: '/subscriptions',
                title: __t('subscription.title.plural')
            }}
            next={nextButton}
        >
            <div className={'h-px'}></div>
            {requiredConfigSteps.map((item, i) => <SubscriptionConfigCard
                key={i}
                title={__t(`subscription.config.steps.${item.step as SubscriptionConfigStep}`)}
                to={item.step}
                optional={false}
                done={item.finished}
            />)}

            <div className={'font-semibold text-center pt-4'}>{__t('subscription.config.advanced')}</div>
            {optionalConfigSteps.map((item, i) => <SubscriptionConfigCard
                key={i}
                title={__t(`subscription.config.steps.${item.step as SubscriptionConfigStep}`)}
                to={item.step}
                optional={true}
                done={item.finished}
            />)}
        </ShowPage>
    )
}