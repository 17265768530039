import React, {FC, useMemo, useState} from 'react'
import {FormFields} from "@components/form/fields/FormField.component";
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import {CreateExtraInput} from "@src/gql-schema";
import {IncludingVatSelectOptions, VatRateSelectOptions} from "@app/types/Vat";
import useGql from "@modules/graphql/useGql";
import {__t} from "@config/i18n";

// @todo remove 'type'|'when'|'active'
// @todo rename 'value' to 'price'
type FormInput = Omit<CreateExtraInput, 'subscriptionId' | 'type' | 'when' | 'active'> & { includingVat: number }
type MutationInput = { input: FormInput }

type Props = {
    subscriptionId: string,
    onSuccess: (extraId: string) => void | Promise<void>
}

export const ExtraPulloverCreateForm: FC<Props> = ({subscriptionId, onSuccess}) => {

    const gql = useGql()
    const [show, setShow] = useState(false);


    const fields = useMemo((): FormFields<FormInput> => {
        return {
            title: {
                zodType: z.string().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.title')
            },
            value: {
                zodType: z.coerce.number().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.price'),
                colSpan: 'col-span-3',
            },
            includingVat: {
                zodType: z.coerce.number(),
                fieldType: 'select',
                options: IncludingVatSelectOptions(),
                label: __t('form.field.includingVat'),
                colSpan: 'col-span-4'
            },
            vatPercentage: {
                zodType: z.coerce.number(),
                fieldType: 'select',
                options: VatRateSelectOptions,
                label: __t('form.field.vatRate'),
                defaultValue: `${VatRateSelectOptions[0].value}`,
                colSpan: 'col-span-5',
            },
            excludeFromYearlyDiscounts: {
                zodType: z.coerce.boolean(),
                fieldType: 'checkbox',
                label: __t('form.field.excludeFromYearlyDiscounts'),
            },
            excludeFromPromotions: {
                zodType: z.coerce.boolean(),
                fieldType: 'checkbox',
                label: __t('form.field.excludeFromPromotions'),
            },
            excludeFromCoupons: {
                zodType: z.coerce.boolean(),
                fieldType: 'checkbox',
                label: __t('form.field.excludeFromCoupons'),
            },
            description: {
                zodType: z.string(),
                fieldType: 'textarea',
                label: __t('form.field.description'),
            }
        }
    }, []);


    return (
        <PullOverForm
            title={__t('extra.new')}
            form={{
                submit: async ({input}: MutationInput) => {

                    const vatPercentage = parseFloat(`${input.vatPercentage}`)
                    let price = parseFloat(`${input.value}`.replace(',', '.'))

                    if (input.includingVat) {
                        const vatFactor = 100 + vatPercentage
                        price = (price / vatFactor) * 100
                    }

                    return await gql.CreateExtra({
                        input: {
                            title: input.title,
                            description: input.description,
                            excludeFromYearlyDiscounts: input.excludeFromYearlyDiscounts == true,
                            excludeFromCoupons: input.excludeFromCoupons == true,
                            excludeFromPromotions: input.excludeFromPromotions == true,
                            vatPercentage,
                            subscriptionId,
                            // @todo rename to price
                            value: price,
                            // @todo remove following values, obsolete
                            type: 'price',
                            when: 'monthly',
                            active: true
                        }
                    })
                },
                onSubmitSuccess: (mutation) => {
                    if (mutation != null) {
                        onSuccess(mutation.createExtra.id)
                    }
                },
                fields,
                payloadInjection: {},
            }}
            submit={{title: __t('form.action.save')}}
            onClose={() => setShow(false)}
            openState={[show, setShow]}
            trigger={{title: __t('extra.new')}}
        />
    )
}