import React, {useImperativeHandle, useRef, useState} from 'react'
import PullOver from "@components/pullover/PullOver.component";
import {CompanyUser} from "@app/mock/MockCompanyData";
import {z} from "zod";
import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {Form, FormControl, FormField, FormItem, FormLabel} from '@src/@/components/ui/form';
import {Input} from "@src/@/components/ui/input";
import {Checkbox} from "@src/@/components/ui/checkbox";
import {Label} from "@src/@/components/ui/label";


const roles = ['Admin', 'Financial Manager', 'Subscription Manager', 'Subscriber Manager', 'User Manager']


const formSchema = z.object({
    name: z.string().min(1, `Name can't be empty`),
    role: z.array(z.string()),
    email: z.string().email(`Invalid email address`).min(1, `Email can't be empty`),
    mobileNo: z.string().min(1, `Mobile number can't be empty`)
});

export type UserFormSchema = z.infer<typeof formSchema>


interface AddCompanyUserPulloverProps {
    addUser: (user: CompanyUser) => void
}

export type FormRef = {
    submit: () => void;
};

export function CompanyUserPulloverCreateForm({addUser}: AddCompanyUserPulloverProps) {
    const [showAddCompany, setShowAddCompany] = useState(false);
    const formRef = useRef<FormRef>(null);

    const handleSubmit = () => {
        formRef.current?.submit();
        setShowAddCompany(false);
    };


    const form = useForm<UserFormSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: '',
            email: '',
            role: [],
            mobileNo: ''
        }
    });

    const handleRoleChange = (role: string, isChecked: boolean | 'indeterminate') => {
        const currentRoles = form.getValues('role');
        let updatedRoles: string[];

        if (role === 'Admin') {
            updatedRoles = isChecked ? ['Admin'] : [];
        } else {
            updatedRoles = isChecked
                ? [...currentRoles.filter(r => r !== 'Admin'), role]
                : currentRoles.filter(r => r !== role);
        }

        form.setValue('role', updatedRoles);
    };

    const onSubmit = (values: UserFormSchema) => {
        const newUser: CompanyUser = {
            id: String(new Date().getTime()), // For random id number only
            ...values
        };
        addUser(newUser);
        form.reset();
    };

    useImperativeHandle(formRef, () => ({
        submit: () => form.handleSubmit(onSubmit)()
    }));

    return (
        <PullOver
            variations={'max-height'}
            openState={[showAddCompany, setShowAddCompany]}
            title={'Add User'}
            submit={
                {
                    title: 'Save',
                    onClick: () => handleSubmit()
                }
            }
            trigger={
                {
                    title: 'Add User',
                    onClick: () => setShowAddCompany(true)
                }
            }
            cancel={
                {
                    title: 'Cancel'
                }
            }
        >
            <Form {...form}>
                <form className={'text-neutral-900 divide-y'}>
                    <div className={'space-y-4 pb-6'}>
                        <p className={'font-roboto text-lg font-semibold text-primary'}>User Information</p>
                        <FormField name={'name'} render={({field}) => (
                            <FormItem>
                                <FormLabel>Name</FormLabel>
                                <FormControl>
                                    <Input placeholder={'John Doe'} {...field} className={'capitalize'}/>
                                </FormControl>
                            </FormItem>
                        )}/>
                        <FormField name={'email'} render={({field}) => (
                            <FormItem>
                                <FormLabel>Email</FormLabel>
                                <FormControl>
                                    <Input placeholder={'johndoe@gmail.com'} {...field} />
                                </FormControl>
                            </FormItem>
                        )}/>
                        <FormField name={'mobileNo'} render={({field}) => (
                            <FormItem>
                                <FormLabel>Mobile No.</FormLabel>
                                <FormControl>
                                    <Input placeholder={'1 234 567 890'} {...field} />
                                </FormControl>
                            </FormItem>
                        )}/>
                    </div>
                    <FormItem className={'pt-6 space-y-4'}>
                        <FormLabel className={'font-roboto text-lg font-semibold text-primary'}>Roles</FormLabel>
                        <div className={'flex flex-col gap-y-3'}>
                            {roles.map(role => (
                                <FormControl key={role}>
                                    <Controller
                                        name='role'
                                        control={form.control}
                                        render={() => (
                                            <div className={'flex'}>
                                                <Checkbox
                                                    id={role}
                                                    checked={form.watch('role').includes(role)}
                                                    onCheckedChange={(isChecked) => handleRoleChange(role, isChecked)}
                                                />
                                                <Label htmlFor={role}
                                                    className={'text-base ml-2 font-semibold hover:cursor-pointer'}>{role}</Label>
                                            </div>
                                        )}
                                    />
                                </FormControl>
                            ))}
                        </div>
                    </FormItem>
                </form>
            </Form>
        </PullOver>
    )
}