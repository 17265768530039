import React, {FC, useMemo, useState} from 'react'
import PullOverForm from "@components/pullover/PullOverForm.component";
import {FormFields} from "@components/form/fields/FormField.component";
import {z} from "zod";
import {VatRateSelectOptions} from "@app/types/Vat";
import useGql from "@modules/graphql/useGql";
import {CreateSubscriptionInput} from "@src/gql-schema";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {__t} from "@config/i18n";


type FormInput = Omit<CreateSubscriptionInput, 'companyId' | 'status'>;
type MutationInput = { input: FormInput }

type Props = {
    onSuccess: (id: string) => void | Promise<void>
}

export const SubscriptionPulloverCreateForm: FC<Props> = ({onSuccess}) => {

    const gql = useGql()
    const [show, setShow] = useState(false);

    const companyId = useAsyncMemo(async () => {
        return await gql.GetCurrentMerchant({}).then(r => r.getCurrentMerchant.id)
    }, [], '')


    const fields = useMemo((): FormFields<FormInput> => {
        return {
            title: {
                zodType: z.string().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.title')
            },
            vatPercentage: {
                zodType: z.coerce.number().min(0, __t('form.error.required')),
                fieldType: 'select',
                label: __t('form.field.vatRate'),
                options: VatRateSelectOptions,
                defaultValue: `${VatRateSelectOptions[0].value}`,
            },
            allowMultiplePlansPerUser: {
                zodType: z.coerce.boolean(),
                fieldType: 'checkbox',
                label: __t('form.field.allowMultiplePlansPerUsers'),
                defaultValue: false
            },
            description: {
                zodType: z.string(),
                fieldType: 'textarea',
                label: __t('form.field.description')
            }
        }
    }, []);


    return (
        <PullOverForm
            title={__t('subscription.new')}
            form={{
                submit: async ({input}: MutationInput) => (
                    await gql.CreateSubscription({
                        input: {
                            ...input,
                            companyId,
                            status: 'draft'
                        }
                    })
                ),
                onSubmitSuccess: (mutation) => {
                    if (mutation != null) {
                        onSuccess(mutation.createSubscription.id)
                    }
                },
                fields,
                payloadInjection: {
                    companyId: companyId,
                },
            }}
            submit={{title: __t('form.action.save')}}
            onClose={() => setShow(false)}
            openState={[show, setShow]}
            trigger={{title: __t('subscription.new')}}
        />
    )
}