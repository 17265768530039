import React from 'react';
import IndexPage from "@templates/page/IndexPage.template";
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from "@src/@/components/ui/accordion";
import {Link, useNavigate} from "react-router-dom";


type SettingsItem = {
    title: string,
    to: string
}
type SettingsCategory = {
    title: string,
    to?: string,
    children?: SettingsItem[]
}

const settings: SettingsCategory[] = [
    {
        title: 'Personal Settings',
        children: [
            {
                title: 'Setup MFA',
                to: 'setup-mfa'
            }
        ]
    },
    {
        title: 'Company',
        children: [
            {
                title: 'Company Information',
                to: 'company'
            },
            {
                title: 'Manage Users',
                to: 'manage-users'
            }
        ]
    },
    {
        title: 'Billing',
        to: 'billing',
        children: []
    },
    {
        title: 'Legal',
        children: [
            {
                title: 'ToS',
                to: 'tos'
            },
            {
                title: 'ToS Editor',
                to: 'tos-editor'
            }
        ]
    },

]

const SettingsIndexPage = () => {

    const navigate = useNavigate();
    const handleTriggerClick = (item: SettingsCategory) => {
        if ((item.children?.length ?? 0) > 0) {
            return;
        }
        if (item.to !== undefined) {
            navigate(item.to)
        }
    }
    return (
        <IndexPage>
            <Accordion type="single" collapsible className='w-full text-neutral-900'>
                {settings.map((item, index) => <AccordionItem
                    key={index}
                    value={item.title}
                >
                    <AccordionTrigger
                        onClick={() => handleTriggerClick(item)}
                        className={'hover:no-underline text-lg'}
                        icon={item.to == undefined && item.children !== undefined}
                    >
                        {item.title}
                    </AccordionTrigger>

                    {(item.children ?? []).map(child => <AccordionContent
                        key={child.title}
                        className={'flex items-center justify-between px-4 font-medium text-sm'}
                    >
                        <Link to={child.to}>{child.title}</Link>
                    </AccordionContent>)}

                </AccordionItem>)}
            </Accordion>
        </IndexPage>
    );
};

export default SettingsIndexPage;
