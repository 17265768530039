import React, {FC, useMemo} from 'react'
import {FormFields} from "@components/form/fields/FormField.component";
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import {AmountType, AmountTypeSelectOptions} from "@app/types/Amount";
import {IncludingVatSelectOptions} from "@app/types/Vat";
import {
    DiscountPulloverFormProps
} from "@pages/merchant/subscriptions/pages/config/pages/discount/types/Discount.types";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {CreateWelcomeDiscountInput} from "@src/gql-schema";
import {__t} from "@config/i18n";


type FormInput = Omit<CreateWelcomeDiscountInput, 'subscriptionId'> & {
    includingVat: number
}

type MutationInput = { input: FormInput }

export const WelcomeDiscountPulloverCreateForm: FC<DiscountPulloverFormProps> = ({
    subscriptionId,
    pulloverState,
    onSuccess
}) => {
    const gql = useGql()

    const setShowPullover = useMemo(() => {
        return pulloverState[1]
    }, [pulloverState[0]]);

    const subscription = useAsyncMemo(async () => {
        return await gql.GetSubscription({id: subscriptionId}).then(r => r.getSubscription)
    }, [])


    const vat = useMemo(() => {
        return subscription?.vatPercentage ?? 0;
    }, [subscription])


    const includingVatOptions = useMemo(() => {
        return IncludingVatSelectOptions(vat)
    }, [vat]);

    const fields = useMemo((): FormFields<FormInput> => {
        return {
            title: {
                zodType: z.string().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.title'),
                description: __t('discount.discount_conditions')
            },
            // Discount
            amount: {
                zodType: z.coerce.number().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.discountAmount'),
                colSpan: 'col-span-3',
            },
            amountType: {
                zodType: z.string(),
                fieldType: 'select',
                label: __t('form.field.discountAmountType'),
                options: AmountTypeSelectOptions,
                defaultValue: AmountTypeSelectOptions[0].value,
                colSpan: 'col-span-4'
            },
            includingVat: {
                zodType: z.coerce.number(),
                fieldType: 'select',
                options: includingVatOptions,
                defaultValue: `${includingVatOptions[0].value}`,
                label: ' ',
                colSpan: 'col-span-5'
            },
            months: {
                zodType: z.coerce.number().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.welcomeDiscountMonths'),
            },
            description: {
                zodType: z.string(),
                fieldType: 'textarea',
                label: __t('form.field.description'),
            }
        }
    }, [includingVatOptions])


    return <PullOverForm
        title={__t('discount.welcome_discount_title')}
        form={{
            submit: async ({input}: MutationInput) => {
                let amount = parseFloat(`${input.amount}`.replace(',', '.'))
                if (input.includingVat && (input.amountType as AmountType) == 'price') {
                    const vatFactor = 100 + vat
                    amount = (amount / vatFactor) * 100
                }

                return await gql.CreateWelcomeDiscount({
                    input: {
                        title: input.title,
                        description: input.description,
                        amount,
                        subscriptionId,
                        months: parseInt(`${input.months}`),
                        amountType: input.amountType
                    }
                })
            },
            onSubmitSuccess: (mutation) => {
                if (mutation != null) {
                    onSuccess(mutation.createWelcomeDiscount.id)
                }
            },
            fields,
            payloadInjection: {},
        }}
        submit={{title: __t('form.action.save')}}
        onClose={() => setShowPullover(false)}
        openState={pulloverState}
    />

}