import React from 'react'

type SubscriptionDiscountCardProps = {
    id: string
    name: string
    summary: string
}

export function DiscountCard({id, name, summary}: SubscriptionDiscountCardProps) {
    return (
        <div key={id} className={'p-4 text-neutral-900 bg-white drop-shadow rounded-md' +
            ' space-y-3'}>
            <div className={'text-primary font-semibold font-roboto'}>{name}</div>
            <div className={'font-medium text-sm'}>{summary}</div>
        </div>
    )
}