import React from 'react'
import {Button} from "@src/@/components/ui/button";
import {Share} from "lucide-react";
import {CloseRounded} from "@mui/icons-material";

interface CreatePaymentLinkSuccessProps {
    handleShare: () => void
    handleClose: () => void
}

export function PaymentlinkShare({
    handleShare,
    handleClose
}: CreatePaymentLinkSuccessProps) {

    return (
        <div className={'flex flex-col justify-center gap-y-4 mt-40'}>
            <div className={'flex flex-col justify-center gap-y-2'}>
                <p className={'text-5xl font-semibold text-positive-800 text-center'}>Success</p>
                <p className={'text-xl font-semibold text-center'}>Payment Link Created</p>
            </div>
            <div className={'space-y-4'}>
                <Button onClick={handleShare} className={'flex items-center gap-x-1 font-medium'}><Share
                    size={18}/> Share</Button>
                <Button variant={'outline'} className={'w-full flex items-center gap-x-1 font-medium text-primary-600'}
                    onClick={handleClose}><CloseRounded/> Exit</Button>
            </div>
        </div>
    )
}