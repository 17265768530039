import React, {createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState} from "react";

type NavbarBottomContextType = {
    hide: [boolean, Dispatch<SetStateAction<boolean>>]
    height: [number, Dispatch<SetStateAction<number>>]
    children: [ReactNode | undefined, Dispatch<SetStateAction<ReactNode | undefined>>]
}

const NavbarBottomDefaultContext: NavbarBottomContextType = {
    hide: [false, () => undefined],
    height: [0, () => null],
    children: [undefined, () => undefined],
}

const NavbarBottomContext = createContext<NavbarBottomContextType>(NavbarBottomDefaultContext);


export const useNavbarBottom = () => useContext(NavbarBottomContext);
export const NavbarBottomProvider: FC<{ children?: ReactNode | undefined }> = props => {

    const hide = useState(true)
    const height = useState<number>(0)
    const children = useState<ReactNode>()

    return (
        <NavbarBottomContext.Provider value={{
            hide,
            height,
            children
        }}>
            {props.children}
        </NavbarBottomContext.Provider>
    );
};
