import React, {Dispatch, SetStateAction} from 'react'
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {BenefitOutput, NestedTierOutput} from "@src/gql-schema";
import {TabsContent} from "@src/@/components/ui/tabs";
import {BenefitCard} from "@pages/merchant/subscriptions/pages/config/pages/benefit/components/Benefit.card";
import {BenefitTabKey} from "@pages/merchant/subscriptions/pages/config/pages/benefit/types/Benefit.types";

type SubscriptionPlanCardListProps = {
    benefits: BenefitOutput[]
    setBenefits: Dispatch<SetStateAction<BenefitOutput[]>>
    setPlans: Dispatch<SetStateAction<NestedTierOutput[]>>
}

export function BenefitList({benefits, setBenefits, setPlans}: SubscriptionPlanCardListProps) {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    )
    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event;

        if (active.id !== over?.id) {
            setBenefits((items) => {
                const oldIndex = items.findIndex(
                    (item) => item.id === active.id,
                );
                const newIndex = items.findIndex((item) => item.id === over?.id);

                // Reorder the items using arrayMove
                const newItems = arrayMove(items, oldIndex, newIndex);

                // Updating setPlans FE
                setPlans((prevPlans) => {
                    return prevPlans.map((plan) => {

                        const updatedBenefits = newItems.map((item, index) => {

                            const originalBenefit = plan.benefits.find((benefit) => benefit.id === item.id);

                            return {
                                ...item,
                                active: originalBenefit ? originalBenefit.active : item.active, // Preserve the active property from prevPlans
                                order: index
                            }
                        })

                        return {
                            ...plan,
                            benefits: updatedBenefits,
                        }
                    })
                })


                // Update the order property (For Benefits state)
                return newItems.map((item, index) => ({
                    ...item,
                    order: index,
                }));

            })
        }
    }

    return (
        <TabsContent value={'manage-benefits' as BenefitTabKey}>
            <div className={'space-y-3 flex flex-col'}>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToVerticalAxis]}
                >
                    <SortableContext
                        items={benefits.map((benefit) => benefit.id) ?? []}
                        strategy={verticalListSortingStrategy}
                    >
                        {benefits.map((benefit) => (
                            <BenefitCard key={benefit.id} id={benefit.id} title={benefit.title}/>
                        ))}
                    </SortableContext>
                </DndContext>
            </div>
        </TabsContent>
    )
}