import React, {useState} from 'react';
import ShowPage from "@templates/page/ShowPage.template";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";
import useParamId from "@hooks/useParamId";
import HeaderCard from "@components/card/HeaderCard.component";
import {EditOutlined} from "@mui/icons-material";
import SubscriptionUpdateForm from "@pages/merchant/subscriptions/_legacy/show/components/SubscriptionUpdate.form";
import HighlightCard from "@components/card/HighlightCard.component";
import TabsComponent from "@components/tab/Tabs.component";
import TierTab from "@pages/merchant/subscriptions/_legacy/show/components/tiers/TierTab";
import ExtraTab from "@pages/merchant/subscriptions/_legacy/show/components/extras/ExtraTab";

const SubscriptionShowPage = () => {

    const gql = useGql();
    const id = useParamId()

    const [openEdit, setOpenEdit] = useState(false)
    const [updateSubscription, setUpdateSubscription] = useState(0)

    const subscription = useAsyncMemo(async () => {
        return await gql.GetSubscription({id}).then(r => r.getSubscription)
    }, [updateSubscription])

    return (<>

        <ShowPage pageTitle={subscription?.title}>

            <HeaderCard
                title={subscription?.title ?? 'Loading'}
                description={subscription?.description}
                action={{
                    Icon: EditOutlined,
                    onClick: () => setOpenEdit(true)
                }}
            />

            <HighlightCard
                title={'Top Performing Tier'}
                subject={'Basic'}
            />

            <TabsComponent className={'h-[calc(100vh-17.7588rem)] !-mb-shell'} tabs={[
                {
                    trigger: 'Tiers',
                    content: <TierTab subscriptionId={id}/>
                },
                {
                    trigger: 'Extras',
                    content: <ExtraTab subscriptionId={id}/>
                }
            ]}/>

        </ShowPage>

        <SubscriptionUpdateForm
            id={id}
            openState={[openEdit, setOpenEdit]}
            onSubmitSuccess={() => setUpdateSubscription(updateSubscription + 1)}
        />
    </>);
};

export default SubscriptionShowPage;
