import {I18nDictionary} from "@config/i18n";


export default {
    "amount": {
        "price": "Euro",
        "percentage": "Percentage",
        "percent": "Procent"
    },
    "auth": {
        "login": "Inloggen",
        "logout": "Uitloggen",
        "authorise": "Login valideren",
        "otp": {
            "title": "OTP code",
            "text": {
                "verify": "Vul uw authenticator code in.",
                "incorrect": "De verificatie code is incorrect!",
                "block": "Uw authenticator is geblokkeerd.",
                "enter": "Vul de verification Code in",
            },
            "qr": {
                "failure": "Niet ingelogd of incorrect account.",
                "scan": "Scan de QR-code hieronder met een ondersteunde authenticator-app. (Google Authenticator & Microsoft Authenticator)",
                "scant": "Kan je de QR-code niet scannen? Voer in plaats daarvan deze code in je authenticator-app in:",
            }
        }
    },
    "benefit": {
        "title": {
            "singular": "Voordeel",
            "plural": "Voordelen"
        },
        "no_results": "Nog geen voordelen",
        "new": "Nieuwe voordeel",
        "manage_benefits": "Voordelen beheren",
        "add_to_plans": "Toevoegen aan plannen"
    },
    "discount": {
        "title": {
            "singular": "Korting",
            "plural": "Kortingen"
        },
        "no_results": "Nog geen kortingen",
        "new": "Nieuwe korting",
        "type": {
            "age": "Leeftijd",
            "loyalty": "Loyaliteit",
            "welcome": "Welkom"
        },
        "choose_type": "Selecteer korting",
        "discount_conditions": "Korting & Voorwaarden",
        "age_discount_title": "Nieuwe leeftijdskorting",
        "loyalty_discount_title": "Nieuwe loyaliteitskorting",
        "welcome_discount_title": "Nieuwe welkomstkorting",
        "age": {
            "younger": "Jonger",
            "older": "Ouder"
        },
        "equal_to": {
            "than": "dan",
            "than_or_equal_to": "dan of gelijk aan"
        }
    },
    "fee": {
        "title": {
            "singular": "Toeslag",
            "plural": "Toeslagen"
        },
        "no_results": "Nog geen toeslagen",
        "new": "Nieuwe toeslag",
        "type": {
            "fixed": "Vast",
            "interval": "Interval",
        },
        "choose_type": "Selecteer toeslag",
        "fixed_fee_title": "Nieuwe vaste toeslag",
        "interval_fee_title": "Nieuwe interval toeslag",
        "fee_conditions": "Toeslag & Voorwaarden"
    },
    "extra": {
        "title": {
            "singular": "Extra",
            "plural": "Extra's"
        },
        "no_results": "Nog geen extra's",
        "new": "Nieuwe extra toevoegen"
    },
    "form": {
        "error": {
            "required": "Verplicht"
        },
        "field": {
            "allowMultiplePlansPerUsers": "Gebruiker kan meerdere plannen selecteren",
            "description": "Omschrijving",
            "title": "Titel",
            "vatRate": "BTW percentage",
            "price": "Prijs",
            "includingVat": "BTW",
            "excludeFromYearlyDiscounts": "Uitgezonderd van jaarlijkse kortingen",
            "excludeFromCoupons": "Uitgezonderd van kortingscodes",
            "excludeFromPromotions": "Uitgezonderd van promoties",
            "isLess": "Wanneer",
            "orEqual": " ",
            "age": "Jaar",
            "discountAmount": "Korting",
            "discountAmountType": " ",
            "yearsSubscribed": "Wanneer geabonneerd voor jaar",
            "intervalYears": "Herhaal elke jaar",
            "loyaltyDiscountMaximumAmount": "Maximale",
            "loyaltyDiscountMaximumAmountType": "korting",
            "welcomeDiscountMonths": "Voor aantal maanden",
            "feeAmount": "Toeslag",
            "feeAmountType": " ",
            "fixedFeeMoment": "Op het volgende moment",
            "intervalFeeIntervalAmount": "Elke",
        },
        "action": {
            "save": "Opslaan"
        }
    },
    "invoice": {
        "title": {
            "singular": "Factuur",
            "plural": "Facturen"
        },
        "new": "Nieuwe factuur",
        "no_results": "Geen facturen beschikbaar",
        "moment": {
            "first_invoice": "Begin van abonnement",
            "last_invoice": "Einde van abonnement"
        },
        "interval": {
            "months": {
                "plural": "Maanden",
                "singular": "Maand"
            },
            "years": {
                "plural": "Jaren",
                "singular": "Jaar"
            },
        }
    },
    "onboarding": {
        "title": "Onboarding"
    },
    "payment": {
        "title": {
            "singular": "Transactie",
            "plural": "Transacties"
        },
        "new": "Nieuwe transactie",
        "no_results": "Geen transacties beschikbaar",
    },
    "plan": {
        "title": {
            "singular": "Plan",
            "plural": "Plannen"
        },
        "new": "Nieuw plan",
        "no_results": "Nog geen plannen",
        "highlighted": "Uitgelicht"
    },
    "registration": {
        "create_account": {
            "title": "Nieuw account"
        },
        "register_company": {
            "title": "Registreer bedrijf"
        },
        "choose_plan": {
            "title": "Kies een plan"
        },
        "checkout": {
            "title": "Afrekenen",
            "status": {
                "success": {
                    "title": "Betaling geslaagd"
                }
            }
        },
        "payment_status": {
            "title": "Transactie status"
        }
    },
    "settings": {
        "title": {
            "singular": "Instelling",
            "plural": "Instellingen"
        },
        "billing": {
            "title": "Facturatie"
        },
        "company": {
            "title": "Bedrijfsinstellingen"
        },
        "mfa": {
            "title": "MFA beheren",
            "setup": {
                "title": "MFA instellen",
                "scan": {
                    "title": "Scan voor koppeling",
                    "verify": {
                        "title": "Valideer MFA",
                        "verify": {
                            "success": {
                                "title": "MFA ingeschakeld"
                            },
                            "failed": {
                                "title": "Koppelen mislukt"
                            }
                        }
                    },
                    "deactivate": {
                        "title": "MFA uitschakelen",
                        "success": {
                            "title": "MFA uitgeschakeld"
                        },
                        "failed": {
                            "title": "MFA uitschakelen mislukt"
                        }
                    }
                },

            }
        },
        "tos": {
            "title": {
                "singular": "Voorwaarde",
                "plural": "Voorwaarden"
            },
            "edit": {
                "title": "Voorwaarden aanpassen"
            },
            "new": "Nieuwe Voorwaarden",
            "no_results": "Geen voorwaarden beschikbaar"
        },
        "user": {
            "title": "Gebruikers beheren"
        }
    },
    "subscriber": {
        "title": {
            "singular": "Lid",
            "plural": "Leden"
        },
        "new": "Nieuw lid",
        "no_results": "Geen leden beschikbaar"
    },
    "subscription": {
        "title": {
            "singular": "Abonnement",
            "plural": "Abonnementen"
        },
        "new": "Nieuw Abonnement",
        "no_results": "Nog Geen Abonnementen",
        "status": {
            "draft": "Concept",
            "published": "Gepubliceerd",
            "scheduled": "Gepland"
        },
        "config": {
            "advanced": "Geavanceerd",
            "title": "Configuratie",
            "steps": {
                "extras": "Extra's",
                "fees": "Toeslagen",
                "plans": "Plannen",
                "benefits": "Voordelen",
                "discounts": "Kortingen"
            }
        }
    },
    "ui": {
        "next": "Volgende",
        "home": "Home"
    },
    "vat": {
        "title": "BTW",
        "including": {
            "short_name": "Incl.",
            "full_name": "Inclusief"
        },
        "excluding": {
            "short_name": "Excl.",
            "full_name": "Exclusief"
        }
    }
} satisfies I18nDictionary