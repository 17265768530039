import React from 'react'
import {DragHandleRounded} from "@mui/icons-material";
import {useSortable} from "@dnd-kit/sortable";
import {CSS as DndkitCSS} from "@dnd-kit/utilities";

type SubscriptionBenefitsCardProps = {
    title: string
    id: string

}

export function BenefitCard({title, id}: SubscriptionBenefitsCardProps) {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});
    return (

        <div className={'p-4 bg-white rounded-md drop-shadow'}
            ref={setNodeRef}
            style={{
                transform: DndkitCSS.Transform.toString(transform),
                transition,
                touchAction: 'none'
            }}
        >
            <div className={'flex items-center gap-x-4'}>
                <DragHandleRounded
                    {...listeners} // Attach listeners here to handle drag events
                    {...attributes} // Attach attributes to allow accessibility and proper drag behavior
                />
                <p className={'text-neutral-900 font-semibold text-sm'}>{title}</p>
            </div>
        </div>
    )
}