import React, {Dispatch, FC, SetStateAction, useEffect, useMemo, useRef} from "react";
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "@src/@/components/ui/card";
import {Button} from "@src/@/components/ui/button";
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectCards} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';
import {classNames} from "@modules/casting/String";
import type {Swiper as SwiperClass} from "swiper/types";
import {useNavigate} from "react-router-dom";
import UspIcon from "@components/icon/usp.icon";
import useGql from "@modules/graphql/useGql";
import {PricingInterval} from "@pages/merchant/registration/choose-plan/components/Interval.toggle";
import useAuth from "@hooks/useAuth";
import {toEuroString} from "@modules/casting/Number";


type PlanCardUsp = {
    title: string,
    active?: boolean
}

export type PlanCardType = {
    id: string,
    title: string,
    description: string | undefined,
    price: number,
    yearlyPrice?: number
    isPopular?: boolean,
    usps: PlanCardUsp[]
}

type Props = {
    tabState: [number, Dispatch<SetStateAction<number>>]
    pricingInterval: PricingInterval | undefined
    cards: PlanCardType[]
}

const PlanCard: FC<Props> = ({
    tabState,
    pricingInterval,
    cards
}) => {

    const gql = useGql()
    const navigate = useNavigate();
    const swiperRef = useRef<SwiperClass>(null)
    const auth = useAuth()

    const [currentTabIndex, setCurrentTabIndex] = useMemo(() => tabState, [tabState[0]]);

    const mostPopularIndex = useMemo(() => {
        return cards?.findIndex(card => card.isPopular === true)
    }, [cards]);

    useEffect(() => {
        if (swiperRef.current && currentTabIndex !== -1) {
            swiperRef.current.slideTo(currentTabIndex);
        }
    }, [currentTabIndex]);

    useEffect(() => {
        if (swiperRef.current && mostPopularIndex !== -1) {
            swiperRef.current.slideTo(mostPopularIndex);
        }
    }, [mostPopularIndex]);


    const isCurrentIndex = (index: number) => {
        return index === currentTabIndex
    }

    const isMostPopular = (index: number) => {
        return index === mostPopularIndex
    }

    const handleSlideChange = (swiper: SwiperClass) => {
        setCurrentTabIndex(swiper.activeIndex);
    };


    /**
     * @todo persist tier choice in backend + interval
     */
    const handleTierClick = async (tierID: string, intervalID?: string) => {
        if (auth.userId && intervalID) {
            await gql.AddToShoppingCart({
                input: {
                    intervalId: intervalID,
                    tierId: tierID
                }

            }).then(() => {
                navigate('/checkout')
            })
        }
    }


    return <div className={'flex justify-content-center flex-grow h-[calc(100vh-24rem)]'}>
        <Swiper
            ref={swiperRef}
            style={{height: 'auto'}}
            effect={'cards'}
            grabCursor={true}
            modules={[EffectCards]}
            onSlideChange={handleSlideChange}
            onSwiper={(swiper: SwiperClass) => swiperRef.current = swiper}
        >
            {
                cards.map(({title, price, yearlyPrice, usps, id}, index) => <SwiperSlide
                    key={index}
                    className={`card-${index + 1} rounded-xl`}
                >
                    <Card
                        className={classNames(
                            'relative bg-white overflow-hidden rounded-xl flex flex-col h-full w-full',
                            isCurrentIndex(index) && 'z-10',
                            isMostPopular(index) && classNames(
                                "after:tracking-wide after:content-['Most_Popular']",
                                "after:absolute after:top-0 after:w-full after:py-2.5 overflow-hidden",
                                "after:font-semibold after:text-2xl after:text-center after:text-neutral-100",
                                "after:bg-primary-800 after:rounded-t-md",
                            )
                        )}
                    >
                        <CardHeader className={classNames(
                            'px-4 gap-y-2 flex flex-col space-y-0',
                            isMostPopular(index) && 'mt-14'
                        )}>
                            <CardTitle className={'text-2xl font-roboto font-medium ml-2'}>
                                {title}
                            </CardTitle>
                            <CardDescription className={'text-xl font-medium ml-2'}>
                                <span>{toEuroString(price)}</span>
                                <span
                                    className={`text-sm ml-1`}>/ {!yearlyPrice ? (pricingInterval?.title ?? 'monthly') : 'monthly'}</span>
                                {yearlyPrice && <span className={'block text-xs font-semibold relative top-0 -mb-4'}>
                                    {toEuroString(yearlyPrice)} billed yearly
                                </span>}
                            </CardDescription>
                        </CardHeader>

                        <CardContent className={'space-y-4 px-5 flex-grow mt-2'}>
                            {usps.map((usp, uspIndex) => <div
                                className={classNames('flex gap-x-2.5 items-start', !usp.active && 'opacity-50')}
                                key={uspIndex}
                            >
                                <UspIcon className={classNames('h-6 -mt-0.5', !usp.active && 'opacity-50')}/>
                                <p className={'font-medium text-sm'}>{usp.title}</p>
                            </div>)}
                        </CardContent>

                        <CardFooter className={'flex justify-center px-4 pb-4'}>
                            <Button
                                className={'rounded-md py-1.5 bg-primary-600 text-neutral-100 w-full hover:bg-gray-300/20'}
                                onClick={() => handleTierClick(id, pricingInterval?.id)}
                            >
                                Choose
                            </Button>
                        </CardFooter>

                    </Card>
                </SwiperSlide>)
            }
        </Swiper>

    </div>
}

export default PlanCard