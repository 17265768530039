import {RouterDefinition} from "@config/router.config";
import {__t} from "@config/i18n";
import PaymentIndexPage from "@pages/merchant/payment/Payment.index.page";
import PaymentListPage from "@pages/merchant/payment/pages/list/Payment.list.page";
import SubscriberShowPage from "@pages/merchant/subscriber/show/Subscriber.show.page";
import SubscriptionIndexPage from "@pages/merchant/subscriptions/Subscription.index.page";
import SettingsIndexPage from "@pages/merchant/settings/Settings.index.page";
import SubscriberIndexPage from "@pages/merchant/subscriber/index/Subscriber.index.page";
import LoginPage from "@pages/auth/login/Login.page";
import AuthorizePage from "@pages/auth/authorize/Authorize.page";
import SubscriptionShowPage from "@pages/merchant/subscriptions/_legacy/show/Subscription.show.page";
import TierShowPage from "@pages/merchant/__legacy/tiers/Tier.show.page";
import OnboardingIndexPage from "@pages/merchant/onboarding/Onboarding.index.page";
import CheckoutIndexPage from "@pages/merchant/registration/checkout/CheckOut.index.page";
import CompanyRegisterPage from "@pages/merchant/registration/company/Company.register.page";
import MerchantRegisterPage from "@pages/merchant/registration/merchant/Merchant.register.page";
import CheckoutSuccessPage from "@pages/merchant/registration/checkout/pages/success/CheckOut.success.page";
import ChoosePlanIndexPage from "@pages/merchant/registration/choose-plan/ChoosePlan.index.page";
import {OtpPage} from "@pages/auth/mfa/Otp.page";
import MfaIndexPage from "@pages/merchant/settings/pages/personal/mfa/Mfa.index.page";
import HomePage from "@pages/Home/Home.page";
import LogoutPage from "@pages/auth/logout/Logout.page";
import MfaScanQrCodePage from "@pages/merchant/settings/pages/personal/mfa/pages/setup/MfaSetupQrCode.page";
import MfaSetupVerifyOtpPage from "@pages/merchant/settings/pages/personal/mfa/pages/setup/MfaSetupVerifyOtp.page";
import MfaSetupVerifyOtpSuccessPage
    from "@pages/merchant/settings/pages/personal/mfa/pages/setup/MfaSetupVerifyOtpSuccess.page";
import MfaManagePage from "@pages/merchant/settings/pages/personal/mfa/pages/manage/MfaManage.page";
import MfaDeactivatePage from "@pages/merchant/settings/pages/personal/mfa/pages/deactivate/MfaDeactivate.page";
import MfaDeactivateSuccessPage
    from "@pages/merchant/settings/pages/personal/mfa/pages/deactivate/MfaDeactivateSuccess.page";
import PaymentShowPage from "@pages/merchant/payment/pages/show/Payment.show.page";
import {SubscriberListPage} from "@pages/merchant/subscriber/list/Subscriber.list.page";
import {SubscriberInvoiceList} from "@pages/merchant/subscriber/show/components/SubscriberInvoiceList";
import {MerchantTOS} from "@pages/merchant/tos/MerchantTOS";
import {MerchantTOSShowPage} from "@pages/merchant/tos/MerchantTOSShowPage";
import {MerchantTOSEditor} from "@pages/merchant/tos/MerchantTOSEditor";
import {CheckOutRedirect} from "@pages/merchant/registration/checkout/pages/CheckOut.redirect";
import {SubscriptionConfigIndexPage} from "@pages/merchant/subscriptions/pages/config/SubscriptionConfig.index.page";
import {BenefitIndexPage} from "@pages/merchant/subscriptions/pages/config/pages/benefit/Benefit.index.page";
import {ExtraIndexPage} from "@pages/merchant/subscriptions/pages/config/pages/extra/Extra.index.page";
import {DiscountIndexPage} from "@pages/merchant/subscriptions/pages/config/pages/discount/Discount.index.page";
import {FeeIndexPage} from "@pages/merchant/subscriptions/pages/config/pages/fee/Fee.index.page";
import {BillingIndexPage} from "@pages/merchant/settings/pages/billing/Billing.index.page";
import CompanySettingsIndexPage
    from "@pages/merchant/settings/pages/company-settings/company/CompanySettings.index.page";
import {ManageUsersIndex} from "@pages/merchant/settings/pages/company-settings/users/ManageUsers.index";
import {PlanIndexPage} from "@pages/merchant/subscriptions/pages/config/pages/plan/Plan.index.page";


/**
 * Base routes should be defined top level.
 * The component of the base route should be defined as a child.
 * The base route component should have 'index=true'.
 * The base route index component should not have children.
 *
 * Children are only supported by the top level route (for now).
 * Pseudo children can be defined by adding the parent in the path.
 */
const Routes: RouterDefinition = [
    {
        path: '/',
        children: [
            {
                title: __t('ui.home'),
                index: true,
                Component: HomePage,
            }
        ]
    },
    {
        path: 'authorize',
        children: [
            {
                title: __t('auth.authorise'),
                index: true,
                Component: AuthorizePage,
            }
        ]
    },
    {
        path: 'login',
        children: [
            {
                title: __t('auth.login'),
                index: true,
                Component: LoginPage,
            }
        ]
    },
    {
        path: 'otp',
        children: [
            {
                title: __t('auth.otp.title'),
                index: true,
                Component: OtpPage,
            },
        ]
    },
    {
        path: 'logout',
        children: [
            {
                title: __t('auth.logout'),
                index: true,
                Component: LogoutPage,
            }
        ]
    },
    {
        path: 'register',
        children: [

            {
                title: __t('registration.create_account.title'),
                index: true,
                Component: MerchantRegisterPage,
            },
            {
                title: __t('registration.register_company.title'),
                path: 'company',
                Component: CompanyRegisterPage,
            },
            {
                title: __t('registration.payment_status.title'),
                path: 'payment',
                Component: CheckOutRedirect,
            },
        ]
    },
    {
        path: 'choose-plan',
        children: [
            {
                title: __t('registration.choose_plan.title'),
                index: true,
                Component: ChoosePlanIndexPage,
            },
        ]
    },
    {
        path: 'checkout',
        children: [
            {
                title: __t('registration.checkout.title'),
                index: true,
                Component: CheckoutIndexPage,
            },
            {
                title: __t('registration.checkout.status.success.title'),
                path: 'success',
                Component: CheckoutSuccessPage,
            }
        ]
    },

    {
        path: 'onboarding',
        isGuarded: true,
        children: [
            {
                title: __t('onboarding.title'),
                index: true,
                Component: OnboardingIndexPage,
            }
        ],
    },
    {
        path: 'payments',
        isGuarded: true,
        isDebug: true,
        children: [
            {
                title: __t('payment.title.plural'),
                index: true,
                Component: PaymentIndexPage,
            },
            {
                title: __t('payment.title.singular'),
                path: ':id',
                Component: PaymentShowPage,
            },
            {
                title: __t('payment.title.plural'),
                path: 'list',
                Component: PaymentListPage,
            },
            {
                title: __t('payment.title.singular'),
                path: 'list/:id',
                Component: PaymentShowPage,
            },
        ],
    },
    {
        path: 'subscriptions',
        isGuarded: true,
        children: [
            {
                title: __t('subscription.title.plural'),
                index: true,
                Component: SubscriptionIndexPage
            },
            {
                title: __t('subscription.title.singular'),
                path: ':id',
                Component: SubscriptionShowPage,
            },
            {
                title: __t('plan.title.singular'),
                path: ':subscriptionId/tiers/:tierId',
                Component: TierShowPage,
            },
            {
                title: __t('subscription.config.title'),
                path: ':subscriptionId/config',
                Component: SubscriptionConfigIndexPage
            },
            {
                title: __t('plan.title.plural'),
                path: ':subscriptionId/config/plans',
                Component: PlanIndexPage
            },
            {
                title: __t('benefit.title.plural'),
                path: ':subscriptionId/config/benefits',
                Component: BenefitIndexPage
            },
            {
                title: __t('extra.title.plural'),
                path: ':subscriptionId/config/extras',
                Component: ExtraIndexPage
            },
            {
                title: __t('discount.title.plural'),
                path: ':subscriptionId/config/discounts',
                Component: DiscountIndexPage
            },
            {
                title: __t('fee.title.plural'),
                path: ':subscriptionId/config/fees',
                Component: FeeIndexPage
            },
        ],
    },
    {
        path: 'subscribers',
        isGuarded: true,
        children: [
            {
                title: __t('subscriber.title.plural'),
                index: true,
                Component: SubscriberIndexPage,
            },
            {
                title: __t('subscriber.title.plural'),
                path: 'list',
                Component: SubscriberListPage,
            },
            {
                title: __t('subscriber.title.singular'),
                path: 'list/:id',
                Component: SubscriberShowPage,
            },
            {
                title: __t('subscriber.title.singular'),
                path: 'list/:id/invoice',
                Component: SubscriberInvoiceList
            }
        ],
    },
    {
        path: 'settings',
        isGuarded: true,
        children: [
            {
                title: __t('settings.title.plural'),
                index: true,
                Component: SettingsIndexPage,
            },
            {
                title: __t('settings.company.title'),
                path: 'company',
                Component: CompanySettingsIndexPage,
            },
            {
                title: __t('settings.user.title'),
                path: 'manage-users',
                Component: ManageUsersIndex
            },
            {
                title: __t('settings.mfa.title'),
                path: 'manage-mfa',
                Component: MfaManagePage,
            },
            {
                title: __t('settings.mfa.setup.title'),
                path: 'setup-mfa',
                Component: MfaIndexPage,
            },
            {
                title: __t('settings.mfa.setup.scan.title'),
                path: 'setup-mfa/scan-qr',
                Component: MfaScanQrCodePage,
            },
            {
                title: __t('settings.mfa.setup.scan.verify.title'),
                path: 'setup-mfa/scan-qr/verify-otp',
                Component: MfaSetupVerifyOtpPage,
            },
            {
                title: __t('settings.mfa.setup.scan.verify.verify.success.title'),
                path: 'setup-mfa/scan-qr/verify-otp/success',
                Component: MfaSetupVerifyOtpSuccessPage,
            },

            {
                title: __t('settings.mfa.setup.scan.deactivate.title'),
                path: 'manage-mfa/deactivate',
                Component: MfaDeactivatePage,
            },
            {
                title: __t('settings.mfa.setup.scan.deactivate.success.title'),
                path: 'manage-mfa/deactivate/success',
                Component: MfaDeactivateSuccessPage,
            },
            {
                title: __t('settings.billing.title'),
                path: 'billing',
                Component: BillingIndexPage
            },
            {
                title: __t('settings.tos.title.plural'),
                path: 'tos',
                Component: MerchantTOS
            },
            {
                title: __t('settings.tos.title.singular'),
                path: 'tos/:id',
                Component: MerchantTOSShowPage
            },
            {
                title: __t('settings.tos.edit.title'),
                path: 'tos/:id/edit',
                Component: MerchantTOSEditor
            }
        ],
    },

] satisfies RouterDefinition;

export default Routes;
