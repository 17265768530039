import React from 'react';
import SearchBar from "@components/search/SearchBar.component";
import FilterButton from "@components/button/FilterButton";

const PaymentFilter = () => {
    return (
        <SearchBar
            className={'pt-shell'}
            inputProps={{placeholder: 'Find payment'}}
            button={<FilterButton/>}
        />
    );
};

export default PaymentFilter;
