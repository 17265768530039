import React, {useEffect, useState} from 'react';
import ShowPage from "@templates/page/ShowPage.template";
import Card from "@components/card/Card.component";
import {BaseCompanyFragment, CreateCompanyMutation, UpdateCompanyMutation} from "@src/gql-schema";
import useGql from "@modules/graphql/useGql";
import CompanyUpdateTestForm
    from "@pages/merchant/settings/pages/company-settings/company/forms/CompanyUpdateTest.form";
import CompanyCreateTestForm
    from "@pages/merchant/settings/pages/company-settings/company/forms/CompanyCreateTest.form";

const CompanySettingsIndexPage = () => {

    const gql = useGql();

    const [currentCompany, setCurrentCompany] = useState<BaseCompanyFragment | null>(null)

    useEffect(() => {
        gql.GetCompanies()
            .then(res => {
                if (res.getCompanies) {
                    setCurrentCompany(res.getCompanies[0])
                }
            });
    }, []);

    const handleSuccess = (values?: CreateCompanyMutation | UpdateCompanyMutation) => {
        console.log({values})
        if (!currentCompany) {
            gql.GetCompanies()
                .then(res => {
                    if (res.getCompanies) {
                        setCurrentCompany(res.getCompanies[0])
                    }
                });
            return;
        }

        // @fixme returntype of response
        setCurrentCompany({
            ...currentCompany,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...values.updateCompany
        })
    }

    return (
        <ShowPage>
            <Card>
                <p>Company: {currentCompany?.name}</p>
                <p>Invoice contact: {currentCompany?.invoiceRecipient}</p>
                <p>Invoice email: {currentCompany?.invoiceEmail}</p>
            </Card>
            <Card>
                {currentCompany?.id
                    ? <CompanyUpdateTestForm
                        id={currentCompany?.id}
                        onSubmitSuccess={handleSuccess}
                    />
                    : <CompanyCreateTestForm onSubmitSuccess={handleSuccess}/>
                }
            </Card>
        </ShowPage>
    );
};

export default CompanySettingsIndexPage;
