import React, {Dispatch, FC, SetStateAction, useMemo, useState} from 'react'
import PullOver from "@components/pullover/PullOver.component";
import {Button} from "@src/@/components/ui/button";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {WelcomeDiscountOutput} from "@src/gql-schema";
import {
    AgeDiscountPulloverCreateForm
} from "@pages/merchant/subscriptions/pages/config/pages/discount/forms/AgeDiscountPullover.create.form";
import {
    LoyaltyDiscountPulloverCreateForm
} from "@pages/merchant/subscriptions/pages/config/pages/discount/forms/LoyaltyDiscountPullover.create.form";
import {
    WelcomeDiscountPulloverCreateForm
} from "@pages/merchant/subscriptions/pages/config/pages/discount/forms/WelcomeDiscountPullover.create.form";
import {DiscountType, DiscountTypeItems} from "@app/types/Discount";
import {__t} from "@config/i18n";

type Props = {
    subscriptionId: string
    refreshState: [boolean, Dispatch<SetStateAction<boolean>>]
}
export const DiscountTypePullover: FC<Props> = ({subscriptionId, refreshState}) => {

    const gql = useGql()

    const [showPulloverScreen, setShowPulloverScreen] = useState(false);
    const [showAgePulloverScreen, setShowAgePulloverScreen] = useState(false)
    const [showLoyaltyPulloverScreen, setShowLoyaltyPulloverScreen] = useState(false)
    const [showWelcomePulloverScreen, setShowWelcomePulloverScreen] = useState(false)


    const [refresh, setRefresh] = useMemo(() => {
        return refreshState
    }, [refreshState]);

    const welcomeDiscounts: WelcomeDiscountOutput[] = useAsyncMemo(async () => {
        if (!subscriptionId || !refresh) {
            return welcomeDiscounts ?? []
        }
        return await gql.GetWelcomeDiscounts({subscriptionId})
            .then(r => r.getWelcomeDiscounts)
    }, [subscriptionId], [])

    function handleOnClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        switch (e.currentTarget.value as DiscountType) {
            case ('age'):
                setShowAgePulloverScreen(true);
                break;

            case ('loyalty'):
                setShowLoyaltyPulloverScreen(true);
                break;

            case ('welcome'):
                setShowWelcomePulloverScreen(true);
                break;

            default:
                return;
        }
        setShowPulloverScreen(false)
    }

    return (<>
        <PullOver
            title={__t('discount.choose_type')}
            openState={[showPulloverScreen, setShowPulloverScreen]}
            submit={{title: '', hidden: true}}
            trigger={{title: __t('discount.new')}}
        >
            <div className={'space-y-4'}>
                {Object.keys(DiscountTypeItems).map(discountItem => <Button
                    key={discountItem}
                    value={discountItem}
                    onClick={handleOnClick}
                    disabled={(discountItem as DiscountType) == 'welcome' && welcomeDiscounts.length > 0}
                >
                    {DiscountTypeItems[discountItem as DiscountType]}
                </Button>)}
            </div>
        </PullOver>

        <AgeDiscountPulloverCreateForm
            subscriptionId={subscriptionId}
            onSuccess={() => setRefresh(true)}
            pulloverState={[showAgePulloverScreen, setShowAgePulloverScreen]}
        />
        <LoyaltyDiscountPulloverCreateForm
            subscriptionId={subscriptionId}
            onSuccess={() => setRefresh(true)}
            pulloverState={[showLoyaltyPulloverScreen, setShowLoyaltyPulloverScreen]}
        />
        <WelcomeDiscountPulloverCreateForm
            subscriptionId={subscriptionId}
            onSuccess={() => setRefresh(true)}
            pulloverState={[showWelcomePulloverScreen, setShowWelcomePulloverScreen]}
        />
    </>)
}
