import {Link} from "react-router-dom";
import React, {FC, useMemo} from "react";
import PaymentList from "@pages/merchant/payment/components/Payment.list";
import {mockPaymentData} from "@app/mock/MockPaymentData";
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";
import H2 from "@components/text/H2.component";

type Props = {
    chartHeight: number
}
const PaymentActivityList: FC<Props> = ({chartHeight}) => {

    const {available} = useWorkspaceHeight();

    const paymentItems = useMemo(() => {
        return mockPaymentData.slice(0, 16)
    }, []);

    const containerHeight = useMemo(() => {
        return `calc(${available - chartHeight}px - 2rem)`
    }, [available]);

    const listHeight = useMemo(() => {
        return `calc(${available - chartHeight}px - 4.6rem)`
    }, [available]);

    return (
        <div style={{height: containerHeight}}>
            <div
                className={'flex items-center justify-between border-b py-2 -mt-shell -mx-shell px-shell relative shadow-lg z-[1]'}>
                <H2>Latest Activity</H2>
                <Link to={'/payments/list'} className={'text-xs text-secondary-600'}>Show more</Link>
            </div>
            <div className={'h-[calc(1rem/32)]'}/>
            <PaymentList forIndexPage={true} height={listHeight} items={paymentItems}/>
        </div>
    )
}
export default PaymentActivityList