import React, {FC, useEffect, useMemo} from 'react';
import {Link, useLocation} from "react-router-dom";
import {
    AccountBalanceWalletOutlined,
    GroupOutlined,
    InsertDriveFileOutlined,
    SettingsOutlined
} from "@mui/icons-material";
import {classNames} from "@modules/casting/String";
import {__t} from "@config/i18n";
import {useMeasure} from "@uidotdev/usehooks";
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import {IconType} from "@app/types/Icons";


type NavItemProps = {
    link: string,
    title: string,
    Icon: IconType
}

const NavItem: FC<NavItemProps> = ({link, title, Icon}) => {

    const location = useLocation();

    const linkText = useMemo(() => {
        return link.replaceAll('/', '');
    }, [link]);

    return <>
        <Link
            to={link}
            className={classNames(
                location.pathname.includes(linkText) ? 'text-primary font-semibold' : 'text-neutral-700'
            )}
        >
            <Icon/>
            <span className={'text-[0.7rem] block'}>{title}</span>
        </Link>
    </>
}

const NavbarBottom = () => {

    const [ref, {height}] = useMeasure()
    const navBottom = useNavbarBottom()
    const setHeight = navBottom.height[1]
    const children = navBottom.children[0];
    const hide = navBottom.hide[0]

    useEffect(() => {
        setHeight(height ?? 0)
    }, [height]);

    return (
        <div ref={ref}>
            {children}
            <nav className={classNames(hide ? 'hidden' : 'flex justify-between text-center pt-1 pb-2')}>

                <NavItem title={__t('invoice.title.plural')} link={'/payments'} Icon={AccountBalanceWalletOutlined}/>
                <NavItem title={__t('subscription.title.plural')} link={'/subscriptions'}
                    Icon={InsertDriveFileOutlined}/>
                <NavItem title={__t('subscriber.title.plural')} link={'/subscribers'} Icon={GroupOutlined}/>
                <NavItem title={__t('settings.title.plural')} link={'/settings'} Icon={SettingsOutlined}/>
            </nav>
        </div>

    )
};

export default NavbarBottom;
