import React, {useEffect, useState} from 'react'
import ShowPage from "@templates/page/ShowPage.template";
import {NavbarBottomButtonWrapper} from "@components/button/NavbarBottomButton.wrapper";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {SubscriptionDiscountOutput} from "@src/gql-schema";
import {NoResourcesScreen} from "@pages/merchant/subscriptions/components/NoResources.screen";
import useParamId from "@hooks/useParamId";
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import {
    DiscountTypePullover
} from "@pages/merchant/subscriptions/pages/config/pages/discount/components/DiscountType.pullover";
import {DiscountList} from "@pages/merchant/subscriptions/pages/config/pages/discount/components/Discount.list";
import {__t} from "@config/i18n";

export function DiscountIndexPage() {

    const gql = useGql()
    const subscriptionId = useParamId('subscriptionId');
    const navbarBottom = useNavbarBottom()

    const [refresh, setRefresh] = useState(true)

    /**
     *
     */
    const subscriptionName = useAsyncMemo(async () => {
        return await gql.GetSubscription({id: subscriptionId})
            .then(r => r.getSubscription.title)
    }, [subscriptionId], __t('subscription.config.title'))

    /**
     *
     */
    useEffect(() => {
        navbarBottom.children[1](
            <NavbarBottomButtonWrapper>
                <DiscountTypePullover
                    subscriptionId={subscriptionId}
                    refreshState={[refresh, setRefresh]}
                />
            </NavbarBottomButtonWrapper>
        )
    }, [subscriptionId]);

    /**
     *
     */
    const discounts: SubscriptionDiscountOutput[] = useAsyncMemo(async () => {
        if (!refresh) {
            return discounts
        }
        return await gql.GetSubscriptionDiscounts({subscriptionId: subscriptionId ?? ''})
            .then(r => r.getSubscriptionDiscounts)
            .finally(() => setRefresh(false))
    }, [subscriptionId, refresh], []);


    return (
        <ShowPage back={{
            title: subscriptionName,
            path: `/subscriptions/${subscriptionId}/config`
        }}>
            {discounts.length === 0 && <NoResourcesScreen text={__t('discount.no_results')}/>}
            <DiscountList discounts={discounts}/>
        </ShowPage>
    )
}