import React, {FC, useMemo, useState} from 'react'
import {FormFields} from "@components/form/fields/FormField.component";
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import {CreateBenefitInput} from "@src/gql-schema";
import useGql from "@modules/graphql/useGql";
import {__t} from "@config/i18n";

type FormInput = Omit<CreateBenefitInput, 'subscriptionId'>;

type Props = {
    subscriptionId: string,
    onSuccess: (benefitId: string) => void | Promise<void>
}


export const BenefitPulloverCreateForm: FC<Props> = ({subscriptionId, onSuccess}) => {

    const gql = useGql();
    const [show, setShow] = useState(false);

    const fields = useMemo((): FormFields<FormInput> => {
        return {
            title: {
                zodType: z.string().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.title')
            },
            description: {
                zodType: z.string(),
                fieldType: 'textarea',
                label: __t('form.field.description')
            }
        }
    }, []);


    return (
        <PullOverForm
            title={__t('benefit.new')}
            form={{
                submit: gql.CreateBenefit,
                onSubmitSuccess: (mutation) => {
                    if (mutation != null) {
                        onSuccess(mutation.createBenefit.id)
                    }
                },
                fields,
                payloadInjection: {
                    subscriptionId
                },
            }}
            submit={{title: __t('form.action.save')}}
            onClose={() => setShow(false)}
            openState={[show, setShow]}
            trigger={{title: __t('benefit.new')}}
        />
    )
}