import {PlanCard} from "@pages/merchant/subscriptions/pages/config/pages/plan/components/Plan.card";
import React, {Dispatch, SetStateAction} from "react";
import {TierOutput} from "@src/gql-schema";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {__t} from "@config/i18n";

export type SubscriptionPlanCardListProps = {
    plans: TierOutput[]
    setPlans: Dispatch<SetStateAction<TierOutput[]>>
    handleChange: (id: string) => void
}

export function SubscriptionPlanCardList({
    plans, handleChange, setPlans
}: SubscriptionPlanCardListProps) {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    )

    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event;

        if (active.id !== over?.id) {
            setPlans((items) => {
                const oldIndex = items.findIndex(
                    (item) => item.id === active.id,
                );
                const newIndex = items.findIndex((item) => item.id === over?.id);

                // Reorder the items using arrayMove
                const newItems = arrayMove(items, oldIndex, newIndex);

                // Update the order property (For plans state)
                return newItems.map((item, index) => ({
                    ...item,
                    order: index,
                }));

            })
        }
    }

    return (<>
        {plans.length > 0 && <div className={'pr-4 text-right text-xs font-semibold text-neutral-600 mb-2'}>
            <div>{__t('plan.highlighted')}</div>
        </div>}

        <div className={'space-y-3 flex flex-col'}>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
            >
                <SortableContext
                    items={plans.map((plan) => plan.id)}
                    strategy={verticalListSortingStrategy}
                >
                    {plans.map((plan) => (
                        <PlanCard
                            key={plan.id}
                            id={plan.id}
                            title={plan.title}
                            handleChange={handleChange}
                            price={plan.price}
                            isHighlighted={plan.isHighlighted}
                        />
                    ))}
                </SortableContext>
            </DndContext>
        </div>
    </>)
}
