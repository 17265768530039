import React from 'react'
import {RiRefund2Line} from "react-icons/ri";
import {CustomerDetails} from "@pages/merchant/payment/pages/show/components/Payment.details";

interface RefundBannerProps {
    customer: CustomerDetails | undefined
}

export function RefundBanner({customer}: RefundBannerProps) {
    return (
        <div className={'border border-red-700 bg-red-100 p-4 rounded-md text-red-700'}>
            <div className={'flex gap-x-2'}>
                <RiRefund2Line size={24}/>
                <p className={'text-sm'}>
                    <span className={'font-semibold'}>Refunded:</span>
                    <span
                        className={'font-medium'}> This payment was Refunded on {customer?.refundDate ?? '08-20-2024'}.
                    </span>
                    <span
                        className={'font-medium'}> Refund Amount - {customer?.refundType === 'Euro' ? `€${customer?.refundAmount}` : `${customer?.refundAmount ?? 100}%`}
                    </span>
                </p>
            </div>
        </div>
    )
}