import React, {Dispatch, SetStateAction} from 'react'
import {ExtraOutput} from "@src/gql-schema";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {ExtraCard} from "@pages/merchant/subscriptions/pages/config/pages/extra/components/Extra.card";

type SubscriptionExtrasCardListProps = {
    extras: ExtraOutput[]
    setExtras: Dispatch<SetStateAction<ExtraOutput[]>>
}

export function ExtraList({extras, setExtras}: SubscriptionExtrasCardListProps) {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    )


    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event;

        if (active.id !== over?.id) {
            setExtras((items) => {
                const oldIndex = items.findIndex(
                    (item) => item.id === active.id,
                );
                const newIndex = items.findIndex((item) => item.id === over?.id);

                // Reorder the items using arrayMove
                const newItems = arrayMove(items, oldIndex, newIndex);

                // Update the order property (For Benefits state)
                return newItems.map((item, index) => ({
                    ...item,
                    order: index,
                }));

            })
        }
    }

    return (
        <div className={'space-y-3 flex flex-col'}>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
            >
                <SortableContext
                    items={extras.map((extra) => extra.id) ?? []}
                    strategy={verticalListSortingStrategy}
                >
                    {
                        extras.map(extra => {
                            return (
                                <ExtraCard key={extra.id} id={extra.id} title={extra.title}
                                    value={extra.value}/>
                            )
                        })
                    }
                </SortableContext>
            </DndContext>
        </div>


    )
}