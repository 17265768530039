import React, {DetailedHTMLProps, Dispatch, FormEvent, forwardRef, HTMLAttributes, SetStateAction,} from "react";
import {Form, FormItem,} from "@src/@/components/ui/form"
import {UseFormReturn} from "react-hook-form";
import {z} from "zod";
import {formSchema, SupportedPaymentMethod} from "@pages/merchant/registration/checkout/CheckOut.index.page";
import InputSameLineComponent from "./InputSameLineComponent";
import InputRadioComponent from "./InputRadioComponent";
import InputCheckOutComponent from "./InputCheckOutComponent";
import SEPAFormComponent from "./SEPAFormComponent";

interface CheckOutFormComponentProps extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>, 'onSubmit' | 'onChange'> {
    form: UseFormReturn<z.infer<typeof formSchema>>,
    paymentMethods: readonly SupportedPaymentMethod[],
    onChange: (values: z.infer<typeof formSchema>) => void,
    buttonText: string,
    ref: React.MutableRefObject<HTMLFormElement>,
    selectedPaymentMethod: SupportedPaymentMethod,
    setSelectedPaymentMethod: Dispatch<SetStateAction<SupportedPaymentMethod>>
}

export type FormSchemaKeys = keyof typeof formSchema['_def']['shape'];

const CheckOutFormComponent: React.ForwardRefRenderFunction<HTMLFormElement, CheckOutFormComponentProps> = ({
    form,
    paymentMethods,
    onChange,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    ...props
}, ref) => {

    /* temp solution */
    const formSchemaProperty: FormSchemaKeys[] = Object.keys(formSchema.shape) as FormSchemaKeys[]

    const handleChange = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onChange(form.getValues())
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }


    return (
        <Form {...props} {...form}>
            <form
                ref={ref}
                onSubmit={handleSubmit}
                onChange={handleChange}
                className={'w-full flex flex-col flex-wrap space-y-5'}
            >
                <InputRadioComponent
                    form={form}
                    options={paymentMethods as string[]}
                    formName={formSchemaProperty[0]}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    label={''}
                />
                <>
                    {selectedPaymentMethod === 'Credit Card' && <FormItem
                        className={'flex flex-col space-y-0 gap-y-2 items-center flex-wrap'}
                    >

                        {['Card Number', 'Card Holder'].map((label, index) => <InputCheckOutComponent
                            key={index}
                            form={form}
                            label={label}
                            formName={formSchemaProperty[index + 1]}
                        />)}

                        <InputSameLineComponent
                            form={form}
                            formName1={formSchemaProperty[3]}
                            formName2={formSchemaProperty[4]}
                            label1={'Expiration Date'}
                            label2={'CVV'}
                            containerClass={'flex gap-x-2 items-center space-y-0 w-full'}
                            firstInputClass={'flex-grow'} secondInputClass={'w-3/12'}
                        />
                    </FormItem>}

                    {selectedPaymentMethod === 'SEPA' && <SEPAFormComponent/>}
                    {/* @todo PayPal support */}
                    {/*{selectedPaymentMethod === 'PayPal' && <PayPalFormComponent/>}*/}
                </>
            </form>
        </Form>
    )
};

CheckOutFormComponent.displayName = 'CheckOutFormComponent'
export default forwardRef(CheckOutFormComponent)