import React from 'react'
import NewResourceImage from "@assets/svg/new-resource.svg";
import H1 from "@components/text/H1.component";

export function NoResourcesScreen({text}: { text: string }) {
    return (
        <div className={'flex flex-col gap-y-12'}>
            <img className={'-mt-24 w-[80vw] mx-auto'} src={NewResourceImage} alt={text}/>
            <H1>{text}</H1>
        </div>
    )
}