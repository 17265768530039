import React from 'react'
import {SubscriptionFeeOutput} from "@src/gql-schema";
import {FeeCard} from "@pages/merchant/subscriptions/pages/config/pages/fee/components/Fee.card";

type SubscriptionFeesCardListProps = {
    fees: SubscriptionFeeOutput[]
}

export function FeeList({fees}: SubscriptionFeesCardListProps) {
    return (
        <div className={'space-y-4'}>
            {
                fees.map(fee => {
                    return (
                        <FeeCard key={fee.id} id={fee.id} title={fee.title} description={fee.description}
                            amount={fee.amount}/>
                    )
                })
            }
        </div>
    )
}