import React, {useMemo} from 'react'
import {FormFields} from "@components/form/fields/FormField.component";
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import {AmountTypeSelectOptions} from "@app/types/Amount";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {IncludingVatSelectOptions} from "@app/types/Vat";
import useGql from "@modules/graphql/useGql";
import {FeePulloverProps} from "@pages/merchant/subscriptions/pages/config/pages/fee/types/Fee.types";
import {CreateDynamicFeeInput} from "@src/gql-schema";
import {InvoiceIntervalSelectOptions} from "@app/types/Invoice";
import {__t} from "@config/i18n";


type FormInput = Omit<CreateDynamicFeeInput, 'subscriptionId' | 'clauseType' | 'active' | 'order' | 'condition'> & {
    includingVat: string
}

type MutationInput = { input: FormInput }

export function IntervalFeePulloverCreateForm({
    subscriptionId,
    pulloverState,
    onSuccess
}: FeePulloverProps) {

    const gql = useGql()

    const setShowPullover = useMemo(() => {
        return pulloverState[1]
    }, [pulloverState[0]]);

    const subscription = useAsyncMemo(async () => {
        return await gql.GetSubscription({id: subscriptionId}).then(r => r.getSubscription)
    }, [])


    const vat = useMemo(() => {
        return subscription?.vatPercentage ?? 0;
    }, [subscription])


    const includingVatOptions = useMemo(() => {
        return IncludingVatSelectOptions(vat)
    }, [vat]);


    const intervalSelectOptions = useMemo(() => {
        return InvoiceIntervalSelectOptions()
    }, []);

    const fields = useMemo((): FormFields<FormInput> => {
        return {
            title: {
                zodType: z.string().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.title'),
            },
            amount: {
                zodType: z.coerce.number().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.feeAmount'),
                colSpan: 'col-span-3',
            },
            amountType: {
                zodType: z.string(),
                fieldType: 'select',
                label: __t('form.field.feeAmountType'),
                options: AmountTypeSelectOptions,
                defaultValue: AmountTypeSelectOptions[0].value,
                colSpan: 'col-span-4'
            },
            includingVat: {
                zodType: z.coerce.number(),
                fieldType: 'select',
                options: includingVatOptions,
                defaultValue: `${includingVatOptions[0].value}`,
                label: ' ',
                colSpan: 'col-span-5'
            },
            intervalAmount: {
                zodType: z.coerce.number().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.intervalFeeIntervalAmount'),
                colSpan: 'col-span-6',
            },
            interval: {
                zodType: z.string(),
                fieldType: 'select',
                label: ' ',
                options: intervalSelectOptions,
                defaultValue: intervalSelectOptions[0].value,
                colSpan: 'col-span-6',
            },
            description: {
                zodType: z.string(),
                fieldType: 'textarea',
                label: __t('form.field.description')
            }
        }
    }, [intervalSelectOptions, includingVatOptions]);


    return (
        <PullOverForm
            title={__t('fee.interval_fee_title')}
            form={{
                submit: async ({input}: MutationInput) => {
                    let amount = parseFloat(`${input.amount}`.replace(',', '.'))
                    if (input.includingVat && input.amountType == 'price') {
                        const vatFactor = 100 + vat
                        amount = (amount / vatFactor) * 100
                    }

                    return await gql.CreateDynamicFee({
                        input: {
                            amount,
                            amountType: input.amountType,
                            title: input.title,
                            description: input.description,
                            subscriptionId: subscriptionId,
                            intervalAmount: input.intervalAmount,
                            interval: input.interval,

                            // @todo remove from backend
                            active: true,
                            order: 0,
                            clauseType: '',
                            condition: ''
                        }
                    })
                },
                onSubmitSuccess: (mutation) => {
                    if (mutation != null) {
                        onSuccess(mutation.createDynamicFee.id)
                    }
                },
                fields,
                payloadInjection: {},
            }}
            submit={{title: __t('form.action.save')}}
            onClose={() => setShowPullover(false)}
            openState={pulloverState}
        />
    )
}