import React, {FC, ReactElement, ReactNode} from 'react';
import {SubscriptionItemCardProps} from "@pages/merchant/subscriptions/_legacy/show/components/SubscriptionItemCard";


type Props = {
    children: ReactElement<SubscriptionItemCardProps>[],
    archiveButton: ReactNode
}
const SubscriptionItemList: FC<Props> = ({
    children,
    archiveButton
}) => {
    return <div className={'overflow-y-auto h-[calc(100vh-28rem)] -mx-shell px-shell flex flex-col gap-y-2.5'}>
        {children}
        {archiveButton}
    </div>
};

export default SubscriptionItemList
