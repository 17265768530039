import React, {FC, useMemo} from 'react';
import ListComponent, {ListItem, ListItems} from "@components/list/List.component";
import {PaymentItem} from "@app/mock/MockPaymentData";
import {groupObjectsByDate} from "@modules/casting/Object";
import {toEuroString} from "@modules/casting/Number";
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";
import {BillingPaymentStatus} from "@pages/merchant/settings/pages/billing/components/Billing.badge";

type Props = {
    height?: string
    items: PaymentItem[],
    forIndexPage?: boolean
}
const PaymentList: FC<Props> = ({items, height, forIndexPage = false}) => {

    const {available} = useWorkspaceHeight();


    const listItems = useMemo((): ListItems => {
        const groupedItems = groupObjectsByDate(items, 'date', {
            groupType: 'day',
            sort: 'DESC'
        });

        return Object.keys(groupedItems).reduce((acc, groupKey) => ({
            ...acc,
            [groupKey]: groupedItems[groupKey].map(item => ({
                id: item.id,
                dateTime: item.date,
                title: item.title,
                description: item.status,
                target: forIndexPage ? `/payments/${item.id}` : `/payments/list/${item.id}`,
                symbol: item.title[0].concat(item.title.split(' ')[1][0]),
                feedback: toEuroString((['failed', 'refunded', 'reversed'] as BillingPaymentStatus[]).includes(item.status)
                    ? item.amount * -1
                    : item.amount
                ),

            }) satisfies ListItem)
        }), {} satisfies ListItems)
    }, [items]);

    return <ListComponent height={height ?? `${available}px`} items={listItems} adjustWidth={forIndexPage}/>;
};

export default PaymentList;
