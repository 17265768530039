import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

const useParamId = (idKey = 'id') => {
    const id = useParams()[idKey];
    const [currentId, setCurrentId] = useState(id ?? '')
    useEffect(() => {
        if (!id) {
            throw new Error(`ID not found with key "${idKey}"!`)
        }
        setCurrentId(id)
    }, [id]);

    return currentId;
};

export default useParamId;
