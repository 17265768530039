import React, {ReactNode} from 'react'
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,} from "@src/@/components/ui/tooltip"
import {TooltipArrow} from "@radix-ui/react-tooltip";


interface PricingTooltipComponentProps {
    trigger: ReactNode,
    balloon?: ReactNode
}

const DiscountTooltip: React.FC<PricingTooltipComponentProps> = ({trigger, balloon}) => {
    return <TooltipProvider>
        <Tooltip defaultOpen={true} open={true}>
            <TooltipTrigger asChild>{trigger}</TooltipTrigger>
            {balloon && <TooltipContent className={'text-white bg-neutral'}>
                {balloon}
                <TooltipArrow/>
            </TooltipContent>}
        </Tooltip>
    </TooltipProvider>
}

export default DiscountTooltip