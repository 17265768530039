import {__t} from "@config/i18n";
import {SelectOption} from "@components/form/fields/Select.component";

export type DiscountType = 'age' | 'loyalty' | 'welcome'

export type DiscountTypeItem = { [K in DiscountType]: string }
export const DiscountTypeItems: DiscountTypeItem = {
    'age': __t('discount.type.age'),
    'loyalty': __t('discount.type.loyalty'),
    'welcome': __t('discount.type.welcome'),
}


export const DiscountAgeLessThanOptions: SelectOption<number>[] = [
    {
        title: __t('discount.age.younger'),
        value: 1
    },
    {
        title: __t('discount.age.older'),
        value: 0
    }
]

export const DiscountAgeOrEqualOptions: SelectOption<number>[] = [
    {
        title: __t('discount.equal_to.than'),
        value: 1
    },
    {
        title: __t('discount.equal_to.than_or_equal_to'),
        value: 0
    }
]