import React from 'react'

type SubscriptionFeesCardProps = {
    id: string
    title: string
    description: string
    amount: number
}

export function FeeCard({id, title, description, amount}: SubscriptionFeesCardProps) {
    return (
        <div key={id}
            className={'flex items-center justify-between gap-x-2 bg-white drop-shadow p-4 rounded-md text-neutral-900'}>
            <div className={'flex flex-col gap-y-3'}>
                <p className={'text-sm font-semibold'}>{title}</p>
                <p className={'text-xs font-medium text-neutral-600'}>{description}</p>
            </div>
            <div>
                <p className={'text-sm font-semibold'}>€{Number.isInteger(amount) ? `${amount}.00` : amount}</p>
            </div>
        </div>
    )
}