import React from 'react'
import {DragHandleRounded} from "@mui/icons-material";
import {useSortable} from "@dnd-kit/sortable";
import {CSS as DndkitCSS} from "@dnd-kit/utilities";

type SubscriptionExtrasCardProps = {
    id: string
    title: string
    value: number
}

export function ExtraCard({id, title, value}: SubscriptionExtrasCardProps) {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});

    return (
        <div key={id} className={'p-4 bg-white rounded-md drop-shadow flex items-center' +
            ' justify-between gap-x-2'}
        ref={setNodeRef}
        style={{
            transform: DndkitCSS.Transform.toString(transform),
            transition,
            touchAction: 'none'
        }}
        >
            <div className={'flex items-center gap-x-4 flex-grow'}>
                <DragHandleRounded
                    {...listeners} // Attach listeners here to handle drag events
                    {...attributes} // Attach attributes to allow accessibility and proper drag behavior
                />
                <p className={'text-neutral-900 font-semibold text-sm'}>{title}</p>
            </div>
            <p className={'text-neutral-900 font-semibold text-sm'}>€{value}</p>
        </div>
    )
}