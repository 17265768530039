import React, {FC, useState} from 'react';
import CreateButton from "@components/button/CreateButton";
import SubscriptionItemCard from "@pages/merchant/subscriptions/_legacy/show/components/SubscriptionItemCard";
import ArchiveButton from "@components/button/ArchiveButton";
import useAsyncMemo from "@hooks/useAsyncMemo";
import useGql from "@modules/graphql/useGql";
import TierCreateForm from "@pages/merchant/subscriptions/_legacy/show/components/tiers/TierCreate.form";
import {Link} from "react-router-dom";
import SearchBar from "@components/search/SearchBar.component";
import SubscriptionItemList from "@pages/merchant/subscriptions/_legacy/show/components/SubscriptionItemList";


type Props = {
    subscriptionId: string
}
const TierTab: FC<Props> = ({subscriptionId}) => {

    const gql = useGql()

    const [openCreateTier, setOpenCreateTier] = useState(false)
    const [updateTiers, setUpdateTiers] = useState(0)
    const tiers = useAsyncMemo(async () => {
        return await gql.GetPlans({subscriptionId: subscriptionId}).then(r => r.getPlans ?? [])
    }, [updateTiers], [])


    return (
        <>
            <SearchBar
                className={'mb-shell'}
                inputProps={{placeholder: 'Find tiers'}}
                button={<CreateButton onClick={() => setOpenCreateTier(true)}/>}
            />

            <SubscriptionItemList archiveButton={<ArchiveButton/>}>
                {tiers.map(tier => <Link
                    key={tier.id}
                    className={'h-fit'}
                    to={`tiers/${tier.id}`}
                >
                    <SubscriptionItemCard

                        title={tier.title}
                        description={tier.description}
                        price={tier.price}
                        activeSubscribers={23}
                    />
                </Link>)}
            </SubscriptionItemList>

            <TierCreateForm
                openState={[openCreateTier, setOpenCreateTier]}
                subscriptionId={subscriptionId}
                onSubmitSuccess={() => setUpdateTiers(updateTiers + 1)}
            />
        </>
    );
};

export default TierTab;
