import {I18nDictionary} from "@config/i18n";


export default {
    "amount": {
        "price": "Price",
        "percentage": "Percentage",
        "percent": "Percent"
    },
    "auth": {
        "login": "Log in",
        "logout": "Log out",
        "authorise": "Validating login",
        "otp": {
            "title": "OTP code",
            "text": {
                "verify": "Please enter the verification code from your Authenticator App",
                "incorrect": "The given verification code is incorrect!",
                "enter": "Enter verification Code",
                "block": "Your authenticator was blocked."
            },
            "qr": {
                "failure": "Not logged in or invalid account",
                "scan": "Scan the QR code below using a supported authenticator app. (Google Authenticator & Microsoft Authenticator)",
                "scant": "Cant scan the QR Code? Enter this code into your authenticator app instead:",
            }
        },
    },
    "benefit": {
        "title": {
            "singular": "Benefit",
            "plural": "Benefits"
        },
        "no_results": "No benefits yet",
        "new": "New benefit",
        "manage_benefits": "Manage benefits",
        "add_to_plans": "Add to plans"
    },
    "discount": {
        "title": {
            "singular": "Discount",
            "plural": "Discounts"
        },
        "no_results": "No discounts yet",
        "new": "New discount",
        "type": {
            "age": "Age",
            "loyalty": "Loyalty",
            "welcome": "Welcome"
        },
        "choose_type": "Choose discount type",
        "discount_conditions": "Discount & Conditions",
        "age_discount_title": "New age discount",
        "loyalty_discount_title": "New loyalty discount",
        "welcome_discount_title": "New welcome discount",
        "age": {
            "younger": "Younger",
            "older": "Older"
        },
        "equal_to": {
            "than": "than",
            "than_or_equal_to": "than or equal to"
        }
    },
    "extra": {
        "title": {
            "singular": "Extra",
            "plural": "Extras"
        },
        "no_results": "No extras yet",
        "new": "New extra"
    },
    "fee": {
        "title": {
            "singular": "Fee",
            "plural": "Fees"
        },
        "no_results": "No fees yet",
        "new": "New fee",
        "type": {
            "fixed": "Fixed",
            "interval": "Interval",
        },
        "choose_type": "Choose fee type",
        "fixed_fee_title": "New fixed fee",
        "interval_fee_title": "New interval fee",
        "fee_conditions": "Fee & Conditions"
    },
    "form": {
        "error": {
            "required": "Required"
        },
        "field": {
            "allowMultiplePlansPerUsers": "Allow multiple plans per user",
            "description": "Description",
            "title": "Title",
            "vatRate": "VAT rate",
            "price": "Price",
            "includingVat": "VAT",
            "excludeFromYearlyDiscounts": "Exclude from yearly discounts",
            "excludeFromCoupons": "Exclude from coupons",
            "excludeFromPromotions": "Exclude from promotions",
            "isLess": "When",
            "orEqual": " ",
            "age": "Years",
            "discountAmount": "Apply",
            "discountAmountType": "Discount",
            "yearsSubscribed": "When subscribed for years",
            "intervalYears": "Repeat every years",
            "loyaltyDiscountMaximumAmount": "With",
            "loyaltyDiscountMaximumAmountType": "Maximum",
            "welcomeDiscountMonths": "For months",
            "feeAmount": "Apply",
            "feeAmountType": "Fee",
            "fixedFeeMoment": "At the",
            "intervalFeeIntervalAmount": "Every",
        },
        "action": {
            "save": "Save"
        }
    },
    "invoice": {
        "title": {
            "singular": "Invoice",
            "plural": "Invoices"
        },
        "new": "New invoice",
        "no_results": "No invoices available",
        "moment": {
            "first_invoice": "Start of subscription",
            "last_invoice": "End of subscription"
        },
        "interval": {
            "months": {
                "plural": "Months",
                "singular": "Month"
            },
            "years": {
                "plural": "Years",
                "singular": "Year"
            },
        }
    },
    "onboarding": {
        "title": "Onboarding"
    },
    "payment": {
        "title": {
            "singular": "Payment",
            "plural": "Payments"
        },
        "new": "New payment",
        "no_results": "No payments available"
    },
    "plan": {
        "title": {
            "singular": "Plan",
            "plural": "Plans"
        },
        "new": "New plan",
        "no_results": "No plans yet",
        "highlighted": "Highlighted"
    },
    "registration": {
        "create_account": {
            "title": "Create account"
        },
        "register_company": {
            "title": "Register company"
        },
        "choose_plan": {
            "title": "Choose plan"
        },
        "checkout": {
            "title": "Checkout",
            "status": {
                "success": {
                    "title": "Payment succeeded"
                }
            }
        },
        "payment_status": {
            "title": "Payment status"
        }

    },
    "settings": {
        "title": {
            "singular": "Setting",
            "plural": "Settings"
        },
        "billing": {
            "title": "Billing"
        },
        "company": {
            "title": "Company settings"
        },
        "mfa": {
            "title": "Manage MFA",
            "setup": {
                "title": "Setup MFA",
                "scan": {
                    "title": "Scan to pair",
                    "verify": {
                        "title": "Verify MFA",
                        "verify": {
                            "success": {
                                "title": "MFA enabled"
                            },
                            "failed": {
                                "title": "Pairing failed"
                            }
                        }
                    },
                    "deactivate": {
                        "title": "Deactivate MFA",
                        "success": {
                            "title": "MFA deactivated"
                        },
                        "failed": {
                            "title": "MFA deactivation failed"
                        }
                    }
                },

            }
        },
        "tos": {
            "title": {
                "singular": "ToS",
                "plural": "ToS"
            },
            "edit": {
                "title": "Edit Tos"
            },
            "new": "New ToS",
            "no_results": "No Tos available"
        },
        "user": {
            "title": "Manage users"
        }
    },
    "subscriber": {
        "title": {
            "singular": "Subscriber",
            "plural": "Subscribers"
        },
        "new": "New subscriber",
        "no_results": "No subscribers available"
    },
    "subscription": {
        "title": {
            "singular": "Subscription",
            "plural": "Subscriptions"
        },
        "new": "New Subscription",
        "no_results": "No Subscriptions yet",
        "status": {
            "draft": "Draft",
            "published": "Published",
            "scheduled": "Scheduled"
        },
        "config": {
            "advanced": "Advanced",
            "title": "Config",
            "steps": {
                "extras": "Extras",
                "fees": "Fees",
                "plans": "Plans",
                "benefits": "Benefits",
                "discounts": "Discounts"
            }
        }
    },
    "ui": {
        "next": "Next",
        "home": "Home",
    },
    "vat": {
        "title": "VAT",
        "including": {
            "short_name": "Incl.",
            "full_name": "Including"
        },
        "excluding": {
            "short_name": "Excl.",
            "full_name": "Excluding"
        }
    }
} satisfies I18nDictionary