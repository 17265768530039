import React, {useMemo, useState} from 'react'
import ListPageTemplate from "@templates/page/ListPage.template";
import BillingFilter, {FilterItem} from "@pages/merchant/settings/pages/billing/components/Billing.filter";
import {BillingPaymentStatus} from "@pages/merchant/settings/pages/billing/components/Billing.badge";
import BillingListComponent from "@pages/merchant/settings/pages/billing/components/Billing.list";
import {BillingItem} from "@pages/merchant/settings/pages/billing/types/Billing.types";


export function BillingIndexPage() {

    const billingItems: BillingItem[] = useMemo(() => [
        {
            company: 'Tjecko',
            invoice: 'tjck1234567890',
            amount: 199,
            invoiceDate: '2024-07-03',
            status: 'second_reminder'
        },
        {
            company: 'Tjecko',
            invoice: 'tjck1234567889',
            amount: 144,
            invoiceDate: '2024-06-03',
            status: 'first_reminder'
        },
        {
            company: 'Tjecko',
            invoice: 'tjck1234567888',
            amount: 144,
            invoiceDate: '2024-05-03',
            status: 'failed'
        },
        {
            company: 'Tjecko',
            invoice: 'tjck1234567887',
            amount: 124,
            invoiceDate: '2024-04-03',
            status: 'paid'
        },
        {
            company: 'Tjecko',
            invoice: 'tjck1234567886',
            amount: 106,
            invoiceDate: '2024-03-03',
            status: 'paid'
        },
        {
            company: 'Tjecko',
            invoice: 'tjck1234567885',
            amount: 106,
            invoiceDate: '2024-02-03',
            status: 'refunded'
        },
        {
            company: 'Tjecko',
            invoice: 'tjck1234567884',
            amount: 128,
            invoiceDate: '2024-01-03',
            status: 'paid'
        },
    ], []);

    /**
     * Extra long list for scroll / ui testing
     */
    const dummyBillingItems = useMemo(() => {
        return Array(10).fill(0).map(() => billingItems).flat()
    }, [billingItems]);


    const filters = useMemo((): FilterItem<BillingPaymentStatus>[] => [
        {
            title: 'Paid',
            value: 'paid'
        },
        {
            title: 'Failed',
            value: 'failed'
        },
        {
            title: 'Reminder',
            value: 'reminder'
        },
        {
            title: 'Refunded',
            value: 'refunded'
        }
    ], []);

    const filterState = useState<FilterItem>()
    const filter = filterState[0]

    const filteredItems = useMemo(() => {
        if (!filter) {
            return dummyBillingItems
        }
        return dummyBillingItems.filter(item => item.status.includes(filter.value))
    }, [dummyBillingItems, filters, filter]);

    return (
        <ListPageTemplate navChildren={<BillingFilter filters={filters} filterState={filterState}/>}>
            <BillingListComponent items={filteredItems}/>
        </ListPageTemplate>
    )
}