import {ReactNode, useEffect, useMemo, useState} from 'react';
import {useNavbarTop} from "@components/navigation/NavbarTop/NavbarTop.context";
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";

/**
 * Get workspace height in px.
 * Workspace = real estate between header top and footer.
 */
const useWorkspaceHeight = () => {

    const navbarTop = useNavbarTop();

    const hideNavbarTop = useMemo(() => {
        return navbarTop.hide[0]
    }, [navbarTop.hide[0]]);

    const navbarTopHeight = useMemo(() => {
        return navbarTop.height[0]
    }, [navbarTop.height[0]]);

    const navbarBottom = useNavbarBottom();

    const hideNavbarBottom = useMemo(() => {
        return navbarBottom.hide[0]
    }, [navbarBottom.hide[0]]);

    const navbarBottomHeight = useMemo(() => {
        return navbarBottom.height[0]
    }, [navbarBottom.height[0]]);

    const navbarBottomHasChildren = useMemo(() => {
        return !([null, undefined] as ReactNode[]).includes(navbarBottom.children[0])
    }, [navbarBottom.children[0]]);



    const marginTop = useMemo(() => {
        return hideNavbarTop ? 0 : navbarTopHeight
    }, [hideNavbarTop, navbarTopHeight]);

    const marginBottom = useMemo(() => {
        return hideNavbarBottom && !navbarBottomHasChildren ? 0 : navbarBottomHeight
    }, [hideNavbarBottom, navbarBottomHeight, navbarBottomHasChildren]);


    const [innerHeight, setInnerHeight] = useState(window.innerHeight)
    useEffect(() => {
        function handleResize() {
            setInnerHeight(window.innerHeight);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const available = useMemo(() => {
        return innerHeight - marginTop - marginBottom
    }, [innerHeight, marginTop, marginBottom]);

    return {
        marginTop,
        marginBottom,
        available,
        total: window.innerHeight
    }
};

export default useWorkspaceHeight;
