import {SelectOption} from "@components/form/fields/Select.component";
import {__t} from "@config/i18n";


export type AmountType = 'price' | 'percentage'

export const AmountTypeSelectOptions: SelectOption<string>[] = [
    {
        title: __t('amount.price'),
        value: 'price'
    },
    {
        title: __t('amount.percent'),
        value: 'percentage'
    }
]