import React, {FC} from 'react';
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";
import BillingBadge from "@pages/merchant/settings/pages/billing/components/Billing.badge";
import {BillingItem} from "@pages/merchant/settings/pages/billing/types/Billing.types";

type Props = {
    items: BillingItem[]
}

const BillingList: FC<Props> = ({items}) => {

    const {available} = useWorkspaceHeight();
    return (
        <div
            className={'grid divide-y gap-y-3 overflow-y-auto pb-3 bg-white'}
            style={{
                height: `${available}px`
            }}
        >
            {items.map((item, i) => <div key={i} className={'px-shell pt-3'}>
                <div className={'grid grid-cols-2 gap-y-0.5'}>
                    <span className={'font-semibold text-lg text-primary'}>{item.company}</span>
                    <div className={'font-medium text-md text-primary text-right'}>€{item.amount}</div>
                    <span className={'font-medium text-sm text-neutral-700'}>{item.invoiceDate}</span>
                    <div>
                        <BillingBadge className={'float-right'} status={item.status}/>
                    </div>
                </div>
            </div>)}
        </div>
    );
};

export default BillingList;
