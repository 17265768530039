import React, {useState} from 'react';
import ShowPageTemplate from "@templates/page/ShowPage.template";
import OtpInputComponent from "@components/mfa/OtpInput.component";
import LoadingSpinner from "@components/feedback/LoadingSpinner.component";
import {useNavigate} from "react-router-dom";
import P from "@components/text/P.component";
import NegativeButton from "@components/button/NegativeButton";
import ErrorMessage from "@components/feedback/Message/ErrorMessage";
import useGql from "@modules/graphql/useGql";
import {__t} from "@config/i18n";

const MfaDeactivatePage = () => {

    const navigate = useNavigate()

    const gql = useGql()

    const [otp, setOtp] = useState<string>('')
    const [verifying, setVerifying] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()


    const handleDeactivate = async () => {

        gql.MfaDeactivate({code: otp})
            .then(() => {
                setErrorMessage(undefined)
                navigate('success')
            })
            .catch(() => {
                setOtp('')
                setErrorMessage('Invalid code provided')
                setVerifying(false)
            })
    }

    return (
        <ShowPageTemplate>
            <P className={'text-center pt-8'}>{__t('auth.otp.text.verify')}</P>
            <OtpInputComponent value={otp} onChange={setOtp} disabled={verifying} className={'py-8'}/>
            <ErrorMessage message={errorMessage}/>
            <NegativeButton onClick={handleDeactivate} disabled={otp.length < 6 || verifying}>Deactivate
                    MFA</NegativeButton>
            {verifying && <LoadingSpinner/>}
        </ShowPageTemplate>
    );
}
;

export default MfaDeactivatePage;
