import React, {useEffect, useState} from 'react'
import ListPage from "@templates/page/ListPage.template";
import {CompanyUser, mockCompanyUsersData} from "@app/mock/MockCompanyData";
import {CompanyUserList} from "@pages/merchant/settings/pages/company-settings/users/components/CompanyUser.list";
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import {NavbarBottomButtonWrapper} from "@components/button/NavbarBottomButton.wrapper";
import {
    CompanyUserPulloverCreateForm
} from "@pages/merchant/settings/pages/company-settings/users/forms/CompanyUserPullover.create.form";


export function ManageUsersIndex() {
    const [users, setUsers] = useState<CompanyUser[]>([])


    const navbarBottom = useNavbarBottom()

    const addUser = (newUser: CompanyUser) => {
        setUsers(prevUsers => [...(prevUsers || []), newUser]);
    };

    useEffect(() => {
        navbarBottom.children[1](
            <NavbarBottomButtonWrapper>
                <CompanyUserPulloverCreateForm addUser={addUser}/>
            </NavbarBottomButtonWrapper>
        )
    }, []);

    const changeUserRole = (userId: string, role: string, isChecked: boolean | 'indeterminate') => {
        setUsers(prevUsers =>
            prevUsers?.map(user => {
                if (user.id === userId) {
                    let updatedRoles: string[];

                    if (role === 'Admin') {
                        updatedRoles = isChecked ? ['Admin'] : [];
                    } else {
                        updatedRoles = isChecked
                            ? [...user.role.filter(currRole => currRole !== 'Admin'), role]
                            : user.role.filter(currRole => currRole !== role);
                    }

                    return {
                        ...user,
                        role: updatedRoles
                    };
                }
                return user;
            })
        );
    }


    const removeUser = (userId: string | undefined) => {
        setUsers(prevUsers => prevUsers?.filter(user => user.id !== userId))
    }

    useEffect(() => {
        setUsers(mockCompanyUsersData)
    }, []);

    return (
        <ListPage>
            <CompanyUserList
                users={users}
                changeUserRole={changeUserRole}
                removeUser={removeUser}
            />
        </ListPage>
    )
}