import React, {useState} from 'react'
import PullOver from "@components/pullover/PullOver.component";
import {Edit} from "lucide-react";
import {CompanyUser} from "@app/mock/MockCompanyData";
import {Checkbox} from "@src/@/components/ui/checkbox";
import {Label} from "@src/@/components/ui/label";

const roles = ['Admin', 'Financial Manager', 'Subscription Manager', 'Subscriber Manager', 'User Manager']

interface EditCompanyUserPulloverProps {
    user: CompanyUser | undefined
    changeUserRole: (userId: string, role: string, isChecked: boolean | 'indeterminate') => void
}

export function CompanyUserPulloverEditForm({user, changeUserRole}: EditCompanyUserPulloverProps) {
    const [showEditCompanyUser, setShowEditCompanyUser] = useState(false);

    return (
        <PullOver
            variations={'max-height'}
            openState={[showEditCompanyUser, setShowEditCompanyUser]}
            title={'Edit User'}
            submit={{
                title: 'Save',
                onClick: () => setShowEditCompanyUser(false)
            }}
            trigger={{
                title: <Edit size={16} className={'hover:cursor-pointer'}/>,
                variation: 'link',
                onClick: () => setShowEditCompanyUser(true)
            }}
        >
            <div className={'divide-y text-neutral-900'}>
                <div className={'pb-6 space-y-4'}>
                    <p className={'font-roboto text-lg font-semibold text-primary'}>User Information</p>
                    <div className={'flex flex-col gap-y-3 font-medium'}>
                        <div className={'flex items-center gap-x-1'}>
                            <p>Name:</p> <p className={'font-semibold'}>{user?.name}</p>
                        </div>
                        <div className={'flex items-center gap-x-1'}>
                            <p>Email:</p> <p className={'font-semibold'}>{user?.email}</p>
                        </div>
                        <div className={'flex items-center gap-x-1'}>
                            <p>Mobile No.:</p> <p className={'font-semibold'}>{user?.mobileNo}</p>
                        </div>
                    </div>
                </div>

                <div className={'pt-6 space-y-4'}>
                    <p className={'font-roboto text-lg font-semibold text-primary'}>
                        Roles
                    </p>
                    <div className={'flex flex-col gap-y-3'}>
                        {roles.map(role => <div key={role}>
                            <Checkbox
                                id={role}
                                value={role}
                                name={role}
                                checked={user?.role.includes(role) || false}
                                onCheckedChange={(check) => {
                                    if (user) {
                                        changeUserRole(user.id, role, check)
                                    }
                                }}
                            />
                            <Label htmlFor={role} className={'text-base ml-2 font-semibold'}>
                                {role}
                            </Label>
                        </div>)}
                    </div>
                </div>

            </div>
        </PullOver>
    )
}