import React, {FC} from 'react';
import {useSortable} from "@dnd-kit/sortable";
import {CSS as DndKitCSS} from '@dnd-kit/utilities';
import {DragHandleRounded} from "@mui/icons-material";
import {Checkbox} from "@src/@/components/ui/checkbox";
import {toEuroString} from "@modules/casting/Number";

type Props = {
    id: string
    title: string
    price?: number
    isHighlighted?: boolean
    handleChange: (id: string) => void
}

export const PlanCard: FC<Props> = ({
    id,
    title,
    price,
    isHighlighted,
    handleChange,
}) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({id});

    return (
        <div className={'p-4 border bg-white drop-shadow rounded-md flex items-center gap-x-2'}
            ref={setNodeRef}
            style={{
                transform: DndKitCSS.Transform.toString(transform),
                transition,
                touchAction: 'none'
            }}
        >
            <div className={'w-6'}>
                <DragHandleRounded
                    {...listeners} // Attach listeners here to handle drag events
                    {...attributes} // Attach attributes to allow accessibility and proper drag behavior
                />
            </div>
            <div className={'flex gap-x-4 items-center flex-grow font-semibold text-sm'}>
                <div className={'flex-grow'}>{title}</div>
                <div>{toEuroString(price)}</div>
            </div>
            <div className={'w-8 text-right'}>
                <Checkbox checked={isHighlighted} onCheckedChange={() => handleChange(id)}/>
            </div>
        </div>
    );
}
