import React, {useMemo} from "react";
import {groupObjectsByDate} from "@modules/casting/Object";
import ListComponent, {ListItem, ListItems} from "@components/list/List.component";
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";
import {toEuroString} from "@modules/casting/Number";
import {mockSubscriberData, SubscriberItem} from "@app/mock/MockSubscriberData";
import {BillingPaymentStatus} from "@pages/merchant/settings/pages/billing/components/Billing.badge";

interface CustomerCardListProps {
    height?: string
    customer: SubscriberItem
}

// @todo refactor to use list component

export function CustomerCardList({height, customer}: CustomerCardListProps) {

    const {available} = useWorkspaceHeight()

    const invoiceList = useMemo(() => {
        const targetCostumer = mockSubscriberData.filter(item => {
            return item.title === customer.title
        });
        const groupedItems = groupObjectsByDate(targetCostumer, 'date', {
            groupType: 'day',
            sort: 'DESC'
        });

        return Object.keys(groupedItems).reduce((acc, groupKey) => ({
            ...acc,
            [groupKey]: groupedItems[groupKey].map(item => ({
                id: item.id,
                dateTime: item.date,
                title: item.title,
                description: item.status,
                target: `/subscribers/list/${item.id}`,
                symbol: item.title[0].concat(item.title.split(' ')[1][0]),
                feedback: toEuroString((['failed', 'refunded', 'reversed'] as BillingPaymentStatus[]).includes(item.status)
                    ? item.amount * -1
                    : item.amount
                ),
            }) satisfies ListItem)
        }), {} satisfies ListItems)
    }, [])

    return (
        <ListComponent height={height ?? `${available}px`} items={invoiceList}/>
    )
}