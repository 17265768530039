import {useEffect} from 'react';
import useAuth from "@hooks/useAuth";
import {useNavigate} from "react-router-dom";


const useAuthGuard = () => {

    // const {loggedIn, hasMfa, otpVerified} = useAuth();
    const {
        hasMfa,
        otpVerified,
        loggedIn
    } = useAuth() //useAuthProvider();

    const navigate = useNavigate()

    useEffect(() => {
        console.log({loggedIn, hasMfa, otpVerified})
        if (loggedIn === null || hasMfa === null || otpVerified === null) {
            return;
        }

        if (!loggedIn) {
            if (hasMfa && !otpVerified) {
                navigate('/otp')
            }
            navigate('/logout')
        }
    }, [loggedIn, hasMfa, otpVerified]);

};
export default useAuthGuard;