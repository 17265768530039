import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "../app/lang/en";
import nl from "../app/lang/nl";
import {ReflectKeysOf} from "@modules/casting/Object";
import TjeckoI18n from "@app/lang/Tjecko.i18n";


// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

export type I18nDictionary = TjeckoI18n


type SupportedLanguage = 'en' | 'nl'
export const SupportedLanguages: (SupportedLanguage | string)[] = ['en', 'nl']

type ResourceTranslations = {
    // eslint-disable-next-line no-unused-vars
    [k in SupportedLanguage]: {
        'translation': I18nDictionary
    }
}


const resources: ResourceTranslations = {
    'en': {
        'translation': en
    },
    'nl': {
        'translation': nl
    }
};


/** Register lang usage */
let currentLang = navigator.language.split('-')[0];
if (!SupportedLanguages.includes(currentLang)) {
    currentLang = 'en'
}

document.documentElement.lang = currentLang;


i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: currentLang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;

export const __t = (key: ReflectKeysOf<I18nDictionary>) => i18n.t(key)