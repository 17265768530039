import * as React from "react"
import {cva, type VariantProps} from "class-variance-authority"

import {cn} from "@shadcn/lib/utils"
import {ReactNode, useMemo} from "react";

const getBadgeVariants = cva(
    "items-center rounded-md px-2 py-1 text-xs font-semibold gap-x-1 flex justify-center",
    {
        variants: {
            variant: {
                default:
                    "border-transparent bg-primary text-primary-foreground shadow text-white",
                secondary:
                    "border-transparent bg-secondary text-secondary-foreground",
                destructive:
                    "border-transparent bg-destructive text-destructive-foreground shadow",
                outline: "text-foreground",
                positive: 'bg-green-600 text-white',
                neutral: 'bg-neutral-300 text-neutral-700'
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
)

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof getBadgeVariants> {
    label: string
    icon?: ReactNode
}

function Badge({className, variant, label, icon, ...props}: BadgeProps) {
    const badgeVariants = useMemo(() => getBadgeVariants({variant}), [variant]);

    return (
        <div className={cn(badgeVariants, className)} {...props}>{icon}{label}</div>
    )
}

export {Badge, getBadgeVariants}
