import React, {useMemo} from 'react'
import PullOverForm from "@components/pullover/PullOverForm.component";
import {FormFields} from "@components/form/fields/FormField.component";
import {z} from "zod";
import {AmountType, AmountTypeSelectOptions} from "@app/types/Amount";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {IncludingVatSelectOptions} from "@app/types/Vat";
import {CreateFixedFeeInput} from "@src/gql-schema";
import {FeePulloverProps} from "@pages/merchant/subscriptions/pages/config/pages/fee/types/Fee.types";
import {InvoiceMomentSelectOptions} from "@app/types/Invoice";
import {__t} from "@config/i18n";

type FormInput = Omit<CreateFixedFeeInput, 'subscriptionId' | 'isDiscount' | 'active' | 'order'> & {
    includingVat: string
}

type MutationInput = { input: FormInput }

export function FixedFeePulloverCreateForm({
    subscriptionId,
    pulloverState,
    onSuccess
}: FeePulloverProps) {

    const gql = useGql()

    const setShowPullover = useMemo(() => {
        return pulloverState[1]
    }, [pulloverState[0]]);

    const subscription = useAsyncMemo(async () => {
        return await gql.GetSubscription({id: subscriptionId}).then(r => r.getSubscription)
    }, [])


    const vat = useMemo(() => {
        return subscription?.vatPercentage ?? 0;
    }, [subscription])


    const includingVatOptions = useMemo(() => {
        return IncludingVatSelectOptions(vat)
    }, [vat]);


    const fields = useMemo((): FormFields<FormInput> => {
        return {
            title: {
                zodType: z.string().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.title'),
                description: __t('fee.fee_conditions')
            },
            amount: {
                zodType: z.coerce.number().min(1, __t('form.error.required')),
                fieldType: 'input',
                colSpan: 'col-span-3',
                label: __t('form.field.feeAmount'),
            },
            amountType: {
                zodType: z.string(),
                fieldType: 'select',
                label: __t('form.field.feeAmountType'),
                options: AmountTypeSelectOptions,
                defaultValue: AmountTypeSelectOptions[0].value,
                colSpan: 'col-span-4'
            },
            includingVat: {
                zodType: z.coerce.number(),
                fieldType: 'select',
                options: includingVatOptions,
                defaultValue: `${includingVatOptions[0].value}`,
                label: ' ',
                colSpan: 'col-span-5'
            },
            moment: {
                zodType: z.string(),
                fieldType: 'select',
                label: __t('form.field.fixedFeeMoment'),
                options: InvoiceMomentSelectOptions,
                defaultValue: InvoiceMomentSelectOptions[0].value
            },
            description: {
                zodType: z.string(),
                fieldType: 'textarea',
                label: __t('form.field.description')
            },
        }
    }, [includingVatOptions])

    return (
        <PullOverForm
            title={__t('fee.fixed_fee_title')}
            form={{
                submit: async ({input}: MutationInput) => {
                    let amount = parseFloat(`${input.amount}`.replace(',', '.'))
                    if (input.includingVat && (input.amountType as AmountType) == 'price') {
                        const vatFactor = 100 + vat
                        amount = (amount / vatFactor) * 100
                    }

                    return await gql.CreateFixedFee({
                        input: {
                            amount,
                            amountType: input.amountType,
                            title: input.title,
                            description: input.description,
                            moment: input.moment,
                            subscriptionId: subscriptionId,

                            // @todo remove from backend
                            isDiscount: false,
                            active: true,
                            order: 0
                        }
                    })
                },
                onSubmitSuccess: (mutation) => {
                    if (mutation != null) {
                        onSuccess(mutation.createFixedFee.id)
                    }
                },
                fields,
                payloadInjection: {},
            }}
            submit={{title: __t('form.action.save')}}
            onClose={() => setShowPullover(false)}
            openState={pulloverState}
        />
    )
}