import React, {FC, useMemo, useState} from 'react'
import {FormFields} from "@components/form/fields/FormField.component";
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import {CreateTierInput} from "@src/gql-schema";
import {IncludingVatSelectOptions} from "@app/types/Vat";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {__t} from "@config/i18n";

type FormInput = Omit<CreateTierInput, 'frequency' | 'subscriptionId'> & { includingVat: boolean }
type MutationInput = { input: FormInput }

type Props = {
    subscriptionId: string
    onSuccess: (planId: string) => void | Promise<void>
}

export const PlanPulloverCreateForm: FC<Props> = ({subscriptionId, onSuccess}) => {

    const gql = useGql()
    const [show, setShow] = useState(false);


    const subscription = useAsyncMemo(async () => {
        return await gql.GetSubscription({id: subscriptionId}).then(r => r.getSubscription)
    }, [])

    const vat = useMemo(() => {
        return subscription?.vatPercentage ?? 0;
    }, [subscription])

    const fields = useMemo((): FormFields<FormInput> => {
        return {
            title: {
                zodType: z.string().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.title')
            },
            price: {
                zodType: z.coerce.number().min(1, __t('form.error.required')),
                fieldType: 'input',
                label: __t('form.field.price'),
                colSpan: 'col-span-6'
            },
            includingVat: {
                zodType: z.coerce.number(),
                fieldType: 'select',
                options: IncludingVatSelectOptions(vat),
                label: __t('form.field.includingVat'),
                colSpan: 'col-span-6'
            },
            description: {
                zodType: z.string(),
                fieldType: 'textarea',
                label: __t('form.field.description'),
            },
        }
    }, [vat])


    return <PullOverForm
        title={__t('plan.new')}
        form={{
            submit: async (data: MutationInput) => {

                let price = parseFloat(`${data.input.price}`.replace(',', '.'))
                if (data.input.includingVat) {
                    const vatFactor = 100 + vat
                    price = (price / vatFactor) * 100
                }

                const input: CreateTierInput = {
                    title: data.input.title,
                    description: data.input.description,
                    price,
                    subscriptionId,
                }

                return await gql.CreateTier({
                    input: input
                })
            },

            onSubmitSuccess: (mutation) => {
                if (mutation != null) {
                    onSuccess(mutation.createPlan.id)
                }
            },
            fields,
            payloadInjection: {
                subscriptionId: subscriptionId
            },
        }}
        submit={{title: __t('form.action.save')}}
        onClose={() => setShow(false)}
        openState={[show, setShow]}
        trigger={{title: __t('plan.new')}}
    />
}