import {SelectOption} from "@components/form/fields/Select.component";
import {__t} from "@config/i18n";

export type VatValue = 0 | 9 | 21
export type VatTitle = `${VatValue}%`
export type VatRate = { [K in VatValue]: VatTitle }
export const VatRates: VatRate = {
    0: '0%',
    9: '9%',
    21: '21%',
}

export const VatRateSelectOptions: SelectOption<number>[] = Object.keys(VatRates).map(vatRate => ({
    title: VatRates[vatRate as unknown as VatValue],
    value: parseInt(vatRate)
}))

export const IncludingVatSelectOptions = (vatPercentage: number | undefined = undefined): SelectOption<number>[] => [
    {
        title: `${__t('vat.excluding.short_name')} ${!vatPercentage ? '' : `${vatPercentage}%`} ${__t('vat.title')}`,
        value: 0
    },
    {
        title: `${__t('vat.including.short_name')} ${!vatPercentage ? '' : `${vatPercentage}%`} ${__t('vat.title')}`,
        value: 1
    }
]