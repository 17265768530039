import React, {useEffect, useState} from 'react'
import ShowPage from "@templates/page/ShowPage.template";
import {NavbarBottomButtonWrapper} from "@components/button/NavbarBottomButton.wrapper";
import useAsyncMemo from "@hooks/useAsyncMemo";
import useGql from "@modules/graphql/useGql";
import {ExtraOutput} from "@src/gql-schema";
import {NoResourcesScreen} from "@pages/merchant/subscriptions/components/NoResources.screen";
import useParamId from "@hooks/useParamId";
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import {
    ExtraPulloverCreateForm
} from "@pages/merchant/subscriptions/pages/config/pages/extra/forms/ExtraPullover.create.form";
import {ExtraList} from "@pages/merchant/subscriptions/pages/config/pages/extra/components/Extra.list";
import {__t} from "@config/i18n";

export function ExtraIndexPage() {

    const gql = useGql()
    const subscriptionId = useParamId('subscriptionId');
    const navbarBottom = useNavbarBottom()

    const [refresh, setRefresh] = useState(true)
    const [extras, setExtras] = useState<ExtraOutput[]>([])

    const subscriptionName = useAsyncMemo(async () => {
        return await gql.GetSubscription({id: subscriptionId})
            .then(r => r.getSubscription.title)
    }, [subscriptionId], __t('subscription.config.title'))

    useEffect(() => {
        navbarBottom.children[1](
            <NavbarBottomButtonWrapper>
                <ExtraPulloverCreateForm
                    subscriptionId={subscriptionId}
                    onSuccess={() => setRefresh(true)}
                />
            </NavbarBottomButtonWrapper>
        )
    }, [subscriptionId]);

    useEffect(() => {
        if (!refresh) {
            return;
        }
        gql.GetExtras({id: subscriptionId})
            .then(r => setExtras(r.getExtras))
            .finally(() => setRefresh(false))
    }, [refresh])

    useEffect(() => {
        const sortedExtras = [...extras].sort((a, b) => a.order - b.order)
        gql.ReorderExtras({extraIds: sortedExtras.map(extra => extra.id)}).then(r => r.reorderExtras)
    }, [extras]);

    return (
        <ShowPage
            back={{
                title: subscriptionName,
                path: `/subscriptions/${subscriptionId}/config`
            }}
        >
            {extras.length === 0 && <NoResourcesScreen text={__t('extra.no_results')}/>}
            <ExtraList extras={extras} setExtras={setExtras}/>
        </ShowPage>
    )
}