import React, {FC, useEffect, useMemo, useState} from "react";
import GuestPageTemplate from "@templates/page/GuestPage.template";
import {TjeckoLogo} from "@components/icon/tjecko.icon";
import P from "@components/text/P.component";
import OtpInputComponent from "@components/mfa/OtpInput.component";
import ErrorMessage from "@components/feedback/Message/ErrorMessage";
import LoadingSpinner from "@components/feedback/LoadingSpinner.component";
import PrimaryButton from "@components/button/PrimaryButton";
import useAuth from "@hooks/useAuth";
import Redirect from "@modules/routing/Redirect";
import useGql from "@modules/graphql/useGql";
import {__t} from "@config/i18n";

export const OtpPage: FC = () => {

    const auth = useAuth()

    const gql = useGql()

    const [otp, setOtp] = useState<string>('');
    const [verifying, setVerifying] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    const [isVerified, setIsVerified] = useState(false)

    useEffect(() => {
        if (auth.loggedIn === true) {
            setIsVerified(true)
        }
    }, [auth.loggedIn]);


    const handleSubmit = async () => {
        setVerifying(true)

        await gql.MfaVerify({code: otp})
            .then((r) => {
                setErrorMessage(undefined)
                setIsVerified(r.mfaVerify)
            })
            .catch(() => {
                setErrorMessage(__t('auth.otp.text.incorrect'))
                setIsVerified(false)
            })
            .finally(() => {
                setOtp('')
                setVerifying(false)
            })
    }

    const redirectOnVerified = useMemo(() => {

        return isVerified ? '/payments' : null

    }, [isVerified]);

    const [prevOtp, setPrevOtp] = useState(otp)
    useEffect(() => {
        setPrevOtp(otp)
        if (otp.length === 6 && prevOtp.length < 6) {
            handleSubmit().then()
        }
    }, [otp]);


    return <GuestPageTemplate>
        <div className={'text-center grid grid-cols-1 gap-y-4 translate-y-[calc(50vh-18rem)]'}>
            <TjeckoLogo/>
            <P className={'text-center pt-8'}>{__t('auth.otp.text.verify')}</P>
            <OtpInputComponent value={otp} onChange={setOtp} disabled={verifying} className={'py-8'}/>

            <ErrorMessage message={errorMessage}/>
            <PrimaryButton onClick={handleSubmit}
                disabled={otp.length < 6 || verifying}>Submit</PrimaryButton>

            {verifying && <LoadingSpinner/>}

            {redirectOnVerified && <Redirect pathname={redirectOnVerified}/>}
        </div>
    </GuestPageTemplate>
}
