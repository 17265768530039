import React, {ReactNode} from 'react'

type CreateNewButtonProps = {
    children: ReactNode;
}

export function NavbarBottomButtonWrapper({children}: CreateNewButtonProps) {

    return (
        <div
            className={'py-3 -mx-shell px-shell border-b'}>
            {children}
        </div>
    )
}