import React from 'react'
import {CompanyUser} from "@app/mock/MockCompanyData";
import {
    CompanyUserPulloverEditForm
} from "@pages/merchant/settings/pages/company-settings/users/forms/CompanyUserPullover.edit.form";
import {
    CompanyUserRemoveDialog
} from "@pages/merchant/settings/pages/company-settings/users/forms/CompanyUser.remove.dialog";
import {CloseRounded} from "@mui/icons-material";

interface CompanyUserCardListProps {
    users: CompanyUser[]
    changeUserRole: (userId: string, role: string, isChecked: boolean | 'indeterminate') => void
    removeUser: (userId: string | undefined) => void
}

export function CompanyUserList({users, changeUserRole, removeUser}: CompanyUserCardListProps) {
    return (
        <>
            {users.map(user => <div
                key={user.id}
                className={'flex justify-between items-center p-4 border-b gap-x-2 bg-white'}
            >
                <div className={'flex flex-col gap-y-1 flex-grow'}>
                    <div className={'text-xl font-roboto font-medium text-primary'}>
                        {user.name}
                    </div>
                    <div className={'text-sm font-medium'}>
                        {user.role.join(', ')}
                    </div>
                </div>
                <div className={'flex items-center gap-x-3 text-primary'}>
                    <CompanyUserPulloverEditForm
                        user={user}
                        changeUserRole={changeUserRole}
                    />
                    <CompanyUserRemoveDialog
                        user={user}
                        removeUser={removeUser}
                        trigger={<CloseRounded className={'hover:cursor-pointer'}/>}
                    />
                </div>
            </div>)}
        </>
    )
}