import React, {Dispatch, SetStateAction, useMemo} from 'react'
import {Checkbox} from "@src/@/components/ui/checkbox";
import {NestedTierOutput} from "@src/gql-schema";
import {TabsContent} from "@src/@/components/ui/tabs";
import {BenefitTabKey} from "@pages/merchant/subscriptions/pages/config/pages/benefit/types/Benefit.types";
import {classNames} from "@modules/casting/String";

type SubscriptionBenefitsAddToPlansProps = {
    plans: NestedTierOutput[]
    selectedPlan: string
    setSelectedPlan: Dispatch<SetStateAction<string>>
    handleCheckChange: (planId: string, benefitId: string) => void
}

export function BenefitAddToPlanTab({
    plans,
    selectedPlan,
    setSelectedPlan,
    handleCheckChange
}: SubscriptionBenefitsAddToPlansProps) {

    const benefits = useMemo(() => {
        return plans.find(plan => plan.id === selectedPlan)?.benefits ?? []
    }, [plans, selectedPlan]);


    return <TabsContent
        value={'add-to-plans' as BenefitTabKey}
        className={'bg-white drop-shadow rounded-md flex flex-col'}
    >
        <div className={'flex flex-grow min-h-[200px]'}>
            <div className={'w-4/12'}>
                {plans.map(plan => <button
                    key={plan.id}
                    onClick={() => setSelectedPlan(plan.id)}
                    className={classNames('w-full px-4 py-3 text-sm font-semibold text-left',
                        selectedPlan === plan.id && 'bg-primary-600 text-neutral-100 rounded-l-md',
                    )}
                >
                    {plan.title}
                </button>)}
            </div>
            <div className="p-4 space-y-6">
                {benefits.map(benefit => <div key={benefit.id} className="flex items-center gap-x-2">
                    <Checkbox
                        checked={benefit.active}
                        onCheckedChange={() => handleCheckChange(selectedPlan, benefit.id)}
                    />
                    <div className="text-neutral-900 text-sm font-medium">{benefit.title}</div>
                </div>)}
            </div>
        </div>
    </TabsContent>

}