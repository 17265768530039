import React, {FC} from 'react'
import {TabsList, TabsTrigger} from "@src/@/components/ui/tabs";
import {
    BenefitTabItems,
    BenefitTabKey
} from "@pages/merchant/subscriptions/pages/config/pages/benefit/types/Benefit.types";


export const BenefitTab: FC = () => {
    return (
        <TabsList className={'w-full flex bg-neutral-300 text-neutral-700'}>
            {Object.keys(BenefitTabItems).map(tab => <TabsTrigger
                key={tab}
                value={tab}
                className={'w-full font-semibold data-[state=active]:bg-primary-800 data-[state=active]:text-white'}
            >
                {BenefitTabItems[tab as BenefitTabKey]}
            </TabsTrigger>)}
        </TabsList>
    )
}